import { initialState } from "../../store/slices/tool"

// Type checking helper functions
const isValidNumber = (value) => {
  return typeof value === "number" && !isNaN(value) && isFinite(value);
};

const isValidString = (value) => {
  return typeof value === "string" && value.length > 0;
};

const isValidObject = (value) => {
  return value !== null && typeof value === "object" && !Array.isArray(value);
};

// Safe math helper functions with enhanced type checking
const safeDivide = (numerator, denominator, defaultValue = 0) => {
  try {
    if (!isValidNumber(numerator) || !isValidNumber(denominator))
      return defaultValue;
    if (denominator === 0) return defaultValue;
    return numerator / denominator;
  } catch (error) {
    return defaultValue;
  }
};

const safeMultiply = (...factors) => {
  try {
    if (factors.some((f) => !isValidNumber(f))) return 0;
    return factors.reduce((acc, val) => acc * val, 1);
  } catch (error) {
    return 0;
  }
};

// Helper function to set nested value with validation
const setNestedValue = (obj, path, value) => {
  try {
    if (!isValidObject(obj) || !isValidString(path)) {
      return;
      // console.log('Invalid object or path in setNestedValue');
    }
    const [category, type] = path.split(".");
    if (!obj[category]) {
      obj[category] = {};
    }
    obj[category][type] = value;
  } catch (error) {
  }
};

// Helper function to get nested value with validation
const getNestedValue = (obj, path) => {
  try {
    if (!isValidObject(obj) || !isValidString(path)) {
      return null;
    }
    const [category, type] = path.split(".");
    return obj[category]?.[type];
  } catch (error) {
    return null;
  }
};

// Helper function for unit conversion with validation
const convertImperialToMetric = (value, fromUnit, toUnit) => {
  try {
    if (!isValidNumber(value)) return 0;
    if (!isValidString(fromUnit) || !isValidString(toUnit)) {
      return value;
    }

    switch (`${fromUnit}_${toUnit}`) {
      case "ft_m":
        return value * 0.3048;
      default:
        return value;
    }
  } catch (error) {
    return 0;
  }
};

// Helper function to calculate distance between two points
const calculateDistance = (x1, y1, x2, y2) => {
  try {
    if (
      !isValidNumber(x1) ||
      !isValidNumber(y1) ||
      !isValidNumber(x2) ||
      !isValidNumber(y2)
    ) {
      return 0;
    }
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  } catch (error) {
    return 0;
  }
};


// Maps calculation IDs to their computation functions and descriptions
export const calculationMap = {
  // Robot Type Calculations
  "activePickTime.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // console.log(999999)
        const waitFteTotal =
          calculationMap["waitForFteTotalTime.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const waitFteBase =
          calculationMap["waitForFteTime.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const pickLineTotal =
          calculationMap["pickLineTotalTime.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const travelNextPickTotal =
          calculationMap["travelToNextPickTotalTime.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;

        return waitFteTotal - waitFteBase + pickLineTotal + travelNextPickTotal;
      } catch (error) {
        // console.log('Error in activePickTime.picking calculation:', error);
        return 0;
      }
    },
    description: "Calculates total active picking time",
    calculation:
      "activeTime = waitForFteTotalTime - waitForFteTime + pickLineTotalTime + travelToNextPickTotalTime",
    dependencies: [
      "waitForFteTotalTime.picking",
      "waitForFteTime.picking",
      "pickLineTotalTime.picking",
      "travelToNextPickTotalTime.picking",
    ],
  },

  "activePickTime.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const waitFteTotal =
          calculationMap["waitForFteTotalTime.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const waitFteBase =
          calculationMap["waitForFteTime.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const pickLineTotal =
          calculationMap["pickLineTotalTime.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const travelNextPickTotal =
          calculationMap["travelToNextPickTotalTime.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;

        return waitFteTotal - waitFteBase + pickLineTotal + travelNextPickTotal;
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total active picking time",
    calculation:
      "activeTime = waitForFteTotalTime - waitForFteTime + pickLineTotalTime + travelToNextPickTotalTime",
    dependencies: [
      "waitForFteTotalTime.putaway",
      "waitForFteTime.putaway",
      "pickLineTotalTime.putaway",
      "travelToNextPickTotalTime.putaway",
    ],
  },

  // Batch Bot Settings
  "activeRobotLphDesignVol.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const linesPerMission =
          calculationMap["linesPerMission.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const robotJobsPerHour =
          calculationMap["robotJobsPerHour.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        return safeMultiply(linesPerMission, robotJobsPerHour);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates lines per hour per active robot at design volume",
    calculation: "lph = linesPerMission × robotJobsPerHour",
    dependencies: ["linesPerMission.picking", "robotJobsPerHour.picking"],
  },

"activeRobotLphDesignVol.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const linesPerMission = calculationMap["linesPerMission.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
      ) || 0;
      const avgMissionTimeSec = calculationMap["averageMissionTimeSec.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
      ) || 0;

      // Formula: linesPerMission / (avgMissionTimeSec / 3600)
      return safeDivide(linesPerMission * 3600, avgMissionTimeSec);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates lines per hour per active robot at design volume",
  calculation: "linesPerMission / (averageMissionTimeSec / 3600)",
  dependencies: ["linesPerMission.putaway", "averageMissionTimeSec.putaway"],
},

  // Robot Speed
  "activeRobotUphDesignVol.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const lph =
          getNestedValue(results, "activeRobotLphDesignVol.picking") || 0;
        const unitsPerLine =
        getNestedValue(results, "unitsPerLine.picking") || 1;
        return lph * unitsPerLine;
      } catch (error) {
        // console.log('Error in activeRobotUphDesignVol.picking calculation:', error);
        return 0;
      }
    },
    description: "Calculates units per hour per active robot",
    calculation: "Active robot LPH * Units per line",
    dependencies: [
      "activeRobotLphDesignVol.picking",
      "designVolumes.unitsPerLine.picking",
    ],
  },

  "activeRobotUphDesignVol.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const lph =
          getNestedValue(results, "activeRobotLphDesignVol.putaway") || 0;
        const unitsPerLine =
        getNestedValue(results, "unitsPerLine.putaway") || 1;
        return lph * unitsPerLine;
      } catch (error) {
        // console.log('Error in activeRobotUphDesignVol.putaway calculation:', error);
        return 0;
      }
    },
    description: "Calculates units per hour per active robot",
    calculation: "Active robot LPH * Units per line",
    dependencies: [
      "activeRobotLphDesignVol.putaway",
      "designVolumes.unitsPerLine.putaway",
    ],
  },

  // Lines per Mission
  "activeRobots.picking": {
    // C135/C138 = Total Jobs per Hour Required / Robot Jobs per Hour
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Replace results access with direct compute calls
        const jobsPerHourRequired =
          calculationMap["totalJobsPerHourRequired.picking"]?.compute(
            userInput,
          ) || 0;
        const robotJobsPerHour =
          calculationMap["robotJobsPerHour.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 1;
        return safeDivide(jobsPerHourRequired, robotJobsPerHour);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates number of active robots needed",
    calculation:
      "activeRobots = CEILING(totalJobsPerHourRequired / robotJobsPerHour)",
    dependencies: [
      "totalJobsPerHourRequired.picking",
      "robotJobsPerHour.picking",
    ],
  },

  "activeRobots.putaway": {
    // C135/C138 = Total Jobs per Hour Required / Robot Jobs per Hour
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Replace results access with direct compute calls
        const jobsPerHourRequired =
          calculationMap["totalJobsPerHourRequired.putaway"]?.compute(
            userInput,
          ) || 0;
        const robotJobsPerHour =
          calculationMap["robotJobsPerHour.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 1;
        return safeDivide(jobsPerHourRequired, robotJobsPerHour);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates number of active robots needed",
    calculation:
      "activeRobots = CEILING(totalJobsPerHourRequired / robotJobsPerHour)",
    dependencies: [
      "totalJobsPerHourRequired.putaway",
      "robotJobsPerHour.putaway",
    ],
  },

  // Lines per Stop
  "allRobotLphDesignVol.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const designLinesPerHour =
          calculationMap["designLinesPerHour.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const totalRobots =
          calculationMap["totalRobots.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
          return safeDivide(designLinesPerHour, totalRobots);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total lines per hour for all active robots",
    calculation: "totalLph = activeRobots × activeRobotLphDesignVol",
    dependencies: ["activeRobots.picking", "activeRobotLphDesignVol.picking"],
  },

  "allRobotLphDesignVol.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const designLinesPerHour = calculationMap["designLinesPerHour.putaway"]?.compute(
          userInput,
          staticInputs,
          lookupReference,
          results,
        ) || 0;
        const totalRobots = calculationMap["totalRobots.putaway"]?.compute(
          userInput,
          staticInputs,
          lookupReference
        ) || 0;
        return safeDivide(designLinesPerHour, totalRobots);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total lines per hour for all active robots in putaway",
    calculation: "totalLph = activeRobots × activeRobotLphDesignVol",
    dependencies: ["activeRobots.putaway", "activeRobotLphDesignVol.putaway"],
  },

  "allRobotUphDesignVol.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const allRobotLphDesignVol =
          calculationMap["allRobotLphDesignVol.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const unitsPerLine =
          calculationMap["unitsPerLine.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        return safeMultiply(allRobotLphDesignVol, unitsPerLine);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total lines per hour for all active robots",
    calculation: "totalLph = activeRobots × activeRobotUphDesignVol",
    dependencies: ["activeRobots.picking", "activeRobotUphDesignVol.picking"],
  },

  "allRobotUphDesignVol.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const allRobotLphDesignVol =
          calculationMap["allRobotLphDesignVol.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const unitsPerLine =
          calculationMap["unitsPerLine.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
          return safeMultiply(allRobotLphDesignVol, unitsPerLine);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total lines per hour for all active robots",
    calculation: "totalLph = activeRobots × activeRobotUphDesignVol",
    dependencies: ["activeRobots.putaway", "activeRobotUphDesignVol.putaway"],
  },


  "autonomousLoadTime.picking": {
    compute: (userInput, staticInputs) => {
      try {
        // Instead of '=I16'
        return staticInputs?.autonomousLoadTime || 0;
      } catch (error) {
        // console.log('Error in autonomousLoadTime.picking calculation:', error);
        return 0;
      }
    },
    description: "Gets autonomous load time from static inputs",
    calculation: "Uses staticInputs.autonomousLoadTime value",
    dependencies: ["staticInputs.autonomousLoadTime"],
  },

  // Task Time Calculations
  "autonomousLoadTime.putaway": {
    compute: (userInput, staticInputs) => {
      try {
        // Instead of '=I16'
        return staticInputs?.autonomousLoadTime || 0;
      } catch (error) {
        // console.log('Error in autonomousLoadTime.putaway calculation:', error);
        return 0;
      }
    },
    description: "Gets autonomous load time from static inputs",
    calculation: "Uses staticInputs.autonomousLoadTime value",
    dependencies: ["staticInputs.autonomousLoadTime"],
  },

  "autonomousUnloadTime.picking": {
    compute: (userInput, staticInputs) => {
      try {
        return staticInputs?.autonomousUnloadTime || 0;
      } catch (error) {
        return 0;
      }
    },
    description: "Gets autonomous unload time from static inputs",
    calculation: "Direct value from staticInputs.autonomousUnloadTime",
    dependencies: ["staticInputs.autonomousUnloadTime"],
  },

  "autonomousUnloadTime.putaway": {
    compute: (userInput, staticInputs) => {
      try {
        return staticInputs?.autonomousUnloadTime || 0;
      } catch (error) {
        return 0;
      }
    },
    description: "Gets autonomous unload time from static inputs",
    calculation: "Direct value from staticInputs.autonomousUnloadTime",
    dependencies: ["staticInputs.autonomousUnloadTime"],
  },

"availableRobots.picking": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const activeRobots = calculationMap["activeRobots.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const averageAvailableTime = calculationMap["averageAvailableTime.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      return safeMultiply(activeRobots, averageAvailableTime);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates number of robots available for work",
  calculation: "activeRobots × averageAvailableTime",
  dependencies: ["activeRobots.picking", "averageAvailableTime.picking"],
},

"availableRobots.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const activeRobots = calculationMap["activeRobots.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const averageAvailableTime = calculationMap["averageAvailableTime.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      return safeMultiply(activeRobots, averageAvailableTime);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates number of robots available for work",
  calculation: "activeRobots × averageAvailableTime",
  dependencies: ["activeRobots.putaway", "averageAvailableTime.putaway"],
},

  "averageActivePickingPercentage.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Replace results access with direct compute calls
        const activeTime =
          calculationMap["activePickTime.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const missionTime =
          calculationMap["averageMissionTimeSec.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 1;
        return safeDivide(activeTime, missionTime);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates percentage of time spent actively picking",
    calculation: "percentage = activePickTime / averageMissionTimeSec",
    dependencies: ["activePickTime.picking", "averageMissionTimeSec.picking"],
  },

"averageActivePickingPercentage.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const activePickTime = calculationMap["activePickTime.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const avgMissionTimeSec = calculationMap["averageMissionTimeSec.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
          return safeDivide(activePickTime, avgMissionTimeSec);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates average active picking percentage",
  calculation: "activePickTime / averageMissionTimeSec",
  dependencies: ["activePickTime.putaway", "averageMissionTimeSec.putaway"],
},

"averageAvailableTime.picking": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const travelToFirstPickTime = calculationMap["travelToFirstPickTime.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const isBatchBot = calculationMap["batchBot.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) === "TRUE";
      const waitForFteTotalTime = calculationMap["waitForFteTotalTime.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const travelToNextPickTotalTime = calculationMap["travelToNextPickTotalTime.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const waitForFteTime = calculationMap["waitForFteTime.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const avgMissionTimeSec = calculationMap["averageMissionTimeSec.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const numerator = travelToFirstPickTime + (isBatchBot ? waitForFteTime : (waitForFteTotalTime + travelToNextPickTotalTime));
      return  safeDivide(numerator, avgMissionTimeSec);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates average available time ratio",
  calculation: "(travelToFirstPickTime + IF(batchBot=FALSE, waitForFteTotalTime + travelToNextPickTotalTime, waitForFteTime)) / averageMissionTimeSec",
  dependencies: [
    "travelToFirstPickTime.picking",
    "batchBot.picking",
    "waitForFteTotalTime.picking",
    "travelToNextPickTotalTime.picking",
    "waitForFteTime.picking",
    "averageMissionTimeSec.picking"
  ],
},

"averageAvailableTime.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const travelToFirstPickTime = calculationMap["travelToFirstPickTime.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const isBatchBot = calculationMap["batchBot.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) === "TRUE";
      const waitForFteTotalTime = calculationMap["waitForFteTotalTime.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const travelToNextPickTotalTime = calculationMap["travelToNextPickTotalTime.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const waitForFteTime = calculationMap["waitForFteTime.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const avgMissionTimeSec = calculationMap["averageMissionTimeSec.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const numerator = travelToFirstPickTime + (isBatchBot ? waitForFteTime : (waitForFteTotalTime + travelToNextPickTotalTime));
      return safeDivide(numerator, avgMissionTimeSec);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates average available time ratio",
  calculation: "(travelToFirstPickTime + IF(batchBot=FALSE, waitForFteTotalTime + travelToNextPickTotalTime, waitForFteTime)) / averageMissionTimeSec",
  dependencies: [
    "travelToFirstPickTime.putaway",
    "batchBot.putaway",
    "waitForFteTotalTime.putaway",
    "travelToNextPickTotalTime.putaway",
    "waitForFteTime.putaway",
    "averageMissionTimeSec.putaway"
  ],
},

  "averageMissionTimeMin.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // return safeDivide(results["averageMissionTimeSec.picking"] || 0, 60);
        return safeDivide(
          calculationMap["averageMissionTimeSec.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0,
          60,
        );
      } catch (error) {
        // console.log('Error in averageMissionTimeMin.picking calculation:', error);
        return 0;
      }
    },
    description: "Converts average mission time from seconds to minutes",
    calculation: "missionTimeMin = averageMissionTimeSec / 60",
    dependencies: ["averageMissionTimeSec.picking"],
  },

"averageMissionTimeMin.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const avgMissionTimeSec = calculationMap["averageMissionTimeSec.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      return  safeDivide(avgMissionTimeSec, 60);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates average mission time in minutes",
  calculation: "averageMissionTimeSec / 60",
  dependencies: ["averageMissionTimeSec.putaway"],
},

  //
"averageMissionTimeSec.picking": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      // Get autonomous load/unload times
      const autonomousLoadTime = staticInputs?.autonomousLoadTime || 0;
      const autonomousUnloadTime = staticInputs?.autonomousUnloadTime || 0;

      // Get all component times
      const inductLoadTime = calculationMap["inductTotalTime.picking"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const travelToFirstPickTime = calculationMap["travelToFirstPickTime.picking"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const waitForFteTotal = calculationMap["waitForFteTotalTime.picking"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const pickLineTotal = calculationMap["pickLineTotalTime.picking"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const travelNextPickTotal = calculationMap["travelToNextPickTotalTime.picking"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const travelToDropoffTime = calculationMap["travelToDropoffTotalTime.picking"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const dropoffQueueTime = calculationMap["dropoffQueueTotalTime.picking"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const dropoffTime = calculationMap["dropoffTotalTime.picking"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const travelToInductTime = calculationMap["travelToInductTotalTime.picking"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const inductQueueTime = calculationMap["inductQueueTotalTime.picking"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      // Calculate total according to Excel formula:
      // =IFERROR(IF(C105>0,C105,C104)+C109+C112+C115+C122+C125+C126+IF(C128>0,C128,C127)+C130+C131,"")
      const totalTime = 
        (autonomousLoadTime > 0 ? autonomousLoadTime : inductLoadTime) +
        travelToFirstPickTime +
        waitForFteTotal +
        pickLineTotal + 
        travelNextPickTotal +
        travelToDropoffTime +
        dropoffQueueTime +
        (autonomousUnloadTime > 0 ? autonomousUnloadTime : dropoffTime) +
        travelToInductTime +
        inductQueueTime;

      return totalTime;
    } catch (error) {
      console.error('Error in averageMissionTimeSec.picking calculation:', error);
      return 0;
    }
  },
  description: "Calculates total mission time in seconds",
  calculation: "Sum of all component times with conditional autonomous times",
  dependencies: [
    "inductTotalTime.picking",
    "travelToFirstPickTime.picking", 
    "waitForFteTotalTime.picking",
    "pickLineTotalTime.picking",
    "travelToNextPickTotalTime.picking",
    "travelToDropoffTotalTime.picking",
    "dropoffQueueTotalTime.picking",
    "dropoffTotalTime.picking",
    "travelToInductTotalTime.picking",
    "inductQueueTotalTime.picking"
  ]
},

"averageMissionTimeSec.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      // Get autonomous load/unload times
      const autonomousLoadTime = staticInputs?.autonomousLoadTime || 0;
      const autonomousUnloadTime = staticInputs?.autonomousUnloadTime || 0;

      // Get all component times
      const inductLoadTime = calculationMap["inductTotalTime.putaway"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const travelToFirstPickTime = calculationMap["travelToFirstPickTime.putaway"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const waitForFteTotal = calculationMap["waitForFteTotalTime.putaway"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const pickLineTotal = calculationMap["pickLineTotalTime.putaway"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const travelNextPickTotal = calculationMap["travelToNextPickTotalTime.putaway"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const travelToDropoffTime = calculationMap["travelToDropoffTotalTime.putaway"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const dropoffQueueTime = calculationMap["dropoffQueueTotalTime.putaway"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const dropoffTime = calculationMap["dropoffTotalTime.putaway"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const travelToInductTime = calculationMap["travelToInductTotalTime.putaway"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      const inductQueueTime = calculationMap["inductQueueTotalTime.putaway"].compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      // Calculate total according to Excel formula:
      // =IFERROR(IF(D105>0,D105,D104)+D109+D112+D115+D122+D125+D126+IF(D128>0,D128,D127)+D130+D131,"")
      
      const totalTime = 
        (autonomousLoadTime > 0 ? autonomousLoadTime : inductLoadTime) +
        travelToFirstPickTime +
        waitForFteTotal +
        pickLineTotal + 
        travelNextPickTotal +
        travelToDropoffTime +
        dropoffQueueTime +
        (autonomousUnloadTime > 0 ? autonomousUnloadTime : dropoffTime) +
        travelToInductTime +
        inductQueueTime;

    return  totalTime;
    } catch (error) {
      console.error('Error in averageMissionTimeSec.putaway calculation:', error);
      return 0;
    }
  },
  description: "Calculates total mission time in seconds for putaway",
  calculation: "Sum of all component times with conditional autonomous times",
  dependencies: [
    "inductTotalTime.putaway",
    "travelToFirstPickTime.putaway", 
    "waitForFteTotalTime.putaway",
    "pickLineTotalTime.putaway",
    "travelToNextPickTotalTime.putaway",
    "travelToDropoffTotalTime.putaway",
    "dropoffQueueTotalTime.putaway",
    "dropoffTotalTime.putaway",
    "travelToInductTotalTime.putaway",
    "inductQueueTotalTime.putaway"
  ]
},

"averageRobotMissionDistance.picking": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const travelToFirstPickDistance = calculationMap["travelToFirstPickDistance.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const travelToNextPickQty = calculationMap["travelToNextPickQty.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const travelToNextPickDistance = calculationMap["travelToNextPickDistance.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const travelToDropoffDistance = calculationMap["travelToDropoffDistance.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const travelToInductDistance = calculationMap["travelToInductDistance.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      return travelToFirstPickDistance + (travelToNextPickQty * travelToNextPickDistance) + travelToDropoffDistance + travelToInductDistance;
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates total distance traveled in one mission",
  calculation: "travelToFirstPickDistance + (travelToNextPickQty * travelToNextPickDistance) + travelToDropoffDistance + travelToInductDistance",
  dependencies: [
    "travelToFirstPickDistance.picking",
    "travelToNextPickQty.picking",
    "travelToNextPickDistance.picking",
    "travelToDropoffDistance.picking",
    "travelToInductDistance.picking"
  ],
},

"averageRobotMissionDistance.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const travelToFirstPickDistance = calculationMap["travelToFirstPickDistance.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const travelToNextPickQty = calculationMap["travelToNextPickQty.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const travelToNextPickDistance = calculationMap["travelToNextPickDistance.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const travelToDropoffDistance = calculationMap["travelToDropoffDistance.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const travelToInductDistance = calculationMap["travelToInductDistance.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      return travelToFirstPickDistance + (travelToNextPickQty * travelToNextPickDistance) + travelToDropoffDistance + travelToInductDistance;
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates total distance traveled in one mission",
  calculation: "travelToFirstPickDistance + (travelToNextPickQty * travelToNextPickDistance) + travelToDropoffDistance + travelToInductDistance",
  dependencies: [
    "travelToFirstPickDistance.putaway",
    "travelToNextPickQty.putaway",
    "travelToNextPickDistance.putaway",
    "travelToDropoffDistance.putaway",
    "travelToInductDistance.putaway"
  ],
},

  "avgJobPercentageOfZone.picking": {
    compute: (userInput, staticInputs) => {
      try {
        // Get picking average job percentage of zone from static inputs
        return staticInputs?.pickingAvgJobPercentOfZone || 0;
      } catch (error) {
        // console.log('Error in avgJobPercentageOfZone.picking calculation:', error);
        return 0;
      }
    },
    description:
      "Gets picking average job percentage of zone from static inputs",
    calculation: "Direct value from staticInputs.pickingAvgJobPercentOfZone",
    dependencies: ["staticInputs.pickingAvgJobPercentOfZone"],
  },

  "avgJobPercentageOfZone.putaway": {
    compute: (userInput, staticInputs) => {
      try {
        // Get putaway average job percentage of zone from static inputs
        return staticInputs?.putawayAvgJobPercentOfZone || 0;
      } catch (error) {
        // console.log('Error in avgJobPercentageOfZone.putaway calculation:', error);
        return 0;
      }
    },
    description:
      "Gets putaway average job percentage of zone from static inputs",
    calculation: "Direct value from staticInputs.putawayAvgJobPercentOfZone",
    dependencies: ["staticInputs.putawayAvgJobPercentOfZone"],
  },

  "avgTasksPerAisle.picking": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const linesPerMission = calculationMap["linesPerMission.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
      ) || 0;
      const linesPerStop = calculationMap["linesPerStop.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
      ) || 0;
      const pickingAvgJobPercentOfZone = staticInputs?.pickingAvgJobPercentOfZone || 0; // I10
      const numberOfAislesInRobotArea = userInput?.numberOfAislesInRobotArea || 0; // C15

      return safeDivide(safeDivide(linesPerMission, linesPerStop), 
        safeMultiply(pickingAvgJobPercentOfZone, numberOfAislesInRobotArea));
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates average tasks per aisle for picking",
  calculation: "(linesPerMission / linesPerStop) / (pickingAvgJobPercentOfZone * numberOfAislesInRobotArea)",
  dependencies: [
    "linesPerMission.picking",
    "linesPerStop.picking",
    "staticInputs.pickingAvgJobPercentOfZone",
    "numberOfAislesInRobotArea"
  ],
},

"avgTasksPerAisle.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const linesPerMission = calculationMap["linesPerMission.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
      ) || 0;
      const linesPerStop = calculationMap["linesPerStop.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
      ) || 0;
      const putawayAvgJobPercentOfZone = staticInputs?.putawayAvgJobPercentOfZone || 0; // I11
      const numberOfAislesInRobotArea = userInput?.numberOfAislesInRobotArea || 0; // C15

    return  safeDivide(safeDivide(linesPerMission, linesPerStop), 
        safeMultiply(putawayAvgJobPercentOfZone, numberOfAislesInRobotArea));
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates average tasks per aisle for putaway",
  calculation: "(linesPerMission / linesPerStop) / (putawayAvgJobPercentOfZone * numberOfAislesInRobotArea)",
  dependencies: [
    "linesPerMission.putaway",
    "linesPerStop.putaway",
    "staticInputs.putawayAvgJobPercentOfZone",
    "numberOfAislesInRobotArea"
  ],
},

  "batchBot.picking": {
    compute: (userInput, staticInputs) => {
      try {
        // Use batchBot setting directly from static inputs
        return staticInputs?.batchBot || "FALSE";
      } catch (error) {
        // console.log('Error in batchBot.picking calculation:', error);
        return "FALSE";
      }
    },
    description: "Uses the batch bot setting from static inputs",
    calculation: "Direct value from batchBot static input",
    dependencies: ["staticInputs.batchBot"],
  },

  "batchBot.putaway": {
    compute: (userInput, staticInputs) => {
      try {
        // Use batchBot setting directly from static inputs
        return staticInputs?.batchBot || "FALSE";
      } catch (error) {
        // console.log('Error in batchBot.putaway calculation:', error);
        return "FALSE";
      }
    },
    description: "Uses the batch bot setting from static inputs",
    calculation: "Direct value from batchBot static input",
    dependencies: ["staticInputs.batchBot"],
  },

  "chargingBuffer.picking": {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        const robotType = userInput?.robotOrigin || "";
        const robotSpecs = lookupReference?.robotSpecs?.[userInput.metricOrImperial.toLowerCase()] || {};
        const robotKey = robotType.split(" ")[0].toLowerCase();
        return robotSpecs[robotKey].buffer || 0; // C141 =IFERROR(XLOOKUP(C23,B41:B48,D41:D48,"",0),"")
      } catch (error) {
        return 0;
      }
    },
    description: "Looks up charging buffer factor based on robot type",
    calculation: "XLOOKUP robot type to get charging buffer factor",
    dependencies: ["robotOrigin", "lookupReference.robotSpecs.chargingBuffer"],
  },

  "chargingBuffer.putaway": {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        const robotType = userInput?.robotOrigin || "";
        const robotSpecs = lookupReference?.robotSpecs?.[userInput.metricOrImperial.toLowerCase()] || {};
        const robotKey = robotType.split(" ")[0].toLowerCase();
        return robotSpecs[robotKey].buffer || 0; 
      } catch (error) {
        return 0;
      }
    },
    description: "Looks up charging buffer factor based on robot type",
    calculation: "XLOOKUP robot type to get charging buffer factor",
    dependencies: ["robotOrigin", "lookupReference.robotSpecs.chargingBuffer"],
  },

  // Average Tasks per Aisle
  "chargingRobots.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const activeRobots = calculationMap["activeRobots.picking"]?.compute(
          userInput,
          staticInputs,
          lookupReference,
          results
        ) || 0;
        const chargingFactor = calculationMap["chargingBuffer.picking"]?.compute(
          userInput,
          staticInputs,
          lookupReference,
          results
        ) || 0;
        return safeMultiply(activeRobots, chargingFactor); // C142 =C141*C140
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total number of robots needed for charging",
    calculation: "chargingRobots = activeRobots × chargingFactor",
    dependencies: ["activeRobots.picking", "chargingBuffer.picking"],
  },

  "chargingRobots.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const activeRobots = calculationMap["activeRobots.putaway"]?.compute(
          userInput,
          staticInputs,
          lookupReference,
          results
        ) || 0;
        const chargingFactor = calculationMap["chargingBuffer.putaway"]?.compute(
          userInput,
          staticInputs,
          lookupReference,
          results
        ) || 0;
        return safeMultiply(activeRobots, chargingFactor); // D142 =D141*D140
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total number of robots needed for charging in putaway",
    calculation: "chargingRobots = activeRobots × chargingFactor",
    dependencies: ["activeRobots.putaway", "chargingBuffer.putaway"],
  },

  // Average Job Percentage of Zone
"designDirectFtes.picking": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const designVolumeWithFactor = calculationMap["designVolumeWithFactorUnitsPerHour.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const uph = calculationMap["uph.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      if (uph === 0) return 0;
      return safeDivide(designVolumeWithFactor, uph);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates number of direct FTEs needed for picking",
  calculation: "designVolumeWithFactorUnitsPerHour / uph",
  dependencies: ["designVolumeWithFactorUnitsPerHour.picking", "uph.picking"],
},

"designDirectFtes.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const designVolumeWithFactor = calculationMap["designVolumeWithFactorUnitsPerHour.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const uph = calculationMap["uph.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;

      if (uph === 0) return 0;
      return safeDivide(designVolumeWithFactor, uph);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates number of direct FTEs needed for putaway",
  calculation: "designVolumeWithFactorUnitsPerHour / uph",
  dependencies: ["designVolumeWithFactorUnitsPerHour.putaway", "uph.putaway"],
},

"designDropOffOperators.picking": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const totalJobsPerHour = calculationMap["totalJobsPerHourRequired.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // C135
      const dropoffTotalTime = calculationMap["dropoffTotalTime.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // C127
      const fteUtilization = staticInputs?.inductDropoffFteUtilization || 0; // I25

      if (fteUtilization === 0) return 0;
      return ((totalJobsPerHour * dropoffTotalTime / 60) / fteUtilization) / 60;
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates number of dropoff operators needed for picking",
  calculation: "(((totalJobsPerHour * dropoffTotalTime/60) / fteUtilization) / 60)",
  dependencies: [
    "totalJobsPerHourRequired.picking",
    "dropoffTotalTime.picking",
    "staticInputs.inductDropoffFteUtilization"
  ],
},

"designDropOffOperators.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const totalJobsPerHour = calculationMap["totalJobsPerHourRequired.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // D135
      const dropoffTotalTime = calculationMap["dropoffTotalTime.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // D127
      const fteUtilization = staticInputs?.inductDropoffFteUtilization || 0; // I25

      if (fteUtilization === 0) return 0;
      return ((totalJobsPerHour * dropoffTotalTime / 60) / fteUtilization) / 60;
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates number of dropoff operators needed for putaway",
  calculation: "(((totalJobsPerHour * dropoffTotalTime/60) / fteUtilization) / 60)",
  dependencies: [
    "totalJobsPerHourRequired.putaway",
    "dropoffTotalTime.putaway",
    "staticInputs.inductDropoffFteUtilization"
  ],
},

  // Travel to Next Pick Time
"designIndirectFtes.picking": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const inductOperators = calculationMap["designInductOperators.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const dropOffOperators = calculationMap["designDropOffOperators.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      return inductOperators + dropOffOperators;
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates total indirect FTEs needed",
  calculation: "designInductOperators + designDropOffOperators",
  dependencies: [
    "designInductOperators.picking",
    "designDropOffOperators.picking"
  ],
},

"designIndirectFtes.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const inductOperators = calculationMap["designInductOperators.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const dropOffOperators = calculationMap["designDropOffOperators.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      return inductOperators + dropOffOperators;
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates total indirect FTEs needed",
  calculation: "designInductOperators + designDropOffOperators",
  dependencies: [
    "designInductOperators.putaway",
    "designDropOffOperators.putaway"
  ],
},

  // Travel to Next Pick Qty
"designInductOperators.picking": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const totalJobsPerHour = calculationMap["totalJobsPerHourRequired.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
      ) || 0;
      const inductTotalTime = calculationMap["inductTotalTime.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
      ) || 0;
      const fteUtilization = staticInputs?.inductDropoffFteUtilization || 0.85; // I25

      // Formula: (((totalJobsPerHour * inductTotalTime/60) / fteUtilization) / 60)
      return safeDivide(safeDivide(safeMultiply(totalJobsPerHour, inductTotalTime/60), fteUtilization), 60);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates number of induct operators needed",
  calculation: "(((totalJobsPerHour * inductTotalTime/60) / fteUtilization) / 60)",
  dependencies: [
    "totalJobsPerHourRequired.picking",
    "inductTotalTime.picking",
    "staticInputs.inductDropoffFteUtilization"
  ],
},

"designInductOperators.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const totalJobsPerHour = calculationMap["totalJobsPerHourRequired.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
      ) || 0;
      const inductTotalTime = calculationMap["inductTotalTime.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
      ) || 0;
      const fteUtilization = staticInputs?.inductDropoffFteUtilization || 0.85; // I25

      // Formula: (((totalJobsPerHour * inductTotalTime/60) / fteUtilization) / 60)
      return safeDivide(safeDivide(safeMultiply(totalJobsPerHour, inductTotalTime/60), fteUtilization), 60);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates number of induct operators needed for putaway",
  calculation: "(((totalJobsPerHour * inductTotalTime/60) / fteUtilization) / 60)",
  dependencies: [
    "totalJobsPerHourRequired.putaway",
    "inductTotalTime.putaway",
    "staticInputs.inductDropoffFteUtilization"
  ],
},

  // Travel to Next Pick Total Time
  "designLinesPerHour.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.picking || 0;
        const productiveHours = userInput?.productiveHoursPerDayPicking || 0;
        return productiveHours > 0 ? linesPerDay / productiveHours : 0;
      } catch (error) {
        // console.log('Error in designLinesPerHour.picking calculation:', error);
        return 0;
      }
    },
    description: "Calculates required lines per hour based on daily volume",
    calculation: "Lines per day / Productive hours per day",
    dependencies: [
      "designVolumes.linesPerDay.picking",
      "productiveHoursPerDay",
    ],
  },

  "designLinesPerHour.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.putaway || 0;
        const productiveHours = userInput?.productiveHoursPerDayPutaway || 0;
        return productiveHours > 0 ? linesPerDay / productiveHours : 0;
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates required lines per hour based on daily volume",
    calculation: "Lines per day / Productive hours per day",
    dependencies: [
      "designVolumes.linesPerDay.putaway",
      "productiveHoursPerDay",
    ],
  },

  // Pick Area to Dropoff Adjustment
  "designVolumeUnitsPerHour.picking": {
    compute: (userInput) => {
      try {
        const unitsPerDay = userInput?.designVolumes?.unitsPerDay?.picking || 0;
        const productiveHours = userInput?.productiveHoursPerDayPicking || 1;
        return safeDivide(unitsPerDay, productiveHours); // C159 =IFERROR(C34/C21,"")
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates design volume units per hour for picking",
    calculation: "unitsPerDay / productiveHoursPerDay",
    dependencies: [
      "designVolumes.unitsPerDay.picking",
      "productiveHoursPerDayPicking",
    ],
  },

  "designVolumeUnitsPerHour.putaway": {
    compute: (userInput) => {
      try {
        const unitsPerDay = userInput?.designVolumes?.unitsPerDay?.putaway || 0;
        const productiveHours = userInput?.productiveHoursPerDayPutaway || 1;
        return safeDivide(unitsPerDay, productiveHours); // D159 =IFERROR(D34/C22,"")
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates design volume units per hour for putaway",
    calculation: "unitsPerDay / productiveHoursPerDay",
    dependencies: [
      "designVolumes.unitsPerDay.putaway",
      "productiveHoursPerDayPicking",
    ],
  },

  // Efficiency and Throughput Calculations
  "designVolumeWithFactorUnitsPerHour.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const designVolumeUph =
          calculationMap["designVolumeUnitsPerHour.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const designFactor = staticInputs?.designFactor || 1; // I24
        return safeMultiply(designVolumeUph, designFactor); // C161 =IFERROR(C159*I24,"")
      } catch (error) {
        return 0;
      }
    },
    description:
      "Calculates design volume units per hour with factor for picking",
    calculation: "designVolumeUnitsPerHour × designFactor",
    dependencies: [
      "designVolumeUnitsPerHour.picking",
      "staticInputs.designFactor",
    ],
  },

  "designVolumeWithFactorUnitsPerHour.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const designVolumeUph =
          calculationMap["designVolumeUnitsPerHour.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const designFactor = staticInputs?.designFactor || 1; // I24
        return safeMultiply(designVolumeUph, designFactor); // D161 =IFERROR(D159*I24,"")
      } catch (error) {
        return 0;
      }
    },
    description:
      "Calculates design volume units per hour with factor for putaway",
    calculation: "designVolumeUnitsPerHour × designFactor",
    dependencies: [
      "designVolumeUnitsPerHour.putaway",
      "staticInputs.designFactor",
    ],
  },

  "dropoffQueueTotalTime.picking": {
    compute: (userInput, staticInputs) => {
      try {
        // Get dropoff queue time from static inputs
        return staticInputs?.dropoffQueueTotalTime || 0;
      } catch (error) {
        // console.log('Error in dropoffQueueTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: "Gets dropoff queue total time from static inputs",
    calculation: "Direct value from staticInputs.dropoffQueueTotalTime",
    dependencies: ["staticInputs.dropoffQueueTotalTime"],
  },

  "dropoffQueueTotalTime.putaway": {
    compute: (userInput, staticInputs) => {
      try {
        // Get dropoff queue time from static inputs
        return staticInputs?.dropoffQueueTotalTime || 0;
      } catch (error) {
        // console.log('Error in dropoffQueueTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: "Gets dropoff queue total time from static inputs",
    calculation: "Direct value from staticInputs.dropoffQueueTotalTime",
    dependencies: ["staticInputs.dropoffQueueTotalTime"],
  },

  "dropoffTotalTime.picking": {
    compute: (userInput) => {
      try {
        // Get dropoff total time from design volumes
        return userInput?.designVolumes?.dropOffUnloadTime?.picking || 0;
      } catch (error) {
        // console.log('Error in dropoffTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: "Gets dropoff total time from design volumes",
    calculation: "Direct value from designVolumes.dropOffUnloadTime.picking",
    dependencies: ["designVolumes.dropOffUnloadTime.picking"],
  },

  // Robot Utilization Calculations
  "dropoffTotalTime.putaway": {
    compute: (userInput) => {
      try {
        // Get dropoff total time from design volumes
          return userInput?.designVolumes?.dropOffUnloadTime?.putaway || 0;
      } catch (error) {
        // console.log('Error in dropoffTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: "Gets dropoff total time from design volumes",
    calculation: "Direct value from designVolumes.dropOffUnloadTime.putaway",
    dependencies: ["designVolumes.dropOffUnloadTime.putaway"],
  },

"inductDropoffLocationEntry.picking": {
  compute: (userInput, staticInputs, lookupReference) => {
    try {
      // Direct pass-through from userInput.inductDropoffLocationEntry (C18)
      return userInput?.inductDropoffLocationEntry || "";
    } catch (error) {
      return "";
    }
  },
  description: "Gets induct/dropoff location entry setting",
  calculation: "Direct copy of inductDropoffLocationEntry input",
  dependencies: ["inductDropoffLocationEntry"],
},

"inductDropoffLocationEntry.putaway": {
  compute: (userInput, staticInputs, lookupReference) => {
    try {
      // Direct pass-through from userInput.inductDropoffLocationEntry (C18)
      return userInput?.inductDropoffLocationEntry || "";
    } catch (error) {
      return "";
    }
  },
  description: "Gets induct/dropoff location entry setting",
  calculation: "Direct copy of inductDropoffLocationEntry input",
  dependencies: ["inductDropoffLocationEntry"],
},

  "inductQueueTotalTime.picking": {
    compute: (userInput, staticInputs) => {
      try {
        return userInput?.designVolumes?.inductQueueTime?.picking || 0;
      } catch (error) {
        // console.log('Error in inductQueueTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: "Gets the total queue time at induct",
    calculation: "Direct value from designVolumes.inductQueueTime.picking",
    dependencies: ["designVolumes.inductQueueTime.picking"],
  },

  "inductQueueTotalTime.putaway": {
    compute: (userInput, staticInputs) => {
      try {
        return staticInputs?.inductQueueTotalTime || 0; // I18
      } catch (error) {
        return 0;
      }
    },
    description: "Gets induct queue total time from static inputs",
    calculation: "Direct value from staticInputs.inductQueueTotalTime",
    dependencies: ["staticInputs.inductQueueTotalTime"],
  },

  // Performance Metrics
  "inductToPickArea.picking": {
    compute: (userInput, staticInputs) => {
      try {
        // =IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),"")
        const isImperial = userInput?.metricOrImperial === "Imperial";
        const inductToPickArea = staticInputs?.inductToPickArea || 0;

        if (isImperial) {
          return inductToPickArea;
        } else {
          return convertImperialToMetric(inductToPickArea, "ft", "m");
        }
      } catch (error) {
        // console.log('Error in inductToPickArea.picking calculation:', error);
        return 0;
      }
    },
    description: "Calculates induct to pick area distance with unit conversion",
    calculation: '=IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),""))',
    dependencies: ["metricOrImperial", "staticInputs.inductToPickArea"],
  },

  "inductToPickArea.putaway": {
    compute: (userInput, staticInputs) => {
      try {
        // =IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),"")
        const isImperial = userInput?.metricOrImperial === "Imperial";
        const inductToPickArea = staticInputs?.inductToPickArea || 0;

        if (isImperial) {
          return inductToPickArea;
        } else {
          return convertImperialToMetric(inductToPickArea, "ft", "m");
        }
      } catch (error) {
        // console.log('Error in inductToPickArea.putaway calculation:', error);
        return 0;
      }
    },
    description: "Calculates induct to pick area distance with unit conversion",
    calculation: '=IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),""))',
    dependencies: ["metricOrImperial", "staticInputs.inductToPickArea"],
  },

  // FTE Calculations
  "inductTotalTime.picking": {
    compute: (userInput) => {
      try {
        // Instead of '=C30'
        return userInput?.designVolumes?.inductLoadTime?.picking || 0;
      } catch (error) {
        // console.log('Error in inductTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: "Gets induct total time from design volumes",
    calculation: "Uses userInput.designVolumes.inductLoadTime.picking value",
    dependencies: ["designVolumes.inductLoadTime.picking"],
  },

  "inductTotalTime.putaway": {
    compute: (userInput) => {
      try {
        // Instead of '=D30'
        return userInput?.designVolumes?.inductLoadTime?.putaway || 0;
      } catch (error) {
        // console.log('Error in inductTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: "Gets induct total time from design volumes",
    calculation: "Uses userInput.designVolumes.inductLoadTime.putaway value",
    dependencies: ["designVolumes.inductLoadTime.putaway"],
  },

  "linesPerCycle.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const repeatedAction =
          calculationMap["repeatedAction.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || "";
        const linesPerStop =
          calculationMap["linesPerStop.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const linesPerMission =
          calculationMap["linesPerMission.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;

        return repeatedAction === "Location" ? linesPerStop : linesPerMission;
      } catch (error) {
        return 0;
      }
    },
    description:
      "Determines lines per cycle based on repeated action type for picking",
    calculation: "IF(repeatedAction='Location', linesPerStop, linesPerMission)",
    dependencies: [
      "repeatedAction.picking",
      "linesPerStop.picking",
      "linesPerMission.picking",
    ],
  },

  "linesPerCycle.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const repeatedAction =
          calculationMap["repeatedAction.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || "";
        const linesPerStop =
          calculationMap["linesPerStop.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const linesPerMission =
          calculationMap["linesPerMission.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;

        return repeatedAction === "Location" ? linesPerStop : linesPerMission;
      } catch (error) {
        return 0;
      }
    },
    description:
      "Determines lines per cycle based on repeated action type for putaway",
    calculation: "IF(repeatedAction='Location', linesPerStop, linesPerMission)",
    dependencies: [
      "repeatedAction.putaway",
      "linesPerStop.putaway",
      "linesPerMission.putaway",
    ],
  },

  "linesPerMission.picking": {
    compute: (userInput) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.picking || 0;
        const ordersPerDay =
          userInput?.designVolumes?.ordersPerDay?.picking || 0;
        const containersPerMission =
          userInput?.designVolumes?.containersPerMission?.picking || 0;

        // Calculate lines per mission based on daily volumes
        if (ordersPerDay === 0) return 0;
        return (linesPerDay / ordersPerDay) * containersPerMission;
      } catch (error) {
        console.log("Error in linesPerMission.picking calculation:", error);
        return 0;
      }
    },
    description:
      "Calculates lines per mission based on daily volumes and containers per mission",
    calculation: "(linesPerDay / ordersPerDay) × containersPerMission",
    dependencies: [
      "designVolumes.linesPerDay.picking",
      "designVolumes.ordersPerDay.picking",
      "designVolumes.containersPerMission.picking",
    ],
  },

  "linesPerMission.putaway": {
    compute: (userInput) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.putaway || 0;
        const ordersPerDay =
          userInput?.designVolumes?.ordersPerDay?.putaway || 0;
        const containersPerMission =
          userInput?.designVolumes?.containersPerMission?.putaway || 0;

        // Calculate lines per mission based on daily volumes
        if (ordersPerDay === 0) return 0;
        return (linesPerDay / ordersPerDay) * containersPerMission;
      } catch (error) {
        // console.log('Error in linesPerMission.putaway calculation:', error);
        return 0;
      }
    },
    description:
      "Calculates lines per mission based on daily volumes and containers per mission",
    calculation: "(linesPerDay / ordersPerDay) × containersPerMission",
    dependencies: [
      "designVolumes.linesPerDay.putaway",
      "designVolumes.ordersPerDay.putaway",
      "designVolumes.containersPerMission.putaway",
    ],
  },

  "linesPerStop.picking": {
    compute: (userInput) => {
      try {
        // Direct value from design volumes
        return userInput?.designVolumes?.linesPerStop?.picking || 0;
      } catch (error) {
        // console.log('Error in linesPerStop.picking calculation:', error);
        return 0;
      }
    },
    description: "Gets lines per stop value from design volumes",
    calculation: "Direct value from designVolumes.linesPerStop.picking",
    dependencies: ["designVolumes.linesPerStop.picking"],
  },

  "linesPerStop.putaway": {
    compute: (userInput) => {
      try {
        // Direct value from design volumes
        return userInput?.designVolumes?.linesPerStop?.putaway || 0;
      } catch (error) {
        // console.log('Error in linesPerStop.putaway calculation:', error);
        return 0;
      }
    },
    description: "Gets lines per stop value from design volumes",
    calculation: "Direct value from designVolumes.linesPerStop.putaway",
    dependencies: ["designVolumes.linesPerStop.putaway"],
  },

  "lph.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const timePerCycleSeconds =
          calculationMap["timePerCycleSeconds.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const linesPerCycle =
          calculationMap["linesPerCycle.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const pickerPfd = staticInputs?.pickerPfd || 1; // I14 in the spreadsheet

        if (timePerCycleSeconds === 0 || linesPerCycle === 0) return 0;

        return (3600 / (timePerCycleSeconds / linesPerCycle) * pickerPfd);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates lines per hour for picking operations",
    calculation: "(3600 / (timePerCycleSeconds / linesPerCycle)) * pickerPfd",
    dependencies: ["timePerCycleSeconds.picking", "linesPerCycle.picking"],
  },

  "lph.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const timePerCycleSeconds =
          calculationMap["timePerCycleSeconds.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const linesPerCycle =
          calculationMap["linesPerCycle.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const pickerPfd = staticInputs?.pickerPfd || 1; // I14 in the spreadsheet

        if (timePerCycleSeconds === 0 || linesPerCycle === 0) return 0;

        return (3600 / (timePerCycleSeconds / linesPerCycle) * pickerPfd);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates lines per hour for putaway operations",
    calculation: "(3600 / (timePerCycleSeconds / linesPerCycle)) * pickerPfd",
    dependencies: ["timePerCycleSeconds.putaway", "linesPerCycle.putaway"],
  },

"navigationFactor.picking": {
  compute: (userInput, staticInputs, lookupReference) => {
    try {
      const passingCapability = userInput?.determinedPassingCapability || "";
      const navFactor = lookupReference?.navFactor || {};

      // XLOOKUP(C24,B61:B64,C61:C64,"",0)
      // If no match is found, Excel formula returns empty string, then 0
      return navFactor[passingCapability] || 0;
    } catch (error) {
      return 0;
    }
  },
  description: "Looks up navigation factor based on passing capability",
  calculation: "XLOOKUP(determinedPassingCapability, passingCapabilityRange, navFactorRange, '', 0)",
  dependencies: ["determinedPassingCapability", "lookupReference.navFactor"],
},

"navigationFactor.putaway": {
  compute: (userInput, staticInputs, lookupReference) => {
    try {
      const passingCapability = userInput?.determinedPassingCapability || "";
      const navFactor = lookupReference?.navFactor || {};
      // XLOOKUP(C24,B61:B64,C61:C64,"",0)
      // If no match is found, Excel formula returns empty string, then 0
      return navFactor[passingCapability] || 0;
    } catch (error) {
      return 0;
    }
  },
  description: "Looks up navigation factor based on passing capability",
  calculation: "XLOOKUP(determinedPassingCapability, passingCapabilityRange, navFactorRange, '', 0)",
  dependencies: ["determinedPassingCapability", "lookupReference.navFactor"],
},

  "pickAreaToDropoffAdjustment.picking": {
    compute: (userInput, staticInputs) => {
      try {
        // Convert pick area to dropoff adjustment based on measurement system
        const isImperial = userInput?.metricOrImperial === "Imperial";
        const adjustment = staticInputs?.pickAreaToDropoffAdjustment || 0;

        if (isImperial) {
          return adjustment;
        } else {
          return convertImperialToMetric(adjustment, "ft", "m");
        }
      } catch (error) {
        // console.log('Error in pickAreaToDropoffAdjustment.picking calculation:', error);
        return 0;
      }
    },
    description:
      "Converts pick area to dropoff adjustment based on measurement system",
    calculation:
      "If Imperial: adjustment value, else: convert adjustment from ft to m",
    dependencies: [
      "metricOrImperial",
      "staticInputs.pickAreaToDropoffAdjustment",
    ],
  },

  "pickAreaToDropoffAdjustment.putaway": {
    compute: (userInput, staticInputs) => {
      try {
        // Convert pick area to dropoff adjustment based on measurement system
        const isImperial = userInput?.metricOrImperial === "Imperial";
        const adjustment = staticInputs?.pickAreaToDropoffAdjustment || 0;

        if (isImperial) {
          return adjustment;
        } else {
          return convertImperialToMetric(adjustment, "ft", "m");
        }
      } catch (error) {
        // console.log('Error in pickAreaToDropoffAdjustment.putaway calculation:', error);
        return 0;
      }
    },
    description:
      "Converts pick area to dropoff adjustment based on measurement system",
    calculation:
      "If Imperial: adjustment value, else: convert adjustment from ft to m",
    dependencies: [
      "metricOrImperial",
      "staticInputs.pickAreaToDropoffAdjustment",
    ],
  },

  "pickLineQty.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Use lines per mission value
        return calculationMap["linesPerMission.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
      } catch (error) {
        // console.log('Error in pickLineQty.picking calculation:', error);
        return 0;
      }
    },
    description: "Uses lines per mission as pick line quantity",
    calculation: "Direct value from linesPerMission.picking",
    dependencies: ["linesPerMission.picking"],
  },

  "pickLineQty.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Use lines per mission value
        return calculationMap["linesPerMission.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
      } catch (error) {
        // console.log('Error in pickLineQty.putaway calculation:', error);
        return 0;
      }
    },
    description: "Uses lines per mission as pick line quantity",
    calculation: "Direct value from linesPerMission.putaway",
    dependencies: ["linesPerMission.putaway"],
  },

  "pickLineTime.picking": {
    compute: (userInput) => {
      try {
        // Get task time per line from design volumes
        return userInput?.designVolumes?.taskTimePerLine?.picking || 0;
      } catch (error) {
        // console.log('Error in pickLineTime.picking calculation:', error);
        return 0;
      }
    },
    description: "Gets task time per line from design volumes",
    calculation: "Direct value from designVolumes.taskTimePerLine.picking",
    dependencies: ["designVolumes.taskTimePerLine.picking"],
  },

  "pickLineTime.putaway": {
    compute: (userInput) => {
      try {
        // Get task time per line from design volumes
        return userInput?.designVolumes?.taskTimePerLine?.putaway || 0;
      } catch (error) {
        // console.log('Error in pickLineTime.putaway calculation:', error);
        return 0;
      }
    },
    description: "Gets task time per line from design volumes",
    calculation: "Direct value from designVolumes.taskTimePerLine.putaway",
    dependencies: ["designVolumes.taskTimePerLine.putaway"],
  },

  "pickLineTotalTime.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Multiply pick line time by quantity
        const pickTime =
          calculationMap["pickLineTime.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const pickQty =
          calculationMap["pickLineQty.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        return pickTime * pickQty;
      } catch (error) {
        // console.log('Error in pickLineTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description:
      "Calculates total pick line time by multiplying time per line by quantity",
    calculation: "pickLineTime × pickLineQty",
    dependencies: ["pickLineTime.picking", "pickLineQty.picking"],
  },

  "pickLineTotalTime.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Multiply pick line time by quantity
        const pickTime =
          calculationMap["pickLineTime.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const pickQty =
          calculationMap["pickLineQty.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        return pickTime * pickQty;
      } catch (error) {
        // console.log('Error in pickLineTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description:
      "Calculates total pick line time by multiplying time per line by quantity",
    calculation: "pickLineTime × pickLineQty",
    dependencies: ["pickLineTime.putaway", "pickLineQty.putaway"],
  },

  "repeatedAction.picking": {
    compute: (userInput, staticInputs) => {
      try {
        const batchBot = staticInputs?.batchBot || "FALSE";
        return batchBot === "TRUE" ? "Task List" : "Location";
      } catch (error) {
        return "Location";
      }
    },
    description:
      "Determines repeated action type based on batch bot setting for picking",
    calculation: "IF(batchBot=TRUE, 'Task List', 'Location')",
    dependencies: ["staticInputs.batchBot"],
  },

  "repeatedAction.putaway": {
    compute: (userInput, staticInputs) => {
      try {
        const batchBot = staticInputs?.batchBot || "FALSE";
        return batchBot === "TRUE" ? "Task List" : "Location";
      } catch (error) {
        return "Location";
      }
    },
    description:
      "Determines repeated action type based on batch bot setting for putaway",
    calculation: "IF(batchBot=TRUE, 'Task List', 'Location')",
    dependencies: ["staticInputs.batchBot"],
  },

  "repeatedActionSeconds.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const repeatedAction =
          calculationMap["repeatedAction.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || "Location";
        const pickLineTime =
          calculationMap["pickLineTime.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const linesPerStop =
          calculationMap["linesPerStop.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const activePickTime =
          calculationMap["activePickTime.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;

        return repeatedAction === "Location"
          ? pickLineTime * linesPerStop
          : activePickTime;
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates repeated action time in seconds for picking",
    calculation:
      "IF(repeatedAction='Location', pickLineTime * linesPerStop, activePickTime)",
    dependencies: [
      "repeatedAction.picking",
      "pickLineTime.picking",
      "linesPerStop.picking",
      "activePickTime.picking",
    ],
  },

  "repeatedActionSeconds.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const repeatedAction =
          calculationMap["repeatedAction.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || "Location";
        const pickLineTime =
          calculationMap["pickLineTime.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const linesPerStop =
          calculationMap["linesPerStop.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const activePickTime =
          calculationMap["activePickTime.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;

        return repeatedAction === "Location"
          ? pickLineTime * linesPerStop
          : activePickTime;
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates repeated action time in seconds for putaway",
    calculation:
      "IF(repeatedAction='Location', pickLineTime * linesPerStop, activePickTime)",
    dependencies: [
      "repeatedAction.putaway",
      "pickLineTime.putaway",
      "linesPerStop.putaway",
      "activePickTime.putaway",
    ],
  },

  "robot.picking": {
    compute: (userInput) => {
      try {
        // Get first word before space from robotOrigin
        return (userInput?.robotOrigin || "").split(" ")[0] || "";
      } catch (error) {
        // console.log('Error in robot.picking calculation:', error);
        return "";
      }
    },
    description: "Extracts the robot type from robotOrigin string",
    calculation: "First word of robotOrigin (before space)",
    dependencies: ["robotOrigin"],
  },

  "robot.putaway": {
    compute: (userInput) => {
      try {
        // Get first word before space from robotOrigin
        return (userInput?.robotOrigin || "").split(" ")[0] || "";
      } catch (error) {
        // console.log('Error in robot.putaway calculation:', error);
        return "";
      }
    },
    description: "Extracts the robot type from robotOrigin string",
    calculation: "First word of robotOrigin (before space)",
    dependencies: ["robotOrigin"],
  },

  "robotJobsPerHour.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Replace results access with direct compute call
        const missionTimeMin =
          calculationMap["averageMissionTimeMin.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 1;
        return safeDivide(60, missionTimeMin);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates number of jobs a robot can complete per hour",
    calculation: "jobsPerHour = 60 / averageMissionTimeMin",
    dependencies: ["averageMissionTimeMin.picking"],
  },

  "robotJobsPerHour.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Replace results access with direct compute call
        const missionTimeMin =
          calculationMap["averageMissionTimeMin.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 1;
        return safeDivide(60, missionTimeMin);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates number of jobs a robot can complete per hour",
    calculation: "jobsPerHour = 60 / averageMissionTimeMin",
    dependencies: ["averageMissionTimeMin.putaway"],
  },

  "robotSpeed.picking": {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        const isImperial = userInput?.metricOrImperial === "Imperial";
        const robotType = (userInput?.robotOrigin || "")
          .split(" ")[0]
          .toLowerCase()
          .replace(".", "_");
        const robotSpecs = lookupReference?.robotSpecs || {};

        // Lookup robot speed based on measurement system
        if (isImperial) {
          return robotSpecs.imperial?.[robotType]?.avgSpeed || 0;
        } else {
          return robotSpecs.metric?.[robotType]?.avgSpeed || 0;
        }
      } catch (error) {
        return 0;
      }
    },
    description:
      "Determines robot speed based on robot type and measurement system",
    calculation:
      "If Imperial: lookup robot speed from imperial specs table, else lookup from metric specs table",
    dependencies: [
      "metricOrImperial",
      "robotOrigin",
      "lookupReference.robotSpecs",
    ],
  },

  "robotSpeed.putaway": {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        const isImperial = userInput?.metricOrImperial === "Imperial";
        const robotType = (userInput?.robotOrigin || "")
          .split(" ")[0]
          .toLowerCase()
          .replace(".", "_");
        const robotSpecs = lookupReference?.robotSpecs || {};

        // Lookup robot speed based on measurement system
        if (isImperial) {
          return robotSpecs.imperial?.[robotType]?.avgSpeed || 0;
        } else {
          return robotSpecs.metric?.[robotType]?.avgSpeed || 0;
        }
      } catch (error) {
        // console.log('Error in robotSpeed.putaway calculation:', error);
        return 0;
      }
    },
    description:
      "Determines robot speed based on robot type and measurement system",
    calculation:
      "If Imperial: lookup robot speed from imperial specs table, else lookup from metric specs table",
    dependencies: [
      "metricOrImperial",
      "robotOrigin",
      "lookupReference.robotSpecs",
    ],
  },

  "timePerCycleSeconds.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const repeatedActionSeconds =
          calculationMap["repeatedActionSeconds.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const walkToNewBotSeconds =
          calculationMap["walkToNewBotSeconds.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;

        return repeatedActionSeconds + walkToNewBotSeconds;
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total time per cycle in seconds for picking",
    calculation: "repeatedActionSeconds + walkToNewBotSeconds",
    dependencies: [
      "repeatedActionSeconds.picking",
      "walkToNewBotSeconds.picking",
    ],
  },

  "timePerCycleSeconds.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const repeatedActionSeconds =
          calculationMap["repeatedActionSeconds.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const walkToNewBotSeconds =
          calculationMap["walkToNewBotSeconds.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;

        return repeatedActionSeconds + walkToNewBotSeconds;
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total time per cycle in seconds for putaway",
    calculation: "repeatedActionSeconds + walkToNewBotSeconds",
    dependencies: [
      "repeatedActionSeconds.putaway",
      "walkToNewBotSeconds.putaway",
    ],
  },

  "totalDesignFtes.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const directFtes =
          calculationMap["designDirectFtes.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const indirectFtes =
          calculationMap["designIndirectFtes.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        return directFtes + indirectFtes; // C160 =IFERROR(C162+C163,"")
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total number of FTEs needed for picking",
    calculation: "totalDesignFtes = designDirectFtes + designIndirectFtes",
    dependencies: ["designDirectFtes.picking", "designIndirectFtes.picking"],
  },

  "totalDesignFtes.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const directFtes =
          calculationMap["designDirectFtes.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const indirectFtes =
          calculationMap["designIndirectFtes.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        return directFtes + indirectFtes; // D160 =IFERROR(D162+D163,"")
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total number of FTEs needed for putaway",
    calculation: "totalDesignFtes = designDirectFtes + designIndirectFtes",
    dependencies: ["designDirectFtes.putaway", "designIndirectFtes.putaway"],
  },

  "totalJobsPerHourRequired.picking": {
    compute: (userInput) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.picking || 0;
        const linesPerMission =
          calculationMap["linesPerMission.picking"]?.compute(userInput) || 1;
        const productiveHours = userInput?.productiveHoursPerDayPicking || 16;

        return safeDivide(
          safeDivide(linesPerDay, linesPerMission),
          productiveHours,
        );
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total jobs per hour needed to meet demand",
    calculation: "(linesPerDay / linesPerMission) / productiveHoursPerDay",
    dependencies: [
      "designVolumes.linesPerDay.picking",
      "linesPerMission.picking",
      "productiveHoursPerDayPicking",
    ],
  },

  "totalJobsPerHourRequired.putaway": {
    compute: (userInput) => {
      try {
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.putaway || 0;
        const linesPerMission =
          calculationMap["linesPerMission.putaway"]?.compute(userInput) || 1;
        const productiveHours = userInput?.productiveHoursPerDayPutaway || 16;

        return safeDivide(
          safeDivide(linesPerDay, linesPerMission),
          productiveHours,
        );
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates total jobs per hour needed to meet demand",
    calculation: "(linesPerDay / linesPerMission) / productiveHoursPerDay",
    dependencies: [
      "designVolumes.linesPerDay.putaway",
      "linesPerMission.putaway",
      "productiveHoursPerDayPutaway",
    ],
  },


  "totalRobots.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const activeRobots =
          calculationMap["activeRobots.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const chargingRobots  =
          calculationMap["chargingRobots.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        return activeRobots + chargingRobots;
      } catch (error) {
        return 0;
      }
    },
    description:
      "Calculates total number of robots needed including charging buffer",
    calculation: "totalRobots = activeRobots + chargingRobots",
    dependencies: ["activeRobots.picking", "chargingRobots.picking"],
  },

"totalRobots.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const activeRobots = calculationMap["activeRobots.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      const chargingRobots = calculationMap["chargingRobots.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0;
      return activeRobots + chargingRobots;
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates total number of robots needed",
  calculation: "activeRobots + chargingRobots",
  dependencies: ["activeRobots.putaway", "chargingRobots.putaway"],
},

  "travelToDropoffDistance.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const locationEntry =
          calculationMap["inductDropoffLocationEntry.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || "";
        const adjustment =
          calculationMap["pickAreaToDropoffAdjustment.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const locationFactor =
          lookupReference?.distRef?.[locationEntry] || 0;
        return locationFactor + adjustment;
      } catch (error) {
        return 0;
      }
    },
    description:
      "Calculates travel distance to dropoff location using location factor and adjustment",
    calculation: "locationFactor × pickAreaToDropoffAdjustment",
    dependencies: [
      "inductDropoffLocationEntry.picking",
      "pickAreaToDropoffAdjustment.picking",
      "lookupReference.distRef",
    ],
  },

  "travelToDropoffDistance.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const locationEntry =
          calculationMap["inductDropoffLocationEntry.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || "";
        const adjustment =
          calculationMap["pickAreaToDropoffAdjustment.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const locationFactor =
          lookupReference?.distRef?.[locationEntry] || 0;

        return locationFactor + adjustment;
      } catch (error) {
        return 0;
      }
    },
    description:
      "Calculates travel distance to dropoff location using location factor and adjustment",
    calculation: "locationFactor × pickAreaToDropoffAdjustment",
    dependencies: [
      "inductDropoffLocationEntry.putaway",
      "pickAreaToDropoffAdjustment.putaway",
      "lookupReference.distRef",
    ],
  },

  "travelToDropoffTotalTime.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const distance =
          calculationMap["travelToDropoffDistance.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const robotSpeed =
          calculationMap["robotSpeed.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 1;
        return safeDivide(distance, robotSpeed);
      } catch (error) {
        return 0;
      }
    },
    description:
      "Calculates travel time to dropoff based on distance and robot speed",
    calculation: "travelToDropoffDistance / robotSpeed",
    dependencies: ["travelToDropoffDistance.picking", "robotSpeed.picking"],
  },

  "travelToDropoffTotalTime.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const distance =
          calculationMap["travelToDropoffDistance.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const robotSpeed =
          calculationMap["robotSpeed.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 1;
        return safeDivide(distance, robotSpeed);
      } catch (error) {
        return 0;
      }
    },
    description:
      "Calculates travel time to dropoff based on distance and robot speed",
    calculation: "travelToDropoffDistance / robotSpeed",
    dependencies: ["travelToDropoffDistance.putaway", "robotSpeed.putaway"],
  },

  "travelToFirstPickDistance.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const inductDropoffLocation =
          userInput?.inductDropoffLocationEntry || "";
        const inductToPickArea =
          calculationMap["inductToPickArea.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
          const distanceFactor = lookupReference?.distRef?.[inductDropoffLocation] || 0;
    
          // Note the + instead of * for putaway calculation
          return distanceFactor + inductToPickArea;
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates travel distance to first pick location",
    calculation: '=IFERROR(XLOOKUP(C106,B77:B80,C77:C80,"",0)*C107,"")',
    dependencies: [
      "inductDropoffLocationEntry.picking",
      "inductToPickArea.picking",
      "lookupReference.distRef",
    ],
  },

  "travelToFirstPickDistance.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      // D108 =IFERROR(XLOOKUP(D106,B77:B80,C77:C80,"",0)+D107,"")
      const inductDropoffLocation = userInput?.inductDropoffLocationEntry;
      const inductToPickArea = calculationMap['inductToPickArea.putaway']?.compute(
        userInput,
        staticInputs,
        lookupReference,
      ) || 0;

      const distanceFactor = lookupReference?.distRef?.[inductDropoffLocation] || 0;

      // Note the + instead of * for putaway calculation
      return distanceFactor + inductToPickArea;
    } catch (error) {
      console.error('Error in travelToFirstPickDistance.putaway:', error);
      return 0;
    }
  },
  description: "Calculate travel distance to first pick location for putaway",
  dependencies: ["inductDropoffLocationEntry.putaway", "inductToPickArea.putaway"]
},

  "travelToFirstPickTime.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // =IFERROR(C108/C101,"")
        const distance =
          calculationMap["travelToFirstPickDistance.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const robotSpeed =
          calculationMap["robotSpeed.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 1; // Avoid division by zero

        return safeDivide(distance, robotSpeed);
      } catch (error) {
        // console.log('Error in travelToFirstPickTime.picking calculation:', error);
        return 0;
      }
    },
    description: "Calculates travel time to first pick location",
    calculation: '=IFERROR(C108/C101,"")',
    dependencies: ["travelToFirstPickDistance.picking", "robotSpeed.picking"],
  },

  "travelToFirstPickTime.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const distance =
          calculationMap["travelToFirstPickDistance.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const robotSpeed =
          calculationMap["robotSpeed.putaway"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 1;

      return  safeDivide(distance, robotSpeed);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates travel time to first pick location",
    calculation: '=IFERROR(D108/D101,"")',
    dependencies: ["travelToFirstPickDistance.putaway", "robotSpeed.putaway"],
  },

  "travelToInductDistance.picking": {
    compute: (userInput, staticInputs) => {
      try {
        const isImperial = userInput?.metricOrImperial === "Imperial";
        const distance =
          userInput?.layoutDimensions?.travelToInductDistance || 0;
        return isImperial
          ? convertImperialToMetric(distance, "ft", "m")
          : distance;
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates travel distance to induct location",
    calculation: "Convert travel distance to metric if needed",
    dependencies: [
      "metricOrImperial",
      "layoutDimensions.travelToInductDistance",
    ],
  },

  "travelToInductDistance.putaway": {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        const inductLocation = userInput?.inductLocation?.putaway || {};
        const dropoffLocation = userInput?.dropoffLocation?.putaway || {};

        const distance = calculateDistance(
          inductLocation.x,
          inductLocation.y,
          dropoffLocation.x,
          dropoffLocation.y,
        );

        return distance;
      } catch (error) {
        return 0;
      }
    },
    description:
      "Calculates the distance from dropoff location to induct location",
    calculation: "Distance between dropoff and induct locations",
    dependencies: ["inductLocation.putaway", "dropoffLocation.putaway"],
  },

  "travelToInductTotalTime.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const distance =
          calculationMap["travelToInductDistance.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const robotSpeed =
          calculationMap["robotSpeed.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 1;
        return safeDivide(distance, robotSpeed);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates time to travel to induct location",
    calculation: "travelToInductDistance / robotSpeed",
    dependencies: ["travelToInductDistance.picking", "robotSpeed.picking"],
  },

  "travelToInductTotalTime.putaway": {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        const distance =
          getNestedValue(botCalculations, "travelToInductDistance.putaway") ||
          0;
        const speed =
          getNestedValue(botCalculations, "robotSpeed.putaway") || 0;
        return speed > 0 ? distance / speed : 0;
      } catch (error) {
        return 0;
      }
    },
    description:
      "Calculates total time to travel from dropoff to induct location",
    calculation: "Distance / Robot Speed (loaded)",
    dependencies: ["travelToInductDistance.putaway", "robotSpeed.loaded"],
  },

"travelToNextPickDistance.picking": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const numberOfCutThroughs = userInput?.numberOfCutThroughsInRobotArea || 0; // C17
      const areaLength = userInput?.totalLengthOfRobotArea || 0; // C13
      const areaWidth = userInput?.totalWidthOfRobotArea || 0; // C12
      const avgJobPercentageOfZone = calculationMap["avgJobPercentageOfZone.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // C117
      const linesPerMission = calculationMap["linesPerMission.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // C114
      const navigationFactor = calculationMap["navigationFactor.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // C118
      const avgTasksPerAisle = calculationMap["avgTasksPerAisle.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // C116

      const cutThroughEffect = lookupReference?.cutThroughEffectiveness?.[numberOfCutThroughs] || 0;

      // Following Excel formula exactly:
      // (MIN(XLOOKUP*areaLength, areaLength/avgTasksPerAisle) + (areaWidth*avgJobPercentageOfZone*(1/linesPerMission))) * navigationFactor
      const minPart = Math.min(
        safeMultiply(cutThroughEffect.percentOfAisle, areaLength),
        safeDivide(areaLength, avgTasksPerAisle)
      );
      const widthPart = safeMultiply(areaWidth, avgJobPercentageOfZone, safeDivide(1, linesPerMission));
      
      return safeMultiply(minPart + widthPart, navigationFactor);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates travel distance to next pick location",
  calculation: "(MIN(cutThroughEffect*areaLength, areaLength/avgTasksPerAisle) + (areaWidth*avgJobPercentageOfZone*(1/linesPerMission))) * navigationFactor",
  dependencies: [
    "numberOfCutThroughsInRobotArea",
    "totalLengthOfRobotArea",
    "totalWidthOfRobotArea",
    "avgJobPercentageOfZone.picking",
    "linesPerMission.picking",
    "navigationFactor.picking",
    "avgTasksPerAisle.picking",
    "lookupReference.cutThroughEffectiveness"
  ],
},

"travelToNextPickDistance.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      const numberOfCutThroughs = userInput?.numberOfCutThroughsInRobotArea || 0; // C17
      const areaLength = userInput?.totalLengthOfRobotArea || 0; // C13
      const areaWidth = userInput?.totalWidthOfRobotArea || 0; // C12
      const avgJobPercentageOfZone = calculationMap["avgJobPercentageOfZone.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // D117
      const linesPerMission = calculationMap["linesPerMission.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // D114
      const navigationFactor = calculationMap["navigationFactor.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // D118
      const avgTasksPerAisle = calculationMap["avgTasksPerAisle.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results
      ) || 0; // D116

      const cutThroughEffect = lookupReference?.cutThroughEffectiveness?.[numberOfCutThroughs] || 0;
      // Following Excel formula exactly:
      // (MIN(XLOOKUP*areaLength, areaLength/avgTasksPerAisle) + (areaWidth*avgJobPercentageOfZone*(1/linesPerMission))) * navigationFactor
      const minPart = Math.min(
        safeMultiply(cutThroughEffect?.percentOfAisle, areaLength),
        safeDivide(areaLength, avgTasksPerAisle)
      );
      const widthPart = safeMultiply(areaWidth, avgJobPercentageOfZone, safeDivide(1, linesPerMission));
      
    return  safeMultiply(minPart + widthPart, navigationFactor);
    } catch (error) {
      return 0;
    }
  },
  description: "Calculates travel distance to next pick location",
  calculation: "(MIN(cutThroughEffect*areaLength, areaLength/avgTasksPerAisle) + (areaWidth*avgJobPercentageOfZone*(1/linesPerMission))) * navigationFactor",
  dependencies: [
    "numberOfCutThroughsInRobotArea",
    "totalLengthOfRobotArea",
    "totalWidthOfRobotArea",
    "avgJobPercentageOfZone.putaway",
    "linesPerMission.putaway",
    "navigationFactor.putaway",
    "avgTasksPerAisle.putaway",
    "lookupReference.cutThroughEffectiveness"
  ],
},

  "travelToNextPickQty.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate quantity based on lines per mission and stop
        const linesPerMission =
          calculationMap["linesPerMission.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const linesPerStop =
          calculationMap["linesPerStop.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;

        if (linesPerStop === 0) return 0;
        return  (linesPerMission / linesPerStop - 1);
      } catch (error) {
        // console.log('Error in travelToNextPickQty.picking calculation:', error);
        return 0;
      }
    },
    description:
      "Calculates number of next picks based on lines per mission and stop",
    calculation: "(linesPerMission / linesPerStop) - 1",
    dependencies: ["linesPerMission.picking", "linesPerStop.picking"],
  },

  "travelToNextPickQty.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate quantity based on lines per mission and stop
        const linesPerMission =
          calculationMap["linesPerMission.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const linesPerStop =
          calculationMap["linesPerStop.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;

        if (linesPerStop === 0) return 0;
        return linesPerMission / linesPerStop - 1;
      } catch (error) {
        // console.log('Error in travelToNextPickQty.putaway calculation:', error);
        return 0;
      }
    },
    description:
      "Calculates number of next picks based on lines per mission and stop",
    calculation: "(linesPerMission / linesPerStop) - 1",
    dependencies: ["linesPerMission.putaway", "linesPerStop.putaway"],
  },

  "travelToNextPickTime.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate time based on distance and robot speed
        const distance =
          calculationMap["travelToNextPickDistance.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const robotSpeed =
          calculationMap["robotSpeed.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 1; // Avoid division by zero
        return safeDivide(distance, robotSpeed);
      } catch (error) {
        // console.log('Error in travelToNextPickTime.picking calculation:', error);
        return 0;
      }
    },
    description:
      "Calculates travel time to next pick based on distance and robot speed",
    calculation: "travelToNextPickDistance / robotSpeed",
    dependencies: ["travelToNextPickDistance.picking", "robotSpeed.picking"],
  },

  "travelToNextPickTime.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate time based on distance and robot speed
        const distance =
          calculationMap["travelToNextPickDistance.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const robotSpeed =
          calculationMap["robotSpeed.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 1; // Avoid division by zero
        return safeDivide(distance, robotSpeed);
      } catch (error) {
        // console.log('Error in travelToNextPickTime.putaway calculation:', error);
        return 0;
      }
    },
    description:
      "Calculates travel time to next pick based on distance and robot speed",
    calculation: "travelToNextPickDistance / robotSpeed",
    dependencies: ["travelToNextPickDistance.putaway", "robotSpeed.putaway"],
  },

  "travelToNextPickTotalTime.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate total time based on quantity and time per pick
        const qty =
          calculationMap["travelToNextPickQty.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const time =
          calculationMap["travelToNextPickTime.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        return safeMultiply(qty, time);
      } catch (error) {
        // console.log('Error in travelToNextPickTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description:
      "Calculates total travel time to next picks based on quantity and time per pick",
    calculation: "travelToNextPickQty × travelToNextPickTime",
    dependencies: [
      "travelToNextPickQty.picking",
      "travelToNextPickTime.picking",
    ],
  },

  "travelToNextPickTotalTime.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Calculate total time based on quantity and time per pick
        const qty =
          calculationMap["travelToNextPickQty.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const time =
          calculationMap["travelToNextPickTime.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        return safeMultiply(qty, time);
      } catch (error) {
        // console.log('Error in travelToNextPickTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description:
      "Calculates total travel time to next picks based on quantity and time per pick",
    calculation: "travelToNextPickQty × travelToNextPickTime",
    dependencies: [
      "travelToNextPickQty.putaway",
      "travelToNextPickTime.putaway",
    ],
  },

  "unitsPerLine.picking": {
    compute: (userInput) => {
      try {
        const unitsPerDay = userInput?.designVolumes?.unitsPerDay?.picking || 0;
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.picking || 0;
        return safeDivide(unitsPerDay, linesPerDay); // C157 =IFERROR(C34/C33,"")
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates average units per line for picking",
    calculation: "unitsPerDay / linesPerDay",
    dependencies: ["designVolumes.unitsPerDay.picking", "designVolumes.linesPerDay.picking"],
  },

  "unitsPerLine.putaway": {
    compute: (userInput) => {
      try {
        const unitsPerDay = userInput?.designVolumes?.unitsPerDay?.putaway || 0;
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.putaway || 0;
        return safeDivide(unitsPerDay, linesPerDay); // D157 =IFERROR(D34/D33,"")
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates average units per line for putaway",
    calculation: "unitsPerDay / linesPerDay",
    dependencies: ["designVolumes.unitsPerDay.putaway", "designVolumes.linesPerDay.putaway"],
  },

  "uph.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const lph = calculationMap["lph.picking"]?.compute(
          userInput,
          staticInputs,
          lookupReference,
          results
        ) || 0;
        const unitsPerLine = calculationMap["unitsPerLine.picking"]?.compute(
          userInput,
          staticInputs,
          lookupReference,
          results
        ) || 0;
        return safeMultiply(lph, unitsPerLine); // C158 =IFERROR(C156*C157,"")
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates units per hour for picking",
    calculation: "uph = lph × unitsPerLine",
    dependencies: ["lph.picking", "unitsPerLine.picking"],
  },

  "uph.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const lph = calculationMap["lph.putaway"]?.compute(
          userInput,
          staticInputs,
          lookupReference,
          results
        ) || 0;
        const unitsPerLine = calculationMap["unitsPerLine.putaway"]?.compute(
          userInput,
          staticInputs,
          lookupReference,
          results
        ) || 0;
        return safeMultiply(lph, unitsPerLine); // D158 =IFERROR(D156*D157,"")
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates units per hour for putaway",
    calculation: "uph = lph × unitsPerLine",
    dependencies: ["lph.putaway", "unitsPerLine.putaway"],
  },

  "waitForFteQty.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Instead of '=C102'
        return calculationMap["linesPerMission.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
      } catch (error) {
        // console.log('Error in waitForFteQty.picking calculation:', error);
        return 0;
      }
    },
    description: "Gets lines per mission as wait FTE quantity",
    calculation: "Uses results.linesPerMission.picking value",
    dependencies: ["linesPerMission.picking"],
  },

  "waitForFteQty.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Instead of '=D102'
        return  calculationMap["linesPerMission.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
      } catch (error) {
        // console.log('Error in waitForFteQty.putaway calculation:', error);
        return 0;
      }
    },
    description: "Gets lines per mission as wait FTE quantity",
    calculation: "Uses results.linesPerMission.putaway value",
    dependencies: ["linesPerMission.putaway"],
  },

  "waitForFteTime.picking": {
    compute: (userInput, staticInputs) => {
      try {
        // Instead of '=I12'
        return staticInputs?.waitTaskTime || 0;
      } catch (error) {
        // console.log('Error in waitForFteTime.picking calculation:', error);
        return 0;
      }
    },
    description: "Gets wait task time from static inputs",
    calculation: "Uses staticInputs.waitTaskTime value",
    dependencies: ["staticInputs.waitTaskTime"],
  },

  "waitForFteTime.putaway": {
    compute: (userInput, staticInputs) => {
      try {
        // Instead of '=I12'
        return staticInputs?.waitTaskTime || 0;
      } catch (error) {
        // console.log('Error in waitForFteTime.putaway calculation:', error);
        return 0;
      }
    },
    description: "Gets wait task time from static inputs",
    calculation: "Uses staticInputs.waitTaskTime value",
    dependencies: ["staticInputs.waitTaskTime"],
  },

  "waitForFteTotalTime.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Instead of '=IFERROR(C110*C111,"")'
        const waitTime =
          calculationMap["waitForFteTime.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
          ) || 0;
        const waitQty =
          calculationMap["waitForFteQty.picking"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        return safeMultiply(waitTime, waitQty);
      } catch (error) {
        // console.log('Error in waitForFteTotalTime.picking calculation:', error);
        return 0;
      }
    },
    description: "Calculates total wait time for FTE",
    calculation: "Multiplies waitForFteTime by waitForFteQty",
    dependencies: ["waitForFteTime.picking", "waitForFteQty.picking"],
  },

  "waitForFteTotalTime.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        // Instead of '=IFERROR(D110*D111,"")'
        const waitTime =
          calculationMap["waitForFteTime.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const waitQty =
          calculationMap["waitForFteQty.putaway"].compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        return safeMultiply(waitTime, waitQty);
      } catch (error) {
        // console.log('Error in waitForFteTotalTime.putaway calculation:', error);
        return 0;
      }
    },
    description: "Calculates total wait time for FTE",
    calculation: "Multiplies waitForFteTime by waitForFteQty",
    dependencies: ["waitForFteTime.putaway", "waitForFteQty.putaway"],
  },

  "walkToNewBotSeconds.picking": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const walkDistance =
          calculationMap["walkToNewRobotDistance.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const walkingSpeed = staticInputs?.averageWalkingSpeed || 0; // I23
        return safeDivide(walkDistance, walkingSpeed);
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates time to walk to new bot in seconds for picking",
    calculation: "walkToNewRobotDistance / averageWalkingSpeed",
    dependencies: [
      "walkToNewRobotDistance.picking",
      "staticInputs.averageWalkingSpeed",
    ],
  },

  "walkToNewBotSeconds.putaway": {
    compute: (userInput, staticInputs, lookupReference, results) => {
      try {
        const walkDistance =
          calculationMap["walkToNewRobotDistance.picking"]?.compute(
            userInput,
            staticInputs,
            lookupReference,
            results,
          ) || 0;
        const walkingSpeed = staticInputs?.averageWalkingSpeed || 0; // I23

        return walkingSpeed > 0 ? safeDivide(walkDistance, walkingSpeed) : 0;
      } catch (error) {
        return 0;
      }
    },
    description: "Calculates time to walk to new bot in seconds for putaway",
    calculation: "walkToNewRobotDistance / averageWalkingSpeed",
    dependencies: [
      "walkToNewRobotDistance.putaway",
      "staticInputs.averageWalkingSpeed",
    ],
  },

"walkToNewRobotDistance.picking": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      // C150 =IF(C143="",C95,E95)
      const totalRobots = calculationMap["totalRobots.putaway"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results,
      );
      const walkDistanceWithCluster = lookupReference?.walkDistance?.walkDistanceWithCluster || {};
      
      // If totalRobots is empty, use picking distance (C95), otherwise use concurrent distance (E95)
      return !totalRobots ? 
        walkDistanceWithCluster.picking || 0 : 
        walkDistanceWithCluster.concurrent || 0;
    } catch (error) {
      console.error('Error in walkToNewRobotDistance.picking:', error);
      return 0;
    }
  },
  description: "Determines walk distance to new robot for picking based on walkDistanceWithCluster",
  calculation: "IF(totalRobots='', walkDistanceWithCluster.picking, walkDistanceWithCluster.concurrent)",
  dependencies: ["totalRobots.picking"]
},

"walkToNewRobotDistance.putaway": {
  compute: (userInput, staticInputs, lookupReference, results) => {
    try {
      // D150 =IF(D143="",D95,E95)
      const totalRobots = calculationMap["totalRobots.picking"]?.compute(
        userInput,
        staticInputs,
        lookupReference,
        results,
      );
      const walkDistanceWithCluster = lookupReference?.walkDistance?.walkDistanceWithCluster || {};
      // If totalRobots is empty, use putaway distance (D95), otherwise use concurrent distance (E95)
      return !totalRobots ? 
        walkDistanceWithCluster.putaway || 0 : 
        walkDistanceWithCluster.concurrent || 0;
    } catch (error) {
      console.error('Error in walkToNewRobotDistance.putaway:', error);
      return 0;
    }
  },
  description: "Determines walk distance to new robot for putaway based on walkDistanceWithCluster",
  calculation: "IF(totalRobots='', walkDistanceWithCluster.putaway, walkDistanceWithCluster.concurrent)",
  dependencies: ["totalRobots.putaway"]
},
};

// Main calculation function with enhanced safety and dependency handling
export const calculateBotMetrics = (
  userInput,
  staticInputs,
  lookupReference,
) => {
  try {

    // Validate inputs
    if (!isValidObject(userInput)) {
      // console.log('Invalid userInput object');
      return {
        values: {},
        metadata: { error: "Invalid userInput object" },
      };
    }

    if (!isValidObject(staticInputs)) {
      // console.log('Invalid staticInputs object');
      return {
        values: {},
        metadata: { error: "Invalid staticInputs object" },
      };
    }

    // Initialize results with default values
    //
    const results = JSON.parse(JSON.stringify(initialState.selectedBotCalculator.botCalculations))
    let iterations = 0;
    const MAX_ITERATIONS = 10;
    let hasChanges = true;
    let lastError = null;

    // Define calculation order based on dependencies
    const calculationOrder = [
      // Basic robot and configuration inputs
      [
        "inductDropoffLocationEntry.picking",
        "inductDropoffLocationEntry.putaway",
      ],
      ["robot.picking", "robot.putaway"],
      ["batchBot.picking", "batchBot.putaway"],
      ["robotSpeed.picking", "robotSpeed.putaway"],
  
      // Volume and line calculations
      ["linesPerMission.picking", "linesPerMission.putaway"],
      ["linesPerStop.picking", "linesPerStop.putaway"],
      ["pickLineTime.picking", "pickLineTime.putaway"],
      ["pickLineQty.picking", "pickLineQty.putaway"],
      ["pickLineTotalTime.picking", "pickLineTotalTime.putaway"],

      // Location and navigation calculations
      ["inductToPickArea.picking", "inductToPickArea.putaway"],
      ["navigationFactor.picking", "navigationFactor.putaway"],

      // Task calculations
      ["avgTasksPerAisle.picking", "avgTasksPerAisle.putaway"],
      ["avgJobPercentageOfZone.picking", "avgJobPercentageOfZone.putaway"],

      // Distance calculations
      [
        "pickAreaToDropoffAdjustment.picking",
        "pickAreaToDropoffAdjustment.putaway",
      ],
      [
        "travelToFirstPickDistance.picking",
        "travelToFirstPickDistance.putaway",
      ],
      ["travelToNextPickDistance.picking", "travelToNextPickDistance.putaway"],
      ["travelToDropoffDistance.picking", "travelToDropoffDistance.putaway"],
      ["travelToInductDistance.picking", "travelToInductDistance.putaway"],

      // Time calculations - first level
      ["inductTotalTime.picking", "inductTotalTime.putaway"],
      ["autonomousLoadTime.picking", "autonomousLoadTime.putaway"],
      ["travelToFirstPickTime.picking", "travelToFirstPickTime.putaway"],
      ["travelToNextPickTime.picking", "travelToNextPickTime.putaway"],
      ["travelToDropoffTotalTime.picking", "travelToDropoffTotalTime.putaway"],
      ["travelToInductTotalTime.picking", "travelToInductTotalTime.putaway"],

      // Wait and queue time calculations
      ["waitForFteTime.picking", "waitForFteTime.putaway"],
      ["waitForFteQty.picking", "waitForFteQty.putaway"],
      ["waitForFteTotalTime.picking", "waitForFteTotalTime.putaway"],
      ["dropoffQueueTotalTime.picking", "dropoffQueueTotalTime.putaway"],
      ["inductQueueTotalTime.picking", "inductQueueTotalTime.putaway"],

      // Mission time calculations
      ["dropoffTotalTime.picking", "dropoffTotalTime.putaway"],
      ["autonomousUnloadTime.picking", "autonomousUnloadTime.putaway"],
      ["travelToNextPickQty.picking", "travelToNextPickQty.putaway"],
      [
        "travelToNextPickTotalTime.picking",
        "travelToNextPickTotalTime.putaway",
      ],

      // Active time and mission calculations
      ["activePickTime.picking", "activePickTime.putaway"],
      ["averageMissionTimeSec.picking", "averageMissionTimeSec.putaway"],
      ["averageMissionTimeMin.picking", "averageMissionTimeMin.putaway"],
      [
        "averageActivePickingPercentage.picking",
        "averageActivePickingPercentage.putaway",
      ],
      [
        "averageRobotMissionDistance.picking",
        "averageRobotMissionDistance.putaway",
      ],

      // Performance calculations
      ["robotJobsPerHour.picking", "robotJobsPerHour.putaway"],
      ["totalJobsPerHourRequired.picking", "totalJobsPerHourRequired.putaway"],
      ["averageAvailableTime.picking", "averageAvailableTime.putaway"],

      // Robot quantity calculations
      ["activeRobots.picking", "activeRobots.putaway"],
      ["chargingBuffer.picking", "chargingBuffer.putaway"],
      ["chargingRobots.picking", "chargingRobots.putaway"],
      ["totalRobots.picking", "totalRobots.putaway"],
      ["availableRobots.picking", "availableRobots.putaway"],

      // Throughput calculations
      ["activeRobotLphDesignVol.picking", "activeRobotLphDesignVol.putaway"],
      ["activeRobotUphDesignVol.picking", "activeRobotUphDesignVol.putaway"],
      ["allRobotLphDesignVol.picking", "allRobotLphDesignVol.putaway"],
      ["designLinesPerHour.picking", "designLinesPerHour.putaway"],

      // FTE calculations
      ["designDirectFtes.picking", "designDirectFtes.putaway"],
      ["designIndirectFtes.picking", "designIndirectFtes.putaway"],
      ["designInductOperators.picking", "designInductOperators.putaway"],
      ["designDropOffOperators.picking", "designDropOffOperators.putaway"],

      // Add lph calculations to the order
      ["lph.picking", "lph.putaway"],

      // Make sure these are in the right dependency order
      ["timePerCycleSeconds.picking", "timePerCycleSeconds.putaway"],
      ["linesPerCycle.picking", "linesPerCycle.putaway"],

      // Add new calculations in dependency order
      ["repeatedAction.picking", "repeatedAction.putaway"],
      ["repeatedActionSeconds.picking", "repeatedActionSeconds.putaway"],
      ["walkToNewBotSeconds.picking", "walkToNewBotSeconds.putaway"],
      ["timePerCycleSeconds.picking", "timePerCycleSeconds.putaway"],
      ["linesPerCycle.picking", "linesPerCycle.putaway"],
      ["lph.picking", "lph.putaway"],

      // Add walk distance and time calculations
      ["walkToNewRobotDistance.picking", "walkToNewRobotDistance.putaway"],
      ["walkToNewBotSeconds.picking", "walkToNewBotSeconds.putaway"],

      // These depend on the walk calculations
      ["timePerCycleSeconds.picking", "timePerCycleSeconds.putaway"],
      ["linesPerCycle.picking", "linesPerCycle.putaway"],
      ["repeatedAction.picking", "repeatedAction.putaway"],
      ["repeatedActionSeconds.picking", "repeatedActionSeconds.putaway"],
      ["lph.picking", "lph.putaway"],
      ["designVolumeUnitsPerHour.picking", "designVolumeUnitsPerHour.putaway"],
      [
        "designVolumeWithFactorUnitsPerHour.picking",
        "designVolumeWithFactorUnitsPerHour.putaway",
      ],
      ["designDirectFtes.picking", "designDirectFtes.putaway"],
      ["designIndirectFtes.picking", "designIndirectFtes.putaway"],
      ["totalDesignFtes.picking", "totalDesignFtes.putaway"],
      ["unitsPerLine.picking", "unitsPerLine.putaway"],
      ["uph.picking", "uph.putaway"],
      ["allRobotUphDesignVol.picking", "allRobotUphDesignVol.putaway"],
    ];

    // console.log('Starting calculation iterations');

    // Keep calculating until no changes or max iterations reached
    // todo check haschanges
    while (hasChanges && iterations < MAX_ITERATIONS) {
      hasChanges = false;
      iterations++;
      // console.log(`Iteration ${iterations}`);

      // Process calculations in dependency order
      for (const calculationGroup of calculationOrder) {
        for (const key of calculationGroup) {
          const calculator = calculationMap[key];
          if (
            !isValidObject(calculator) ||
            typeof calculator.compute !== "function"
          ) {
            // console.log(`Invalid calculator for ${key}`);
            continue;
          }

          const prevValue = getNestedValue(results, key);
          try {
            // console.log(`Computing ${key}`);
            const newValue = calculator.compute(
              userInput,
              staticInputs,
              lookupReference,
              results,
            );
            // console.log(`${key} result:`, newValue);

            // Validate the new value based on expected type
            let isValidValue = true;
            if (
              key.startsWith("robot.") ||
              key.startsWith("batchBot.") ||
              key.startsWith("repeatedAction.")
            ) {
              isValidValue = typeof newValue === "string";
            } else {
              isValidValue = isValidNumber(newValue);
            }

            if (!isValidValue) {
              // console.log(`Invalid value type for ${key}`);
              continue;
            }

            const currentValue = getNestedValue(results, key);
            if (JSON.stringify(currentValue) !== JSON.stringify(newValue)) {
              setNestedValue(results, key, newValue);
              hasChanges = true;
              // console.log(`Updated ${key} from`, currentValue, 'to', newValue);
            }
          } catch (error) {
            // console.log(`Error calculating ${key}:`, error);
            lastError = error;
            // Keep previous value on error
            setNestedValue(results, key, prevValue);
          }
        }
      }
    }

    // Todo remove later ideally
    results.inductDropoffLocations = {
      picking: userInput.inductDropoffLocationEntry || "",
      putaway: userInput.inductDropoffLocationEntry || ""
    };


    return {
      values: results,
      metadata: {
        iterations,
        calculationDescriptions: Object.fromEntries(
          Object.entries(calculationMap)
            .filter(([_, calc]) => isValidObject(calc))
            .map(([key, calc]) => [
              key,
              {
                description: calc.description || "",
                dependencies: Array.isArray(calc.dependencies)
                  ? calc.dependencies
                  : [],
                calculation: calc.calculation || "",
              },
            ]),
        ),
        warnings:
          iterations >= MAX_ITERATIONS ? ["Maximum iterations reached"] : [],
        error: lastError?.message,
      },
    };
  } catch (error) {
    // console.log('Error in calculateBotMetrics:', error);
    return {
      values: {},
      metadata: {
        iterations: 0,
        calculationDescriptions: {},
        error: error.message,
        warnings: ["Fatal error in calculation"],
      },
    };
  }
};
