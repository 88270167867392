import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusAnalyticsPreload = (width, height) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 400 305"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.8311 279.603C46.8366 279.603 43.5874 276.354 43.5874 272.36V117.108H18.478V296.664C18.478 301.11 22.0811 304.713 26.5264 304.713H339.88C344.325 304.713 347.929 301.11 347.929 296.664V279.603H50.8311Z"
        fill="#6A7F86"
      />
      <path
        d="M338.993 273V297.175C338.993 301.186 335.415 304.437 331 304.437H364.413C368.827 304.437 372.406 301.186 372.406 297.175V273H338.993Z"
        fill="#546975"
      />
      <path
        d="M364.185 66.991H334.603C332.94 100.129 305.459 126.576 271.916 126.576C238.373 126.576 210.892 100.129 209.229 66.991H146.36V113.818C146.36 116.925 143.833 119.452 140.727 119.452H42.7828V274.703C42.7828 279.148 46.3859 282.752 50.8312 282.752H364.185C368.63 282.752 372.233 279.149 372.233 274.703V75.0395C372.233 70.5942 368.63 66.991 364.185 66.991Z"
        fill="white"
      />
      <path
        d="M346.381 129.986L320.228 103.833C317.729 106.845 314.954 109.62 311.941 112.12L338.095 138.273L346.381 129.986Z"
        fill="#C8DBE8"
      />
      <path
        d="M234.817 114.397H205.131C201.894 114.397 199.272 117.021 199.272 120.257C199.272 123.493 201.894 126.116 205.131 126.116H264.446C253.451 124.806 243.322 120.65 234.817 114.397Z"
        fill="#AEE5F6"
      />
      <path
        d="M150.132 126H6.86856C3.07464 126 0 122.966 0 119.225V20.7755C0 17.033 3.07547 14 6.86856 14H150.131C153.925 14 157 17.0338 157 20.7755V119.225C157.001 122.966 153.925 126 150.132 126Z"
        fill="#546975"
      />
      <path
        d="M142.165 113.818V113.819C142.165 114.612 141.522 115.257 140.727 115.257H6.43828C5.64385 115.257 5 114.613 5 113.818V20.2684C5 19.4739 5.64384 18.8301 6.43828 18.8301H140.727C141.521 18.8301 142.165 19.4739 142.165 20.2684V113.818Z"
        fill="white"
        stroke="#6A7F86"
        strokeWidth="10"
      />
      <path
        d="M54 88V75.3775C54 72.96 51.9858 71 49.5 71C47.0142 71 45 72.96 45 75.3775V88H54Z"
        fill="#27AAE1"
      />
      <path
        d="M73 88V52.5054C73 50.0172 70.9858 48 68.5 48C66.0142 48 64 50.0172 64 52.5054V88H73Z"
        fill="#0078CF"
      />
      <path
        d="M92 88V65.5907C92 63.0554 89.9858 61 87.5 61C85.0142 61 83 63.0554 83 65.5907V88H92Z"
        fill="#27AAE1"
      />
      <path
        d="M111 88V52.5054C111 50.0172 108.986 48 106.5 48C104.014 48 102 50.0172 102 52.5054V88H111Z"
        fill="#0078CF"
      />
      <path
        d="M124.002 198.752H179.234C179.238 198.491 179.254 198.233 179.254 197.971C179.254 167.887 154.866 143.5 124.783 143.5C124.521 143.5 124.262 143.516 124.002 143.519V198.752Z"
        fill="#0078CF"
      />
      <path
        d="M70.3125 197.971C70.3125 228.054 94.7 252.442 124.784 252.442C154.867 252.442 179.255 228.054 179.255 197.971H124.784V143.5C94.7 143.5 70.3125 167.887 70.3125 197.971Z"
        fill="#27AAE1"
      />
      <ellipse cx="125" cy="197.406" rx="27.3437" ry="27.3437" fill="white" />
      <path
        d="M333.087 258.108H199.485C196.248 258.108 193.626 255.485 193.626 252.249C193.626 249.013 196.248 246.389 199.485 246.389H333.087C336.323 246.389 338.946 249.013 338.946 252.249C338.946 255.485 336.323 258.108 333.087 258.108Z"
        fill="#AEE5F6"
      />
      <path
        d="M282.527 208.695L258.331 184.498C257.336 188.578 254.124 191.789 250.045 192.785L274.242 216.982C275.236 212.902 278.448 209.69 282.527 208.695Z"
        fill="#C8DBE8"
      />
      <path
        d="M236.527 184.938L215.43 207.797H199.485C196.248 207.797 193.626 210.421 193.626 213.657C193.626 216.893 196.248 219.516 199.485 219.516H217.996C219.632 219.516 221.193 218.832 222.302 217.63L245.133 192.893C240.996 192.057 237.68 188.951 236.527 184.938Z"
        fill="#C8DBE8"
      />
      <path
        d="M312.067 184.498L287.871 208.694C291.951 209.689 295.162 212.901 296.158 216.981L320.355 192.785C316.274 191.789 313.063 188.578 312.067 184.498Z"
        fill="#C8DBE8"
      />
      <path
        d="M247.372 193.899C254.039 193.899 259.445 188.494 259.445 181.826C259.445 175.159 254.039 169.753 247.372 169.753C240.704 169.753 235.299 175.159 235.299 181.826C235.299 188.494 240.704 193.899 247.372 193.899Z"
        fill="#27AAE1"
      />
      <path
        d="M285.199 231.725C291.867 231.725 297.272 226.32 297.272 219.653C297.272 212.985 291.867 207.58 285.199 207.58C278.532 207.58 273.127 212.985 273.127 219.653C273.127 226.32 278.532 231.725 285.199 231.725Z"
        fill="#27AAE1"
      />
      <path
        d="M323.026 193.899C329.694 193.899 335.099 188.494 335.099 181.826C335.099 175.159 329.694 169.753 323.026 169.753C316.359 169.753 310.954 175.159 310.954 181.826C310.954 188.494 316.359 193.899 323.026 193.899Z"
        fill="#27AAE1"
      />
      <path
        d="M323.027 169.753C321.52 169.753 320.083 170.036 318.755 170.543C323.315 172.268 326.563 176.662 326.563 181.826C326.563 186.99 323.314 191.384 318.755 193.109C320.083 193.615 321.52 193.899 323.027 193.899C329.694 193.899 335.099 188.494 335.099 181.826C335.098 175.159 329.694 169.753 323.027 169.753Z"
        fill="#219ACC"
      />
      <path
        d="M247.373 169.753C245.866 169.753 244.429 170.036 243.101 170.543C247.661 172.268 250.908 176.662 250.908 181.826C250.908 186.99 247.66 191.384 243.101 193.109C244.429 193.615 245.866 193.899 247.373 193.899C254.04 193.899 259.445 188.494 259.445 181.826C259.444 175.159 254.04 169.753 247.373 169.753Z"
        fill="#219ACC"
      />
      <path
        d="M285.199 207.581C283.693 207.581 282.255 207.864 280.927 208.371C285.487 210.096 288.735 214.489 288.735 219.653C288.735 224.818 285.487 229.211 280.927 230.936C282.255 231.443 283.693 231.726 285.199 231.726C291.866 231.726 297.272 226.321 297.272 219.653C297.272 212.985 291.866 207.581 285.199 207.581Z"
        fill="#219ACC"
      />
      <path
        d="M226.665 156.7H205.132C201.895 156.7 199.272 154.076 199.272 150.84C199.272 147.604 201.895 144.981 205.132 144.981H226.665C229.901 144.981 232.524 147.604 232.524 150.84C232.524 154.076 229.901 156.7 226.665 156.7Z"
        fill="#AEE5F6"
      />
      <path
        d="M315.38 156.7H248.197C244.961 156.7 242.338 154.076 242.338 150.84C242.338 147.604 244.961 144.981 248.197 144.981H315.38C318.617 144.981 321.24 147.604 321.24 150.84C321.24 154.076 318.616 156.7 315.38 156.7Z"
        fill="#AEE5F6"
      />
      <path
        d="M316.805 108.755C341.618 83.9413 341.618 43.7108 316.805 18.8973C291.991 -5.91613 251.761 -5.91614 226.947 18.8973C202.134 43.7108 202.134 83.9413 226.947 108.755C251.761 133.568 291.991 133.568 316.805 108.755Z"
        fill="#0078CF"
      />
      <path
        d="M271.916 106.453C295.468 106.453 314.561 87.3601 314.561 63.8082C314.561 40.2562 295.468 21.1636 271.916 21.1636C248.364 21.1636 229.272 40.2562 229.272 63.8082C229.272 87.3601 248.364 106.453 271.916 106.453Z"
        fill="#EAF6FF"
      />
      <path
        d="M271.875 119.281C241.29 119.281 216.406 94.3981 216.406 63.8123C216.406 33.2266 241.29 8.3436 271.875 8.3436C302.46 8.3436 327.344 33.2266 327.344 63.8123C327.344 94.3981 302.46 119.281 271.875 119.281ZM271.875 21.7451C248.679 21.7451 229.808 40.6162 229.808 63.8123C229.808 87.0085 248.679 105.88 271.875 105.88C295.071 105.88 313.942 87.0085 313.942 63.8123C313.942 40.6162 295.071 21.7451 271.875 21.7451Z"
        fill="#27AAE1"
      />
      <path
        d="M396.081 187.973C390.856 193.198 382.386 193.198 377.161 187.973L335.181 145.993C333.548 144.36 333.548 141.714 335.181 140.081L348.189 127.073C349.822 125.44 352.469 125.44 354.102 127.073L396.081 169.053C401.306 174.278 401.306 182.748 396.081 187.973Z"
        fill="#6A7F86"
      />
      <path
        d="M340.496 151.308L348.189 143.615C349.822 141.982 352.469 141.982 354.102 143.615L396.081 185.595C396.46 185.974 396.803 186.374 397.127 186.784C401.27 181.533 400.927 173.899 396.081 169.053L354.102 127.073C352.469 125.44 349.822 125.44 348.189 127.073L335.181 140.081C333.548 141.714 333.548 144.36 335.181 145.993L340.496 151.308Z"
        fill="#546975"
      />
      <path
        d="M266.801 84.2652C265.301 84.2652 263.801 83.6933 262.657 82.5488L245.755 65.6473C243.467 63.359 243.467 59.6488 245.755 57.3613C248.044 55.0731 251.754 55.0731 254.042 57.3613L266.8 70.1199L289.215 47.7035C291.505 45.4152 295.214 45.4152 297.502 47.7035C299.79 49.9918 299.79 53.7019 297.502 55.9894L270.943 82.5488C269.8 83.6926 268.3 84.2652 266.801 84.2652Z"
        fill="#0078CF"
      />
      <path
        d="M115.396 97H32.6044C30.0609 97 28 94.938 28 92.3946V41.6054C28 39.062 30.0609 37 32.6044 37C35.1479 37 37.2088 39.062 37.2088 41.6054V87.7893H115.396C117.939 87.7893 120 89.8512 120 92.3946C120 94.938 117.939 97 115.396 97Z"
        fill="#C8DBE8"
      />
    </svg>
  );
};
