import AuthGuard from "../utils/route-guard/AuthGuard";
import { MainLayout } from "../layout/MainLayout/MainLayout";
import { ProtectedElement } from "./ProtectedRoute";
import { Loadable } from "../components/Loadable/Loadable";
import { lazy } from "react";
import {
  CONFIGURATION_ADMIN,
  LOCUS_ASSET_MANAGER,
  LOCUS_CONFIGURATION_ADMIN,
  LOCUS_TENANT,
  SUPER_ADMIN,
} from "../utils/constants/user";
import { AssetManagementRoutes } from "./ChildRoutes/AssetManagementRoutes";
import { PermissionsRoutes } from "./ChildRoutes/PermissionsRoutes";
import { UsersRoutes } from "./ChildRoutes/UsersRoutes";
import { ToolsRoutes } from "./ChildRoutes/ToolsRoutes";
import { HelpRoutes } from "./ChildRoutes/HelpRoutes";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import componentLoader from "../utils/helpers/componentLoader";

const Home = Loadable(
  lazy(() => componentLoader(() => import("views/home/LocusHome"))),
);
const ClientList = Loadable(
  lazy(() => componentLoader(() => import("views/client/ClientList"))),
);
const ClientCreate = Loadable(
  lazy(() => componentLoader(() => import("views/client/ClientCreate"))),
);
const DashboardList = Loadable(
  lazy(() => componentLoader(() => import("views/dashboard/DashboardList"))),
);
const DashboardCreate = Loadable(
  lazy(() => componentLoader(() => import("views/dashboard/DashboardCreate"))),
);
const LocusRobotAssets = Loadable(
  lazy(() => componentLoader(() => import("views/analytics/LocusRobotAssets"))),
);

export const LocusRoutes = {
  path: "/locus",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      breadcrumb: {
        display: <FormattedMessage id="global-overview" />,
      },
      element: (
        <ProtectedElement element={Home} allowedGroups={[LOCUS_TENANT]} />
      ),
    },
    {
      path: "clients",
      breadcrumb: {
        display: <FormattedMessage id="clients" />,
      },
      children: [
        {
          path: "",
          element: (
            <ProtectedElement
              element={ClientList}
              allowedGroups={[LOCUS_TENANT]}
            />
          ),
        },
        {
          path: "create",
          breadcrumb: {
            // display: <FormattedMessage id="create" />,
            display: "Add New Client",
          },
          element: (
            <ProtectedElement
              element={ClientCreate}
              allowedGroups={[
                SUPER_ADMIN,
                LOCUS_CONFIGURATION_ADMIN,
                CONFIGURATION_ADMIN,
              ]}
            />
          ),
        },
      ],
    },
    {
      path: "analytics-manager",
      breadcrumb: {
        display: <FormattedMessage id="dashboards" />,
      },
      children: [
        {
          path: "",
          element: (
            <ProtectedElement element={DashboardList} allowedGroups={[]} />
          ),
        },
        {
          path: "create",
          element: (
            <ProtectedElement element={DashboardCreate} allowedGroups={[]} />
          ),
          breadcrumb: {
            display: "Add New Dashboard",
          },
        },
      ],
    },
    {
      path: "reports",
      breadcrumb: {
        display: <FormattedMessage id="reports" />,
      },
      children: [
        {
          path: "robot-assets",
          breadcrumb: {
            display: <FormattedMessage id="bot-assets" />,
          },
          element: (
            <ProtectedElement
              element={LocusRobotAssets}
              allowedGroups={[LOCUS_ASSET_MANAGER]}
            />
          ),
        },
      ],
    },
    PermissionsRoutes,
    AssetManagementRoutes,
    UsersRoutes,
    ToolsRoutes,
    HelpRoutes,
  ],
};
