import React, { createContext, useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const RootContext = createContext();

function RootProvider({ children }) {
    const [client, setClient] = useState(null);
    const [warehouse, setWarehouse] = useState(null);
    const [message, setMessage] = useState({});
    const [locusWarehouse, setLocusWarehouse] = useState(null)

    const [floor, setFloor] = useState("floor_1");
    const [floorsInfo, setFloorsInfo] = useState([])
    const [floorSubmitLoading, setFloorSubmitLoading] = useState(false)
    const [requestId, setRequestId] = useState(null);
    const [activeWarehouseFiles, setActiveWarehouseFiles] = useState({});
    const [warehouseSASForUpload, setWarehouseSASForUpload] = useState({});
    const [submitDisabled, setSubmitDisabled] = useState({});
    const [expanded, setExpanded] = useState(1);
    const [floorSubmitIndex, setFloorSubmitIndex] = useState(0);
    const [fileIdDeleteSubmit, setFileIdDeleteSubmit] = useState("");

    const contextValue = {
        client, setClient,
        warehouse, setWarehouse,
        message, setMessage,
        locusWarehouse, setLocusWarehouse,
        floor, setFloor,
        floorsInfo, setFloorsInfo,
        floorSubmitLoading, setFloorSubmitLoading,
        requestId, setRequestId,
        activeWarehouseFiles, setActiveWarehouseFiles,
        warehouseSASForUpload, setWarehouseSASForUpload,
        submitDisabled, setSubmitDisabled,
        expanded, setExpanded,
        floorSubmitIndex, setFloorSubmitIndex,
        fileIdDeleteSubmit, setFileIdDeleteSubmit
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setMessage({});
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <RootContext.Provider value={contextValue}>
            {message?.text && (
                <Snackbar
                    open={!!message?.text}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={message.type}
                        sx={{ width: "100%" }}
                    >
                        {message.text}
                    </Alert>
                </Snackbar>
            )}
            {children}
        </RootContext.Provider>
    );
}

export default RootProvider;

export const useRoot = () => {
    return useContext(RootContext);
};
