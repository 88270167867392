import { createTheme } from "@mui/material/styles";
import defaultColor from "assets/scss/_themes-vars.module.scss";

// Create light theme configuration - exact copy of original light theme values
const createLightPaletteConfig = (colors) => ({
  mode: 'light',
  common: {
    black: colors.darkPaper,
  },
  primary: {
    light: colors.primaryLight,
    main: colors.primaryMain,
    dark: colors.primaryDark,
    200: colors.primary200,
    800: colors.primary800,
  },
  secondary: {
    light: colors.secondaryLight,
    main: colors.secondaryMain,
    dark: colors.secondaryDark,
    200: colors.secondary200,
    800: colors.secondary800,
  },
  error: {
    light: colors.errorLight,
    200: colors.errorLight,
    main: colors.errorMain,
    dark: colors.errorDark,
  },
  orange: {
    light: colors.orangeLight,
    main: colors.orangeMain,
    dark: colors.orangeDark,
  },
  warning: {
    light: colors.warningLight,
    200: colors.success200,
    main: colors.warningMain,
    dark: colors.warningDark,
  },
  success: {
    light: colors.successLight,
    200: colors.success200,
    main: colors.successMain,
    dark: colors.successDark,
  },
  grey: {
    50: colors.grey50,
    100: colors.grey100,
    200: colors.grey200,
    300: colors.grey300,
    500: colors.grey500,
    600: colors.grey900,
    700: colors.grey700,
    900: colors.grey900,
  },
  dark: {
    light: colors.darkTextPrimary,
    main: colors.darkLevel1,
    dark: colors.darkLevel2,
    800: colors.darkBackground,
    900: colors.darkPaper,
  },
  text: {
    primary: colors.grey700,
    secondary: colors.grey500,
    dark: colors.grey900,
    hint: colors.grey100,
  },
  divider: colors.grey200,
  background: {
    paper: colors.paper,
    default: colors.paper,
  },
  powerbi: {
    background: colors.paper,
    backgroundAlt: colors.grey50,
    backgroundDarker: colors.grey900,
    text: colors.grey700,
    textSecondary: colors.grey500,
    textMuted: colors.grey300,
  },
});

// Create dark theme configuration - exact copy of original dark theme values
const createDarkPaletteConfig = (colors) => ({
  mode: 'dark',
  common: {
    black: colors.darkPaper,
  },
  primary: {
    light: colors.darkPrimaryLight,
    main: colors.darkPrimaryMain,
    dark: colors.darkPrimaryDark,
    200: colors.darkPrimary200,
    800: colors.darkPrimary800,
  },
  secondary: {
    light: colors.darkSecondaryLight,
    main: colors.darkSecondaryMain,
    dark: colors.darkSecondaryDark,
    200: colors.darkSecondary200,
    800: colors.darkSecondary800,
  },
  error: {
    light: colors.darkErrorText,
    200: colors.darkErrorStroke,
    main: colors.darkErrorMain,
    dark: colors.darkErrorBackground,
  },
  orange: {
    light: colors.orangeLight,
    main: colors.orangeMain,
    dark: colors.orangeDark,
  },
  warning: {
    light: colors.darkWarningText,
    200: colors.darkWarningStroke,
    main: colors.darkWarningMain,
    dark: colors.darkWarningBackground,
  },
  success: {
    light: colors.darkSuccessText,
    200: colors.darkSuccessStroke,
    main: colors.darkSuccessMain,
    dark: colors.darkSuccessBackground,
  },
  grey: {
    50: colors.darkGrey800,
    100: colors.darkGrey100,
    200: colors.darkGrey200,
    300: colors.darkGrey300,
    500: colors.darkGrey500,
    600: colors.darkGrey600,
    700: colors.darkGrey700,
    900: colors.darkGrey800,
  },
  dark: {
    light: colors.darkTextPrimary,
    main: colors.darkLevel1,
    dark: colors.darkLevel2,
    800: colors.darkBackground,
    900: colors.darkPaper,
  },
  text: {
    primary: colors.darkGrey700,
    secondary: colors.darkGrey500,
    dark: colors.darkGrey800,
    hint: colors.darkGrey300,
  },
  divider: colors.darkGrey200,
  background: {
    paper: colors.darkPaper,
    default: colors.darkPaper,
  },
  powerbi: {
    background: colors.darkBackground,
    backgroundAlt: colors.darkPaper,
    backgroundDarker: colors.darkLevel2,
    text: colors.darkTextPrimary,
    textSecondary: colors.darkTextSecondary,
    textMuted: colors.darkTextMuted,
  },
});

// Create light theme
export const createLightTheme = (presetColor = "default") => {
  let colors;
  switch (presetColor) {
    case "default":
    default:
      colors = defaultColor;
  }
  return createTheme({
    palette: createLightPaletteConfig(colors),
  });
};

// Create dark theme
export const createDarkTheme = (presetColor = "default") => {
  let colors;
  switch (presetColor) {
    case "default":
    default:
      colors = defaultColor;
  }
  return createTheme({
    palette: createDarkPaletteConfig(colors),
  });
};

// For backward compatibility - exact same function as before
const Palette = (navType, presetColor) => {
  return navType === "dark" ? createDarkTheme(presetColor) : createLightTheme(presetColor);
};

export default Palette;
