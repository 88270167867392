// Type checking helper functions
const isValidNumber = (value) => {
  return typeof value === "number" && !isNaN(value) && isFinite(value);
};

const isValidString = (value) => {
  return typeof value === "string" && value.length > 0;
};

// Safe math helper functions
const safeDivide = (numerator, denominator, defaultValue = 0) => {
  try {
    if (!isValidNumber(numerator) || !isValidNumber(denominator))
      return defaultValue;
    if (denominator === 0) return defaultValue;
    return numerator / denominator;
  } catch (error) {
    console.log("Error in division:", error);
    return defaultValue;
  }
};

const safeMultiply = (...factors) => {
  try {
    if (factors.some((f) => !isValidNumber(f))) return 0;
    return factors.reduce((acc, val) => acc * val, 1);
  } catch (error) {
    console.log("Error in multiplication:", error);
    return 0;
  }
};

// Safe lookup helper function
const safeGet = (obj, path, defaultValue = null) => {
  try {
    if (!obj) return defaultValue;
    const value = path.split('.').reduce((o, i) => o?.[i], obj);
    return value === undefined || value === null ? defaultValue : value;
  } catch (error) {
    return defaultValue;
  }
};

// Helper functions for safe operations
const safeAdd = (...args) => {
  try {
    return args.reduce((sum, value) => {
      if (!isValidNumber(value)) return sum;
      return sum + value;
    }, 0);
  } catch (error) {
    return 0;
  }
};

// Maps lookup IDs to their values and descriptions
export const lookupMap = {
  // Navigation Factors
  avgTasksPerAisle: {
    compute: (userInput, staticInputs) => {
      try {
        const aisleLength = safeGet(userInput, "totalLengthOfRobotArea", 0);
        const avgTaskSpacing = safeGet(staticInputs, "averageTaskSpacing", 1);
        return Math.floor(safeDivide(aisleLength, avgTaskSpacing));
      } catch (error) {
        console.log("Error computing avgTasksPerAisle:", error);
        return 0;
      }
    },
    description: "Average number of tasks that can be performed in one aisle",
    type: "computed",
    dependencies: ["totalLengthOfRobotArea", "averageTaskSpacing"],
  },

  // Robot Specifications
  batchBotImperial: {
    value: {
      Origin: "TRUE",
      Locus: "FALSE",
      HAI: "FALSE",
    },
    description: "Batch bot compatibility for robots in imperial units",
    type: "static",
    validate: (value) => (isValidString(value) ? value : "FALSE"),
  },

  // Cut-through Effectiveness
  batchBotMetric: {
    value: {
      Origin: "TRUE",
      Locus: "FALSE",
      HAI: "FALSE",
    },
    description: "Batch bot compatibility for robots in metric units",
    type: "static",
    validate: (value) => (isValidString(value) ? value : "FALSE"),
  },

  // Distance References
  cutThroughEffectiveness: {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        // Get values from state
        const cutThroughEffectivenessArray = safeGet(lookupReference, "cutThroughEffectiveness", []);
        const aisleLength = safeGet(userInput, "totalLengthOfRobotArea", 0); // C13
        const aisleWidth = safeGet(userInput, "totalWidthOfRobotArea", 0);   // C12
        const numCutThroughs = safeGet(userInput, "numberOfCutThroughsInRobotArea", 0); // C17

        // Map through the array and calculate endCap values
        return cutThroughEffectivenessArray.map(item => {
          const cutThroughs = safeGet(item, "cutThroughs", 0);
          const percentOfAisle = safeGet(item, "percentOfAisle", 0);

          // Calculate endCap based on number of cutThroughs per Excel formulas
          let endCap = 0;
          if (cutThroughs === 0) {
            // D67: =((C13/(B67+1))/2)+((C13/(B67+1))/2)+(C12*0.5)
            endCap = safeDivide(safeDivide(aisleLength, cutThroughs + 1), 2) + 
                     safeDivide(safeDivide(aisleLength, cutThroughs + 1), 2) + 
                     safeMultiply(aisleWidth, 0.5);
          } else if (cutThroughs === 1) {
            // D68: =((C13/(B68+1))/2)+(C12*0.5)
            endCap = safeDivide(safeDivide(aisleLength, cutThroughs + 1), 2) + safeMultiply(aisleWidth, 0.5);
          } else if (cutThroughs === 2) {
            if(percentOfAisle === null) {
              // D74: =AVERAGE(XLOOKUP(ROUNDDOWN(C17,0),B67:B73,D67:D73),XLOOKUP(ROUNDUP(C17,0),B67:B73,D67:D73))
              const roundedDown = Math.floor(numCutThroughs);
              const roundedUp = Math.ceil(numCutThroughs);
              
              // Find endCap values for rounded down and up
              const roundedDownValue = cutThroughEffectivenessArray
                .filter(v => v.percentOfAisle !== null)
                .find(v => v.cutThroughs === roundedDown)?.endCap || 0;
                
              const roundedUpValue = cutThroughEffectivenessArray
                .filter(v => v.percentOfAisle !== null)
                .find(v => v.cutThroughs === roundedUp)?.endCap || 0;
                
              // Calculate average
              endCap = (roundedDownValue + roundedUpValue) / 2;
            } else {
              // D69: =((C13/(B69+1))/2)+((C13/(B69+1))/2)+(C12*0.5)
              endCap = safeDivide(safeDivide(aisleLength, cutThroughs + 1), 2) + 
                       safeDivide(safeDivide(aisleLength, cutThroughs + 1), 2) + 
                       safeMultiply(aisleWidth, 0.5);
            }
          } else if (cutThroughs === 3) {
            // D70: =(C13*0.25)+(C12*0.5)
            endCap = safeMultiply(aisleLength, 0.25) + safeMultiply(aisleWidth, 0.5);
          } else if (cutThroughs === 4) {
            // D71: =(C13*0.1)+(C13*0.18)+(C12*0.5)
            endCap = safeMultiply(aisleLength, 0.1) + safeMultiply(aisleLength, 0.18) + safeMultiply(aisleWidth, 0.5);
          } else if (cutThroughs === 5) {
            // D72: =(C13*0.25)+(C12*0.5)
            endCap = safeMultiply(aisleLength, 0.25) + safeMultiply(aisleWidth, 0.5);
          } else if (cutThroughs === 6) {
            // D73: =(C13*0.071)+(C13*0.1939)+(C12*0.5)
            endCap = safeMultiply(aisleLength, 0.071) + safeMultiply(aisleLength, 0.1939) + safeMultiply(aisleWidth, 0.5);
          }

          return {
            cutThroughs,
            percentOfAisle,
            endCap
          };
        });
      } catch (error) {
        console.log("Error computing cutThroughEffectiveness:", error);
        return [];
      }
    },
    description: "Effectiveness of cut-throughs in reducing travel distance",
    type: "computed",
    dependencies: [
      "totalLengthOfRobotArea",
      "totalWidthOfRobotArea", 
      "numberOfCutThroughsInRobotArea"
    ]
  },

  // Batch Bot Lookup Tables
  distRef: {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        const aisleLength = safeGet(userInput, "totalLengthOfRobotArea", 0); // C13
        const aisleWidth = safeGet(userInput, "totalWidthOfRobotArea", 0);   // C12
        const numCutThroughs = safeGet(userInput, "numberOfCutThroughsInRobotArea", 0); // C17

        // Compute cutThroughEffectiveness first
        const cutThroughEffectiveness = lookupReference["cutThroughEffectiveness"]
        
        return {
          // C77: =IFERROR((0.5*C12)+(0.5*C13),"")
          cornerOfPickSpace: safeMultiply(0.5, aisleWidth) + safeMultiply(0.5, aisleLength),
          
          // C78: =IFERROR((0.5*C13)+(0.25*C12),"")
          centerOfSpaceAlongEndCaps: safeMultiply(0.5, aisleLength) + safeMultiply(0.25, aisleWidth),
          
          // C79: =IFERROR(XLOOKUP(IF(C17>6,6,C17),B67:B74,D67:D74),"")
          centerOfSpaceAlongAislePickFaces: cutThroughEffectiveness?.find(v => 
            v.cutThroughs === (numCutThroughs > 6 ? 6 : numCutThroughs))?.endCap || 0,
          
          // C80: =IFERROR((0.25*C13)+(0.25*C12),"")
          centerOfPickSpace: safeMultiply(0.25, aisleLength) + safeMultiply(0.25, aisleWidth)
        };
      } catch (error) {
        console.log("Error computing distRef:", error);
        return {
          cornerOfPickSpace: 0,
          centerOfSpaceAlongEndCaps: 0,
          centerOfSpaceAlongAislePickFaces: 0,
          centerOfPickSpace: 0
        };
      }
    },
    description: "Reference distances from various points in the picking space",
    type: "computed",
    dependencies: ["totalLengthOfRobotArea", "totalWidthOfRobotArea", "numberOfCutThroughs", "cutThroughEffectiveness"]
  },

  fteEfficiencyFactors: {
    value: {
      standard: 1.0,
      training: 0.7,
      peak: 1.2,
    },
    description: "Efficiency factors for different FTE conditions",
    type: "static",
    validate: (value) => (isValidNumber(value) ? value : 1.0),
  },

  // FTE Related Lookups
  jobsPerHourByRobotType: {
    value: {
      Origin: { picking: 25, putaway: 20 },
      Locus: { picking: 22, putaway: 18 },
      HAI: { picking: 23, putaway: 19 },
    },
    description: "Jobs per hour capabilities by robot type and operation",
    type: "static",
    validate: (value) => ({
      picking: isValidNumber(value?.picking) ? value.picking : 0,
      putaway: isValidNumber(value?.putaway) ? value.putaway : 0,
    }),
  },

  navFactor: {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        // Get passing capability from user input
        const passingCapability = safeGet(userInput, "determinedPassingCapability", "unableToEnterAisle");

        // Compute navFactor values first
        const navFactorValues = lookupReference["navFactor"]

        // Convert passing capability to lookup key
        const lookupKey = passingCapability.toLowerCase().replace(/[- ]/g, "");
        
        // Return both the factors and the selected value
        // This matches Excel XLOOKUP: =XLOOKUP(C24,B61:B64,C61:C64,"",0)
        return {
          unableToEnterAisle: safeGet(navFactorValues, "unableToEnterAisle", 0),  // C61: Unable to Enter Aisle
          noPass: safeGet(navFactorValues, "noPass", 0),                          // D61: No Pass
          twoWidePassing: safeGet(navFactorValues, "twoWidePassing", 0),          // E61: 2-Wide Passing
          threeWidePassing: safeGet(navFactorValues, "threeWidePassing", 0),      // F61: 3-Wide Passing
          selected: safeGet(navFactorValues, lookupKey, 0)
        };
      } catch (error) {
        console.log("Error computing navFactor:", error);
        return {
          unableToEnterAisle: 0,
          noPass: 0,
          twoWidePassing: 0,
          threeWidePassing: 0,
          selected: 0
        };
      }
    },
    description: "Navigation factors based on passing capability",
    type: "computed",
    dependencies: ["determinedPassingCapability", "navFactorValues"]
  },

  // Dynamic Lookups (these might need calculations)
  pickingDensity: {
    compute: (userInput, staticInputs, lookupReference) => {
      try {
        // Get base values from lookupReference state
        const densityRanges = safeGet(lookupReference, "pickDensity", []);

        // Get required values
        const robotArea = safeGet(userInput, "totalRobotArea", 0); // C14
        const linesPerDay = {
          picking: safeGet(userInput, "designVolumes.linesPerDay.picking", 0), // C33
          putaway: safeGet(userInput, "designVolumes.linesPerDay.putaway", 0)  // D33
        };
        const productiveHours = {
          picking: safeGet(userInput, "productiveHoursPerDayPicking", 0), // C21
          putaway: safeGet(userInput, "productiveHoursPerDayPutaway", 0)  // C22
        };

        // Calculate density per Excel formula
        // (ISUM(C33:D33)/MAX(C21:C22))/(C14/1000)
        const totalLines = linesPerDay.picking + linesPerDay.putaway;
        const maxProductiveHours = Math.max(productiveHours.picking, productiveHours.putaway);
        const density = (totalLines / maxProductiveHours) / (robotArea / 1000);

        // Calculate active status for each range per Excel formulas
        const result = densityRanges.map(range => ({
          ...range,
          isActive: range.lowerBounds === null ? 
            density > 0 :
            range.upperBounds === null ?
              density > range.lowerBounds :
              density > range.lowerBounds && density <= range.upperBounds
        }));

        return {
          density,
          ranges: result
        };
      } catch (error) {
        console.log("Error computing pickingDensity:", error);
        return {
          density: 0,
          ranges: []
        };
      }
    },
    description: "Density of picking locations in the robot area",
    type: "computed",
    dependencies: [
      "totalRobotArea",
      "designVolumes.linesPerDay.picking",
      "designVolumes.linesPerDay.putaway",
      "productiveHoursPerDayPicking",
      "productiveHoursPerDayPutaway"
    ]
  },

  walkDistance: {
    compute: (userInput, staticInputs, botCalculations, lookupReference) => {
      try {
        // Get required values from inputs
        const robotArea = safeGet(userInput, "totalRobotArea", 0); // C14
        const aisleWidth = safeGet(userInput, "totalWidthOfRobotArea", 0); // C12
        const aisleLength = safeGet(userInput, "totalLengthOfRobotArea", 0); // C13
        const metricOrImperial = safeGet(userInput, "metricOrImperial", "Imperial"); // C11
        
        // Calculate clustering factor based on metric/imperial
        // I23 =IFERROR(IF(C11="Imperial",3.8,1.16),"")
        const clusteringFactor = .75

        // Calculate pickDensity values first
        const pickDensity = lookupReference["pickDensity"]
        // Now use the computed values to find active range
        // I25 =XLOOKUP("Active",E83:E87,D83:D87,"",0)
        const activeRange = pickDensity.ranges.find(range => range.isActive);
        const waitTaskTime = activeRange ? activeRange.dwellTime : 0;

        // Get available robots from bot calculations
        const availableRobots = {
          picking: safeGet(botCalculations, "availableRobots.picking", 0), // C144
          putaway: safeGet(botCalculations, "availableRobots.putaway", 0), // D144
          concurrent: safeGet(botCalculations, "availableRobots.picking", 0) + safeGet(botCalculations, "availableRobots.putaway", 0) // E144
        };

        // Get divisors from state instead of hardcoding
        const divisors = safeGet(lookupReference, "walkDistance.divisors", {
          picking: 0,
          putaway: 0,
          concurrent: 0
        });

        // Calculate availableRobots values (C90:E90)
        const availableRobotsCalc = {
          picking: safeDivide(availableRobots.picking, divisors.picking), // C90: =C144/59.02
          putaway: safeDivide(availableRobots.putaway, divisors.putaway), // D90: =D144/6.403
          concurrent: safeDivide(availableRobots.concurrent, divisors.concurrent) // E90: =E144/65.419
        };

        // Calculate areaPerAvailableBot (C91:E91)
        const areaPerAvailableBot = {
          picking: safeDivide(robotArea, availableRobotsCalc.picking), // C91: =IFERROR(C14/C90,"")
          putaway: safeDivide(robotArea, availableRobotsCalc.putaway), // D91: =IFERROR(C14/D90,"")
          concurrent: safeDivide(robotArea, availableRobotsCalc.concurrent) // E91: =IFERROR(C14/E90,"")
        };

        // Calculate xPerRobot (C92:E92)
        const xPerRobot = {
          picking: safeDivide(Math.sqrt(areaPerAvailableBot.picking), Math.sqrt(safeDivide(aisleLength, aisleWidth))), // C92: =IFERROR(SQRT(C91)/(SQRT(C13/C12)),"")
          putaway: safeDivide(Math.sqrt(areaPerAvailableBot.putaway), Math.sqrt(safeDivide(aisleLength, aisleWidth))), // D92: =IFERROR(SQRT(D91)/(SQRT(C13/C12)),"")
          concurrent: safeDivide(Math.sqrt(areaPerAvailableBot.concurrent), Math.sqrt(safeDivide(aisleLength, aisleWidth))) // E92: =IFERROR(SQRT(E91)/(SQRT(C13/C12)),"")
        };

        // Calculate yPerRobot (C93:E93)
        const yPerRobot = {
          picking: safeDivide(Math.sqrt(areaPerAvailableBot.picking), Math.sqrt(safeDivide(aisleWidth, aisleLength))), // C93: =IFERROR(SQRT(C91)/(SQRT(C12/C13)),"")
          putaway: safeDivide(Math.sqrt(areaPerAvailableBot.putaway), Math.sqrt(safeDivide(aisleWidth, aisleLength))), // D93: =IFERROR(SQRT(D91)/(SQRT(C12/C13)),"")
          concurrent: safeDivide(Math.sqrt(areaPerAvailableBot.concurrent), Math.sqrt(safeDivide(aisleWidth, aisleLength))) // E93: =IFERROR(SQRT(E91)/(SQRT(C12/C13)),"")
        };

        // Calculate walkDistanceWithoutCluster (C94:E94)
        const walkDistanceWithoutCluster = {
          picking: safeDivide(
            safeAdd(
              safeAdd(xPerRobot.picking, yPerRobot.picking),
              Math.sqrt(Math.pow(xPerRobot.picking, 2) + Math.pow(yPerRobot.picking, 2))
            ),
            2
          ),
          putaway: safeDivide(
            safeAdd(
              safeAdd(xPerRobot.putaway, yPerRobot.putaway),
              Math.sqrt(Math.pow(xPerRobot.putaway, 2) + Math.pow(yPerRobot.putaway, 2))
            ),
            2
          ),
          concurrent: safeDivide(
            safeAdd(
              safeAdd(xPerRobot.concurrent, yPerRobot.concurrent),
              Math.sqrt(Math.pow(xPerRobot.concurrent, 2) + Math.pow(yPerRobot.concurrent, 2))
            ),
            2
          )
        };
        console.log("walkDistanceWithoutCluster999", walkDistanceWithoutCluster);

        // Calculate walkDistanceWithCluster (C95:E95)
        const walkDistanceWithCluster = {
          picking: safeMultiply(walkDistanceWithoutCluster.picking, clusteringFactor), // C95: =IFERROR(C94*I9,"")
          putaway: safeMultiply(walkDistanceWithoutCluster.putaway, clusteringFactor), // D95: =IFERROR(D94*I9,"")
          concurrent: safeMultiply(walkDistanceWithoutCluster.concurrent, clusteringFactor) // E95: =IFERROR(E94*I9,"")
        };

        return {
          availableRobots: availableRobotsCalc,
          areaPerAvailableBot,
          xPerRobot,
          yPerRobot,
          walkDistanceWithoutCluster,
          walkDistanceWithCluster,
          waitTaskTime
        };
      } catch (error) {
        console.log("Error computing walkDistance:", error);
        return {
          availableRobots: { picking: 0, putaway: 0, concurrent: 0 },
          areaPerAvailableBot: { picking: 0, putaway: 0, concurrent: 0 },
          xPerRobot: { picking: 0, putaway: 0, concurrent: 0 },
          yPerRobot: { picking: 0, putaway: 0, concurrent: 0 },
          walkDistanceWithoutCluster: { picking: 0, putaway: 0, concurrent: 0 },
          walkDistanceWithCluster: { picking: 0, putaway: 0, concurrent: 0 },
          waitTaskTime: 0
        };
      }
    },
    description: "Walk distances based on robot availability and area",
    type: "computed",
    dependencies: [
      "totalRobotArea",
      "totalWidthOfRobotArea",
      "totalLengthOfRobotArea",
      "metricOrImperial",
      "availableRobots"
    ]
  },
};

// Helper function to get lookup value
export const getLookupValue = (lookupId, params = {}) => {
  try {
    const lookup = lookupMap[lookupId];
    if (!lookup) {
      console.log(`Lookup not found: ${lookupId}`);
      return null;
    }

    if (lookup.type === "static") {
      const value = lookup.value;
      return lookup.validate ? lookup.validate(value) : value;
    }

    if (lookup.type === "computed" && lookup.compute) {
      try {
        const result = lookup.compute(params?.userInput || {}, params?.staticInputs || {}, params?.lookupReference || {});
        return result;
      } catch (error) {
        console.log(`Error computing lookup ${lookupId}:`, error);
        return null;
      }
    }

    return null;
  } catch (error) {
    console.log("Error in getLookupValue:", error);
    return null;
  }
};

// Function to get lookup metadata
export const getLookupMetadata = () => {
  try {
    return Object.fromEntries(
      Object.entries(lookupMap || {}).map(([key, lookup]) => [
        key,
        {
          description: lookup?.description || "",
          type: lookup?.type || "unknown",
          dependencies:
            lookup?.type === "computed" && Array.isArray(lookup?.dependencies)
              ? lookup.dependencies
              : [],
        },
      ]),
    );
  } catch (error) {
    console.log("Error in getLookupMetadata:", error);
    return {};
  }
};

// Calculate all lookup references
export const calculateLookupReferences = (userInput = {}, staticInputs = {}, botCalculations = {}, lookupReference = {}) => {
  try {
    const computedValues = {};
    const metadata = {};

    // 1. First calculate cutThroughEffectiveness since distRef depends on it
    computedValues.cutThroughEffectiveness = calculateCutThroughEffectiveness(userInput, staticInputs, JSON.parse(JSON.stringify(lookupReference)));

    // 2. Calculate distRef which depends on cutThroughEffectiveness
    computedValues.distRef = calculateDistRef(userInput, staticInputs, {
      ...lookupReference,
      cutThroughEffectiveness: computedValues.cutThroughEffectiveness
    });

    // 3. Calculate navFactor which is independent
    computedValues.navFactor = calculateNavFactor(userInput, staticInputs, lookupReference);

    // 4. Calculate pickDensity which is needed for walkDistance
    computedValues.pickDensity = calculatePickDensity(userInput, staticInputs, lookupReference);

    // 5. Finally calculate walkDistance which depends on pickDensity
    computedValues.walkDistance = calculateWalkDistance(userInput, staticInputs, botCalculations, {
      ...lookupReference,
      pickDensity: computedValues.pickDensity
    });

    return {
      values: computedValues,
      metadata: metadata
    };
  } catch (error) {
    console.log("Error in calculateLookupReferences:", error);
    return {
      values: {},
      metadata: {},
      error: error.message
    };
  }
};

const calculateCutThroughEffectiveness = (userInput, staticInputs, lookupReference) => {
  try {
    // Get values from state
    const cutThroughEffectivenessArray = safeGet(lookupReference, "cutThroughEffectiveness", []);
    const aisleLength = safeGet(userInput, "totalLengthOfRobotArea", 0); // C13
    const aisleWidth = safeGet(userInput, "totalWidthOfRobotArea", 0);   // C12
    const numCutThroughs = safeGet(userInput, "numberOfCutThroughsInRobotArea", 0); // C17

    // Map through the array and calculate endCap values
    return cutThroughEffectivenessArray.map(item => {
      const cutThroughs = safeGet(item, "cutThroughs", 0);
      const percentOfAisle = safeGet(item, "percentOfAisle", 0);

      // Calculate endCap based on number of cutThroughs per Excel formulas
      let endCap = 0;
      if (cutThroughs === 0) {
        // D67: =((C13/(B67+1))/2)+((C13/(B67+1))/2)+(C12*0.5)
        endCap = safeDivide(safeDivide(aisleLength, cutThroughs + 1), 2) + 
                 safeDivide(safeDivide(aisleLength, cutThroughs + 1), 2) + 
                 safeMultiply(aisleWidth, 0.5);
      } else if (cutThroughs === 1) {
        // D68: =((C13/(B68+1))/2)+(C12*0.5)
        endCap = safeDivide(safeDivide(aisleLength, cutThroughs + 1), 2) + safeMultiply(aisleWidth, 0.5);
        //** update later */
      } else if (cutThroughs === 2) {
        if(percentOfAisle === null) {
          // D74: =AVERAGE(XLOOKUP(ROUNDDOWN(C17,0),B67:B73,D67:D73),XLOOKUP(ROUNDUP(C17,0),B67:B73,D67:D73))
          const roundedDown = Math.floor(numCutThroughs);
          const roundedUp = Math.ceil(numCutThroughs);
          
          // Find endCap values for rounded down and up
          const roundedDownValue = cutThroughEffectivenessArray
            .filter(v => v.percentOfAisle !== null)
            .find(v => v.cutThroughs === roundedDown)?.endCap || 0;
            
          const roundedUpValue = cutThroughEffectivenessArray
            .filter(v => v.percentOfAisle !== null)
            .find(v => v.cutThroughs === roundedUp)?.endCap || 0;
            
          // Calculate average
          endCap = (roundedDownValue + roundedUpValue) / 2;
        } else {
          // D69: =((C13/(B69+1))/2)+((C13/(B69+1))/2)+(C12*0.5)
          endCap = safeDivide(safeDivide(aisleLength, cutThroughs + 1), 2) + 
                   safeDivide(safeDivide(aisleLength, cutThroughs + 1), 2) + 
                   safeMultiply(aisleWidth, 0.5);
        }
      } else if (cutThroughs === 3) {
        // D70: =(C13*0.25)+(C12*0.5)
        endCap = safeMultiply(aisleLength, 0.25) + safeMultiply(aisleWidth, 0.5);
      } else if (cutThroughs === 4) {
        // D71: =(C13*0.1)+(C13*0.18)+(C12*0.5)
        endCap = safeMultiply(aisleLength, 0.1) + safeMultiply(aisleLength, 0.18) + safeMultiply(aisleWidth, 0.5);
      } else if (cutThroughs === 5) {
        // D72: =(C13*0.25)+(C12*0.5)
        endCap = safeMultiply(aisleLength, 0.25) + safeMultiply(aisleWidth, 0.5);
      } else if (cutThroughs === 6) {
        // D73: =(C13*0.071)+(C13*0.1939)+(C12*0.5)
        endCap = safeMultiply(aisleLength, 0.071) + safeMultiply(aisleLength, 0.1939) + safeMultiply(aisleWidth, 0.5);
      }

      return {
        cutThroughs,
        percentOfAisle,
        endCap
      };
    });
  } catch (error) {
    console.log("Error computing cutThroughEffectiveness:", error);
    return [];
  }
};

const calculateDistRef = (userInput, staticInputs, lookupReference) => {
  try {
    const aisleLength = safeGet(userInput, "totalLengthOfRobotArea", 0); // C13
    const aisleWidth = safeGet(userInput, "totalWidthOfRobotArea", 0);   // C12
    const numCutThroughs = safeGet(userInput, "numberOfCutThroughsInRobotArea", 0); // C17

    // Get cutThroughEffectiveness values for centerOfSpaceAlongAislePickFaces calculation
    const cutThroughEffectivenessValues = calculateCutThroughEffectiveness(userInput, staticInputs, lookupReference);
    
    return {
      // C77: =IFERROR((0.5*C12)+(0.5*C13),"")
      cornerOfPickSpace: safeMultiply(0.5, aisleWidth) + safeMultiply(0.5, aisleLength),
      
      // C78: =IFERROR((0.5*C13)+(0.25*C12),"")
      centerOfSpaceAlongEndCaps: safeMultiply(0.5, aisleLength) + safeMultiply(0.25, aisleWidth),
      
      // C79: =IFERROR(XLOOKUP(IF(C17>6,6,C17),B67:B74,D67:D74),"")
      centerOfSpaceAlongAislePickFaces: cutThroughEffectivenessValues?.find(v => 
        v.cutThroughs === (numCutThroughs > 6 ? 6 : numCutThroughs))?.endCap || 0,
      
      // C80: =IFERROR((0.25*C13)+(0.25*C12),"")
      centerOfPickSpace: safeMultiply(0.25, aisleLength) + safeMultiply(0.25, aisleWidth)
    };
  } catch (error) {
    console.log("Error computing distRef:", error);
    return {
      cornerOfPickSpace: 0,
      centerOfSpaceAlongEndCaps: 0,
      centerOfSpaceAlongAislePickFaces: 0,
      centerOfPickSpace: 0
    };
  }
};

const calculateNavFactor = (userInput, staticInputs, lookupReference) => {
  try {
    // Get passing capability from user input
    const passingCapability = safeGet(userInput, "determinedPassingCapability", "unableToEnterAisle");

    // Compute navFactor values first
    const navFactorValues = lookupReference["navFactor"]
    // Convert passing capability to lookup key
    const lookupKey = passingCapability.toLowerCase().replace(/[- ]/g, "");
    
    // Return both the factors and the selected value
    // This matches Excel XLOOKUP: =XLOOKUP(C24,B61:B64,C61:C64,"",0)
    return {
      unableToEnterAisle: safeGet(navFactorValues, "unableToEnterAisle", 0),  // C61: Unable to Enter Aisle
      noPass: safeGet(navFactorValues, "noPass", 0),                          // D61: No Pass
      twoWidePassing: safeGet(navFactorValues, "twoWidePassing", 0),          // E61: 2-Wide Passing
      threeWidePassing: safeGet(navFactorValues, "threeWidePassing", 0),      // F61: 3-Wide Passing
      selected: safeGet(navFactorValues, lookupKey, 0)
    };
  } catch (error) {
    console.log("Error computing navFactor:", error);
    return {
      unableToEnterAisle: 0,
      noPass: 0,
      twoWidePassing: 0,
      threeWidePassing: 0,
      selected: 0
    };
  }
};

const calculatePickDensity = (userInput, staticInputs, lookupReference) => {
  try {
    // Get base values from lookupReference state
    const densityRanges = safeGet(lookupReference, "pickDensity", []);

    // Get required values from userInput
    const robotArea = safeGet(userInput, "totalRobotArea", 0); // C14
    const linesPerDay = {
      picking: safeGet(userInput, "designVolumes.linesPerDay.picking", 0), // C33
      putaway: safeGet(userInput, "designVolumes.linesPerDay.putaway", 0)  // D33
    };
    const productiveHours = {
      picking: safeGet(userInput, "productiveHoursPerDayPicking", 0), // C21
      putaway: safeGet(userInput, "productiveHoursPerDayPutaway", 0)  // C22
    };

    // Calculate density per Excel formula
    // ((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))
    const totalLines = linesPerDay.picking + linesPerDay.putaway;
    const maxProductiveHours = Math.max(productiveHours.picking, productiveHours.putaway);
    const density = safeDivide(safeDivide(totalLines, maxProductiveHours), safeDivide(robotArea, 1000));

    // Calculate active status for each range using their specific Excel formulas
    const result = densityRanges.map(range => {
      let isActive = false;
      
      if (range.lowerBounds === null && range.upperBounds === 1) {
        // E83: =IF(((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))<C83,"Active","")
        isActive = density < range.upperBounds;
      } 
      else if (range.lowerBounds === 1 && range.upperBounds === 3) {
        // E84: =IF(AND(((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))>=B84,((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))<C84),"Active","")
        isActive = (density >= range.lowerBounds && density < range.upperBounds);
      }
      else if (range.lowerBounds === 3 && range.upperBounds === 10) {
        // E85: =IF(AND(((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))>=B85,((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))<C85),"Active","")
        isActive = (density >= range.lowerBounds && density < range.upperBounds);
      }
      else if (range.lowerBounds === 10 && range.upperBounds === 20) {
        // E86: =IF(AND(((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))>=B86,((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))<C86),"Active","")
        isActive = (density >= range.lowerBounds && density < range.upperBounds);
      }
      else if (range.lowerBounds === 20 && range.upperBounds === null) {
        // E87: =IF(((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))>=B87,"Active","")
        isActive = density >= range.lowerBounds;
      }

      return {
        ...range,
        isActive
      };
    });

    return result
  } catch (error) {
    console.log("Error computing pickDensity:", error);
    return safeGet(lookupReference, "pickDensity", []);
  }
};

// Export the individual calculation functions
export const calculateWalkDistance = (userInput, staticInputs, botCalculations, lookupReference) => {
  try {
    // Get required values from inputs
    const robotArea = safeGet(userInput, "totalRobotArea", 0); // C14
    const aisleWidth = safeGet(userInput, "totalWidthOfRobotArea", 0); // C12
    const aisleLength = safeGet(userInput, "totalLengthOfRobotArea", 0); // C13
    const metricOrImperial = safeGet(userInput, "metricOrImperial", "Imperial"); // C11
    
    // Calculate clustering factor based on metric/imperial
    // I23 =IFERROR(IF(C11="Imperial",3.8,1.16),"")
    const clusteringFactor = 0.75

    // Calculate pickDensity values first
    const pickDensity = lookupReference["pickDensity"]
    // Now use the computed values to find active range
    // I25 =XLOOKUP("Active",E83:E87,D83:D87,"",0)
    const activeRange = pickDensity.find(range => range.isActive);
    const waitTaskTime = activeRange ? activeRange.dwellTime : 0;

    // Get available robots from bot calculations
    const availableRobots = {
      picking: safeGet(botCalculations, "availableRobots.picking", 0), // C144
      putaway: safeGet(botCalculations, "availableRobots.putaway", 0), // D144
      concurrent: safeGet(botCalculations, "availableRobots.picking", 0) + safeGet(botCalculations, "availableRobots.putaway", 0) // E144
    };

    // Get divisors from state instead of hardcoding
    const divisors = safeGet(lookupReference, "walkDistance.divisors", {
      picking: 0,
      putaway: 0,
      concurrent: 0
    });

    // Calculate availableRobots values (C90:E90)
    const availableRobotsCalc = {
      picking: availableRobots.picking, // C90: =C144
      putaway: availableRobots.putaway, // D90: =D144
      concurrent: availableRobots.concurrent // E90: =E144
    };

    // Calculate areaPerAvailableBot (C91:E91)
    const areaPerAvailableBot = {
      picking: safeDivide(robotArea, availableRobotsCalc.picking), // C91: =IFERROR(C14/C90,"")
      putaway: safeDivide(robotArea, availableRobotsCalc.putaway), // D91: =IFERROR(C14/D90,"")
      concurrent: safeDivide(robotArea, availableRobotsCalc.concurrent) // E91: =IFERROR(C14/E90,"")
    };

    // Calculate xPerRobot (C92:E92)
    const xPerRobot = {
      picking: safeDivide(Math.sqrt(areaPerAvailableBot.picking), Math.sqrt(safeDivide(aisleLength, aisleWidth))), // C92: =IFERROR(SQRT(C91)/(SQRT(C13/C12)),"")
      putaway: safeDivide(Math.sqrt(areaPerAvailableBot.putaway), Math.sqrt(safeDivide(aisleLength, aisleWidth))), // D92: =IFERROR(SQRT(D91)/(SQRT(C13/C12)),"")
      concurrent: safeDivide(Math.sqrt(areaPerAvailableBot.concurrent), Math.sqrt(safeDivide(aisleLength, aisleWidth))) // E92: =IFERROR(SQRT(E91)/(SQRT(C13/C12)),"")
    };

    // Calculate yPerRobot (C93:E93)
    const yPerRobot = {
      picking: safeDivide(Math.sqrt(areaPerAvailableBot.picking), Math.sqrt(safeDivide(aisleWidth, aisleLength))), // C93: =IFERROR(SQRT(C91)/(SQRT(C12/C13)),"")
      putaway: safeDivide(Math.sqrt(areaPerAvailableBot.putaway), Math.sqrt(safeDivide(aisleWidth, aisleLength))), // D93: =IFERROR(SQRT(D91)/(SQRT(C12/C13)),"")
      concurrent: safeDivide(Math.sqrt(areaPerAvailableBot.concurrent), Math.sqrt(safeDivide(aisleWidth, aisleLength))) // E93: =IFERROR(SQRT(E91)/(SQRT(C12/C13)),"")
    };

    // Calculate walkDistanceWithoutCluster (C94:E94)
    const walkDistanceWithoutCluster = {
      picking: safeDivide(
        safeAdd(
          safeAdd(xPerRobot.picking, yPerRobot.picking),
          Math.sqrt(Math.pow(xPerRobot.picking, 2) + Math.pow(yPerRobot.picking, 2))
        ),
        2
      ),
      putaway: safeDivide(
        safeAdd(
          safeAdd(xPerRobot.putaway, yPerRobot.putaway),
          Math.sqrt(Math.pow(xPerRobot.putaway, 2) + Math.pow(yPerRobot.putaway, 2))
        ),
        2
      ),
      concurrent: safeDivide(
        safeAdd(
          safeAdd(xPerRobot.concurrent, yPerRobot.concurrent),
          Math.sqrt(Math.pow(xPerRobot.concurrent, 2) + Math.pow(yPerRobot.concurrent, 2))
        ),
        2
      )
    };
    // Calculate walkDistanceWithCluster (C95:E95)
    const walkDistanceWithCluster = {
      picking: safeMultiply(walkDistanceWithoutCluster.picking, clusteringFactor), // C95: =IFERROR(C94*I9,"")
      putaway: safeMultiply(walkDistanceWithoutCluster.putaway, clusteringFactor), // D95: =IFERROR(D94*I9,"")
      concurrent: safeMultiply(walkDistanceWithoutCluster.concurrent, clusteringFactor) // E95: =IFERROR(E94*I9,"")
    };

    return {
      availableRobots: availableRobotsCalc,
      areaPerAvailableBot,
      xPerRobot,
      yPerRobot,
      walkDistanceWithoutCluster,
      walkDistanceWithCluster,
      waitTaskTime
    };
  } catch (error) {
    console.log("Error computing walkDistance:", error);
    return {
      availableRobots: { picking: 0, putaway: 0, concurrent: 0 },
      areaPerAvailableBot: { picking: 0, putaway: 0, concurrent: 0 },
      xPerRobot: { picking: 0, putaway: 0, concurrent: 0 },
      yPerRobot: { picking: 0, putaway: 0, concurrent: 0 },
      walkDistanceWithoutCluster: { picking: 0, putaway: 0, concurrent: 0 },
      walkDistanceWithCluster: { picking: 0, putaway: 0, concurrent: 0 },
      waitTaskTime: 0
    };
  }
};
