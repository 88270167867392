import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { dispatch } from "../index";
import { storage } from "../../utils/helpers/storage";

import {
  SUPER_ADMIN,
  LOCUS_ASSET_MANAGER,
  LOCUS_USER_MANAGER,
  USER_MANAGER,
  LOCUS_CUSTOMER_USER_MANAGER,
  LOCUS_TOOLS_USER,
  LOCUS_CONFIGURATION_ADMIN,
  CONFIGURATION_ADMIN,
  CROSS_SITE_VIEWER,
  LOCUS_TENANT,
  SYSTEM_INTEGRATOR,
  ASSET_MANAGER,
} from "../../utils/constants/user";

// ----------------------------------------------------------------------

const defaultAdvancedLocus = [
  {
    id: "636bf1338483ff4453b13496",
    canModify: false,
    name: LOCUS_TENANT,
    description: "Allows a Locus Robotics User default read access.",
    icon: "visibility_outlined",
    locus: true,
  },
  {
    id: "636bf1338483ff4453b1348c",
    canModify: false,
    name: LOCUS_TOOLS_USER,
    description: "Allows a Locus Robotics user to access all tools.",
    locus: true,
    icon: "build_circle_outlined",
  },
  {
    id: "636bf1338483ff4453b13494",
    canModify: false,
    name: LOCUS_ASSET_MANAGER,
    description:
      "Allows a Locus Robotics user to view and edit asset information for all clients.",
    locus: true,
    icon: "devices_outlined",
  },
  {
    id: "636bf1338483ff4453b1348b",
    canModify: false,
    name: LOCUS_CONFIGURATION_ADMIN,
    description: "Allows a Locus Robotics user to set-up clients and sites.",
    locus: true,
    icon: "stars_outlined",
  },
  {
    id: "636bf1338483ff4453b1348d",
    canModify: false,
    name: LOCUS_CUSTOMER_USER_MANAGER,
    description:
      "Allows a Locus Robotics user to view and edit user permissions of client users.",
    icon: "supervised_user_circle_outlined",
    locus: true,
  },
  {
    id: "636bf1338483ff4453b1348f",
    canModify: false,
    name: LOCUS_USER_MANAGER,
    description:
      "Allows a Locus Robotics user to view and edit user permissions of other Locus Robotics users.",
    icon: "supervised_user_circle_outlined",
    locus: true,
  },
  {
    id: "65240a05570392693d290db3",
    canModify: false,
    name: SYSTEM_INTEGRATOR,
    description:
      "Identifies a user as a system integrator user, which provides the ability to run bot estimates.",
    icon: "calculate",
    requiredGroups: [SUPER_ADMIN],
    locus: true,
  },
  {
    id: "65240a05570392693d290db3",
    canModify: false,
    name: SUPER_ADMIN,
    description: "Allows a Locus Robotics user to have all access.",
    icon: "lock_person_outlined",
    locus: true,
  },
];

const defaultAdvancedClient = [
  {
    id: "636bf1338483ff4453b13490",
    canModify: false,
    name: CROSS_SITE_VIEWER,
    description:
      "Allows a user to view all unprotected information about any site within the client.",
    icon: "preview_outlined",
    preventDisable: [CONFIGURATION_ADMIN],
  },
  {
    id: "636bf1338483ff4453b13491",
    canModify: false,
    name: USER_MANAGER,
    description:
      "Allows a user to view and edit user profiles across sites in a client.",
    icon: "supervised_user_circle_outlined",
  },
  {
    id: "636bf1338483ff4453b13493",
    canModify: false,
    name: CONFIGURATION_ADMIN,
    description:
      "Allows a user to change client-level details such as address and logo. Users must have Cross-Site Viewer permission before other advanced permissions are given.",
    icon: "stars_outlined",
    requires: [CROSS_SITE_VIEWER],
  },
  {
    id: "65240a05570392693d290db3",
    canModify: false,
    name: SYSTEM_INTEGRATOR,
    description:
      "Identifies a user as a system integrator user, which provides the ability to run bot estimates.",
    icon: "calculate",
    requiredGroups: [SUPER_ADMIN],
  },
  {
    id: "636bf1338483ff4453b13495",
    canModify: false,
    name: ASSET_MANAGER,
    description:
      "Allows a user to edit certain device properties. Users must have Cross-Site Viewer permission.",
    icon: "stars_outlined",
    requires: [CROSS_SITE_VIEWER],
  },
];

// Initial state
export const initialState = {
  error: null,
  groups: [],
  usersByActiveGroup: [],
  defaultAdvancedLocus: [...defaultAdvancedLocus],
  defaultAdvancedClient: [...defaultAdvancedClient],
  defaultAdvanced: [...defaultAdvancedLocus, ...defaultAdvancedClient],
  groupById: null,
};

// Slice
const slice = createSlice({
  name: "group",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getGroupSuccess(state, action) {
      state.groups = action.payload;
    },
    getClientAdvancedSuccess(state, action) {
      state.clientAdvanced = action.payload;
    },
    getLocusAdvancedSuccess(state, action) {
      state.locusAdvanced = action.payload;
    },
    getUsersByActiveGroupSuccess(state, action) {
      state.usersByActiveGroup = action.payload;
    },
    resetUsersByActiveGroupSuccess(state) {
      state.usersByActiveGroup = [];
    },
    getGroupByIdSuccess(state, action) {
      state.groupById = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Async actions
export function getGroups() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_API}/group`,
      );
      dispatch(slice.actions.getGroupSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersByActiveGroup(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_API}/group/${id}/members`,
      );
      const users =
        response?.data?.users?.filter((user) => user.type === "human") || [];
      dispatch(slice.actions.getUsersByActiveGroupSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetUsersByActiveGroup() {
  return async () => {
    dispatch(slice.actions.resetUsersByActiveGroupSuccess());
  };
}

export function getGroupById(id) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_API}/group/${id}`,
      );
      dispatch(slice.actions.getGroupByIdSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createGroup(data, selectedClient) {
  return async () => {
    try {
      data.domain = selectedClient?.domain || storage.getDomain() || null;
      return await axios.post(`${process.env.REACT_APP_AUTH_API}/group`, data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function deleteGroup(id) {
  return async () => {
    try {
      return await axios.delete(
        `${process.env.REACT_APP_AUTH_API}/group/${id}`,
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function editGroup(groupId, data) {
  return async () => {
    try {
      return await axios.put(
        `${process.env.REACT_APP_AUTH_API}/group/${groupId}`,
        data,
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function batchEditGroup(groupId, members) {
  return async () => {
    try {
      return await axios.patch(
        `${process.env.REACT_APP_AUTH_API}/group/${groupId}/members`,
        {
          usingUsernames: false,
          members,
        },
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
