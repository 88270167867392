// third-party
import { FormattedMessage } from "react-intl";
import {
  LocusAnalyticsDashboardsIcon,
  LocusReportsIcon,
} from "../assets/icons/LocusIcons.js";
import {
  CROSS_SITE_VIEWER,
  LOCUS_ASSET_MANAGER,
  LOCUS_TENANT,
  VIEWER,
} from "../utils/constants/user";
import * as React from "react";

// ==============================|| APPLICATION MENU ITEMS ||============================== //
/** @type {LocusMenuGroup} */
const analytics = {
  id: "analytics",
  title: <FormattedMessage id="analytics" />,
  type: "group",
  view: ["locus", "site"],
  children: [
    {
      id: "dashboards",
      title: <FormattedMessage id="dashboards" />,
      type: "collapse",
      icon: LocusAnalyticsDashboardsIcon,
      view: ["site"],
      children: [
        {
          id: "performance",
          title: <FormattedMessage id="performance" />,
          type: "item",
          url: "dashboards/performance?site=:siteId",
          breadcrumbs: true,
          view: ["site"],
          allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
        },
        {
          id: "visibility",
          title: <FormattedMessage id="visibility" />,
          type: "item",
          url: "dashboards/visibility?site=:siteId",
          breadcrumbs: true,
          view: ["site"],
          allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
        },
        {
          id: "management",
          title: <FormattedMessage id="management" />,
          type: "item",
          url: "dashboards/management?site=:siteId",
          breadcrumbs: true,
          view: ["site"],
          allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
        },
      ],
    },
    {
      id: "reports",
      title: <FormattedMessage id="reports" />,
      type: "collapse",
      icon: LocusReportsIcon,
      view: ["locus", "site"],
      children: [
        {
          id: "performance",
          title: <FormattedMessage id="performance" />,
          type: "item",
          url: "reports/performance?site=:siteId",
          breadcrumbs: true,
          view: ["site"],
          allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
        },
        {
          id: "visibility",
          title: <FormattedMessage id="visibility" />,
          type: "item",
          url: "reports/visibility?site=:siteId",
          breadcrumbs: true,
          view: ["site"],
          allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
        },
        {
          id: "management",
          title: <FormattedMessage id="management" />,
          type: "item",
          url: "reports/management?site=:siteId",
          breadcrumbs: true,
          view: ["site"],
          allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
        },
        {
          id: "robot-assets",
          title: <FormattedMessage id="bot-assets" />,
          type: "item",
          url: "reports/robot-assets",
          breadcrumbs: true,
          view: ["locus"],
          allowedGroups: [LOCUS_ASSET_MANAGER],
        },
      ],
    },
  ],
};

export default analytics;
