const helpUrls = {
  default:
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub_Helpc.htm",
  robots:
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAsset_Management%2FRobots.htm",
  chargers:
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAsset_Management%2FChargers.htm",
  devices:
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAsset_Management%2FDevices.htm",
  "integration-monitoring":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FTools%2FIntegration_Monitoring.htm&rhsearch=integration%20monitoring&rhhlterm=integration%20monitoring",
  clients:
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub_Helpc.htm",
  users:
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAdmininstration%2FUsers.htm",
  permissions:
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAdmininstration%2FPermissions.htm",
  "analytics-manager":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub_Helpc.htm",
  "release-info":
    "https://locushub-asset.locus-robotics.com/help/LocusHub/Introduction/What_s_New_in_Update_1.htm",
  "service-health":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FHelp%2FService_Health.htm&rhsearch=service%20health&rhhlterm=service%20health",
  "device-configuration":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FTools%2FiPad_Access.htm&rhsearch=ipad%20access&rhhlterm=ipad%20ipads%20access",
  "update-configuration-files":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FTools%2FSite_File_Upload.htm&rhsearch=site%20file%20upload&rhhlterm=site%20file%20files%20upload%20uploaded%20uploading",
  client:
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub_Helpc.htm",
  site: "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub_Helpc.htm",
};

const helpDashboardUrls = {
  "performance-site-overview":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FDashboards%2FPerformance_Dashboards%2FMy_Overview.htm",
  "performance-induction":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FDashboards%2FPerformance_Dashboards%2FInduction.htm",
  "performance-associate-performance":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FDashboards%2FPerformance_Dashboards%2FAssociate_Performance.htm",

  "visibility-open-work":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FDashboards%2FVisibility_Dashboards%2FOpen_Work.htm",
  "visibility-order-pool":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FDashboards%2FVisibility_Dashboards%2FOrder_Pool.htm",
  "visibility-task-map":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FDashboards%2FVisibility_Dashboards%2FTask_Map.htm",

  "management-client-overview":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FDashboards%2FManagement_Dashboards%2FBusiness_Overview.htm",
  "management-work-assignment":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FDashboards%2FManagement_Dashboards%2FWork_by_Aisle.htm",
  "site-overview":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FDashboards%2FPerformance_Dashboards%2FMy_Overview.htm",
  "management-overview":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FDashboards%2FPerformance_Dashboards%2FMy_Overview.htm",
  "management-work-by-aisle":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FDashboards%2FManagement_Dashboards%2FWork_by_Aisle.htm",
};

const helpReportUrls = {
  "management-analyst":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FManagement_Reports%2FAnalyst_Report.htm",
  "management-client-scorecard":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FManagement_Reports%2FClient_Scorecard_Report.htm",
  "management-health-metrics":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FManagement_Reports%2FHealth_Metrics_Report.htm",
  "management-checkmark-detail":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FManagement_Reports%2FPick_Checkmark_Detail_Report.htm",
  "management-checkmark-summary":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FManagement_Reports%2FPick_Checkmark_Summary_Report.htm",
  "management-pick-detail":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FManagement_Reports%2FPick_Detail_Report.htm",
  "management-short-detail":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FManagement_Reports%2FShort_Detail_Report.htm",
  "management-pick-short-detail":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FManagement_Reports%2FShort_Detail_Report.htm",
  "management-detail":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FManagement_Reports%2FPick_Detail_Report.htm",
  "management-bot-usage":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FManagement_Reports%2FBot_Usage_Report.htm",
  "management-task-detail":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FManagement_Reports%2FPick_Detail_Report.htm&rhsearch=task%20detail&rhhlterm=task%20detail%20details",
  "performance-associate-lph-uph-summary":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FPerformance_Reports%2FAssociate_LPH_UPH_Summary_Report.htm",
  "performance-executive-summary":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FPerformance_Reports%2FExecutive_Summary_Report.htm",
  "performance-lph-uph-summary":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FPerformance_Reports%2FLPH_UPH_Summary_Report.htm",
  "performance-mission-detail":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FPerformance_Reports%2FMission_Detail_Report.htm",
  "performance-rates":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FPerformance_Reports%2FRate_Report.htm",
  "visibility-volume":
    "https://locushub-asset.locus-robotics.com/help/index.htm#t=LocusHub%2FAnalytics%2FReports%2FVisibility_Reports%2FVolume_Report.htm",
};

const formatKey = (str) => {
  return str
    .toLowerCase()
    .replace(/[\s\W-]+/g, "-") // Replace spaces, special characters, and hyphens with a single hyphen
    .replace(/-hourly|-daily|-monthly|-yearly/g, ""); // Replace specific words with an empty string
};

export const getHelpUrl = (currentUrl, additionalString = "") => {
  currentUrl = currentUrl
    ? currentUrl.toLowerCase()
    : window.location.href.toLowerCase();

  // Check for overview Dashboard
  if (currentUrl.includes("overview-dashboard")) {
    return helpDashboardUrls["site-overview"];
  }

  if (additionalString) {
    let keySegment = "";

    if (currentUrl.includes("performance")) {
      keySegment = "performance";
    } else if (currentUrl.includes("management")) {
      keySegment = "management";
    } else if (currentUrl.includes("visibility")) {
      keySegment = "visibility";
    }

    if (!keySegment) {
      const key = Object.keys(helpUrls).find((keyword) =>
        currentUrl.includes(keyword),
      );
      return helpUrls[key] || helpUrls["default"];
    }

    const formattedKey = formatKey(`${keySegment}-${additionalString}`);
    let currentUrlBase = currentUrl.split("?");
    currentUrlBase = currentUrlBase[0] || currentUrl;
    if (currentUrlBase.includes("dashboard")) {
      return helpDashboardUrls[formattedKey] || helpUrls["default"];
    } else if (currentUrlBase.includes("reports")) {
      return helpReportUrls[formattedKey] || helpUrls["default"];
    }
  }

  const key = Object.keys(helpUrls).find((keyword) =>
    currentUrl.includes(keyword),
  );
  return helpUrls[key] || helpUrls["default"];
};
