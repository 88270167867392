// third-party
import { FormattedMessage } from "react-intl";

import {
  LocusOriginBotIcon,
  LocusChargersIcon,
  LocusDevicesIcon,
} from "assets/icons/LocusIcons.js";
import {
  ASSET_MANAGER,
  CROSS_SITE_VIEWER,
  LOCUS_ASSET_MANAGER,
  LOCUS_TENANT,
  VIEWER,
} from "../utils/constants/user";

// ==============================|| APPLICATION MENU ITEMS ||============================== //
/** @type {LocusMenuGroup} */
const assetManagement = {
  id: "asset-management",
  title: <FormattedMessage id="asset-management" />,
  type: "group",
  view: ["locus", "client", "site"],
  className: "guided-tour-nav-asset-management",
  children: [
    {
      id: "robots",
      title: <FormattedMessage id="robots" />,
      type: "item",
      icon: LocusOriginBotIcon,
      url: "asset-management/robots?site=:siteId",
      breadcrumbs: true,
      view: ["locus", "client", "site"],
      allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
    },
    {
      id: "chargers",
      title: <FormattedMessage id="chargers" />,
      type: "item",
      icon: LocusChargersIcon,
      url: "asset-management/chargers?site=:siteId",
      breadcrumbs: true,
      view: ["locus", "client", "site"],
      // https://locusrobotics.atlassian.net/browse/HUB-1614 && HUB-1627
      allowedGroups: [LOCUS_ASSET_MANAGER],
      // allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
    },
    {
      id: "devices",
      title: <FormattedMessage id="devices" />,
      type: "item",
      icon: LocusDevicesIcon,
      url: "asset-management/devices?site=:siteId",
      breadcrumbs: true,
      view: ["locus", "client", "site"],
      // https://locusrobotics.atlassian.net/browse/HUB-1614 && HUB-1627
      allowedGroups: [LOCUS_ASSET_MANAGER, ASSET_MANAGER],
      // allowedGroups: [LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER],
    },
  ],
};

export default assetManagement;
