export const newSite = {
  address: {
    loc: {
      coordinates: [],
      type: "",
    },
    line1: "",
    line2: "",
    city: "",
    countryCode: "1",
    countryShortName: "",
    continentCode: "NA",
    timezone: "",
  },
  code: "",
  active: true,
  status: "Setup In Progress",
  localNetworkRanges: [],
  workingHours: [
    {
      dayOfTheWeek: "t",
      index: 0,
    },
  ],
  biParameters: {},
  penetrationPercentage: 0,
  merakiHostPort: 0,
  merakiTags: [],
  totalRobotSquareFeet: 0,
  totalSquareFeet: 0,
  timeCompleted: {
    robots: {
      count: 0
    },
    chargers: {
      count: 0
    },
    devices: {
      count: 0
    },
    totals: [{
      siteTotals: [{
        checkpointRangeData: {
          totalUnits: 0,
          totalLines: 0,
          checkpointDate: ""
        },
        currentDayData: {
          totalUnits: 0,
          totalLines: 0
        },
        currentTotalUnits: 0,
        currentTotalLines: 0,
        updatedAt: ""
      }],
      createdAt: "",
      updatedAt: "",
      currentTotalLines: 0,
      currentTotalUnits: 0
    }],
    maxExecDateTimeProcessed: ""
  },
};

export const UpdateSiteRequestObject = {
  friendlyName: "",
  address: {
    loc: {
      coordinates: [],
      type: "",
    },
    line1: "",
    line2: "",
    city: "",
    countryCode: "1",
    countryShortName: "",
    continentCode: "NA",
    timezone: "",
  },
  databaseCluster: "",
  databaseName: "",
  ingestion: {
    enabled: false,
    eventHubName: "",
    eventHubNamespace: "",
    eventConsumerGroup: "",
    warehouseIdDisabledCollections: false,
    WarehouseLocalJobCollection: false,
    WarehouseLocalTaskCollection: false,
    WarehouseLocalTaskHistoryCollection: false,
    WarehouseLocalRobotHistoryCollection: false,
  },
  status: "",
  totalRobotSquareFeet: 0,
  totalSquareFeet: 0,
  code: "", // Netsuite Code
  siteConfigName: "", // Netsuite Site Config Name
  email: "", // Netsuite Contact Email
  integrations: [],
  biParameters: {
    host: "",
    datasetId: "",
  },
  onSiteDatabaseServerName: "", // Onsite VM Server Name
  onSiteDatabaseServerAddress: "", // Onsite VM Server IP
  merakiTags: [],
  timeCompleted: {
    robots: {
      count: 0
    },
    chargers: {
      count: 0
    },
    devices: {
      count: 0
    },
    totals: [{
      siteTotals: [{
        checkpointRangeData: {
          totalUnits: 0,
          totalLines: 0,
          checkpointDate: ""
        },
        currentDayData: {
          totalUnits: 0,
          totalLines: 0
        },
        currentTotalUnits: 0,
        currentTotalLines: 0,
        updatedAt: ""
      }],
      createdAt: "",
      updatedAt: "",
      currentTotalLines: 0,
      currentTotalUnits: 0
    }],
    maxExecDateTimeProcessed: ""
  },
  mongoAdx: {
    enabled: false,
    cluster: "",
    streamingEnabled: false
  },
  d2c: false,
  pipelines: false,
  realtime: false,
};
