import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { dispatch } from "../index";
import { calculateBotMetrics } from "../calculations/botCalculations";
import {
  calculateLookupReferences,
  getLookupValue,
  getLookupMetadata,
} from "../calculations/lookupReferences";
import { calculateOutputs } from "../calculations/outputCalculations";

const isValidNumber = (value) => {
  return typeof value === "number" && !isNaN(value) && isFinite(value);
};

const safeMultiply = (...factors) => {
  try {
    if (factors.some((f) => !isValidNumber(f))) return 0;
    return factors.reduce((acc, val) => acc * val, 1);
  } catch (error) {
    return 0;
  }
};

const inductDropoffLocationEntryMapping = {
  cornerOfPickSpace: "Corner of Pick Space",
  centerOfSpaceAlongEndCaps: "Center of Space along End Caps",
  centerOfSpaceAlongAislePickFaces: "Center of Space along Aisle Pick Faces",
  centerOfPickSpace: "Center of Pick Space",
};

const robotOriginMapping = {
  origin20_5: 'Origin 20.5"',
  origin22_5: 'Origin 22.5"',
  origin24_5: 'Origin 24.5"',
  origin26_5: 'Origin 26.5"',
  vectorFixedShelf: 'Vector Fixed Shelf',
  vectorLift_2x4: "Vector Lift (2'x4')",
  vectorLift_2x5: "Vector Lift (2'x5')",
  vectorLift_2x6: "Vector Lift (2'x6')"
};
// Mappings between API and current state
const mappings = {
  topLevel: {
    staticInput: "staticInputs",
  },
  userInput: {
    robotType: "robotOrigin",
    containersPerMission: "designVolumes.containersPerMission",
    linesPerStop: "designVolumes.linesPerStop",
    inductLoadTime: "designVolumes.inductLoadTime",
    dropOffUnloadTime: "designVolumes.dropOffUnloadTime",
    ordersPerDay: "designVolumes.ordersPerDay",
    linesPerDay: "designVolumes.linesPerDay",
    unitsPerDay: "designVolumes.unitsPerDay",
    taskTimePerLine: "designVolumes.taskTimePerLine",
  },
  staticInput: {
    autonomousLoadTimeTotal: "autonomousLoadTime",
    autonomousUnloadTimeTotal: "autonomousUnloadTime",
    pickerPFD: "pickerPfd",
    inductDropoffFTEUtilization: "inductDropoffFteUtilization",
    waitTaskTimeBasedOnPickDensity: "waitTaskTime",
    robotClusteringFactor: "robotClusteringFactor",
    pickingAvgJobPercentOfZone: "pickingAvgJobPercentOfZone",
    putawayAvgJobPercentOfZone: "putawayAvgJobPercentOfZone",
    putawayType: "putawayType",
    batchBot: "batchBot",
    inductToPickArea: "inductToPickArea",
    pickAreaToDropoffAdjustment: "pickAreaToDropoffAdjustment",
    dropoffQueueTotalTime: "dropoffQueueTotalTime",
    travelToInductDistance: "travelToInductDistance",
    inductQueueTotalTime: "inductQueueTotalTime",
    averageWalkingSpeed: "averageWalkingSpeed",
    designFactor: "designFactor"
  },
};

// Create inverse mappings for lookup
const inverseInductDropoffLocationEntryMapping = Object.entries(inductDropoffLocationEntryMapping)
  .reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

const inverseRobotOriginMapping = Object.entries(robotOriginMapping)
  .reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

// Convert API format to current state format
function convertToCurrentState(apiObject) {
  const currentState = { ...apiObject };

  // Top-level fields
  for (const [apiKey, stateKey] of Object.entries(mappings.topLevel)) {
    if (apiObject[apiKey] !== undefined) {
      currentState[stateKey] = apiObject[apiKey];
      delete currentState[apiKey];
    }
  }

  // User input fields
  if (currentState.userInput) {
    // Handle special mappings first
    if (currentState.userInput.robotType) {
      currentState.userInput.robotOrigin = inverseRobotOriginMapping[currentState.userInput.robotType];
      delete currentState.userInput.robotType;
    }
    
    if (currentState.userInput.inductDropoffLocationEntry) {
      currentState.userInput.inductDropoffLocationEntry = 
        inverseInductDropoffLocationEntryMapping[currentState.userInput.inductDropoffLocationEntry];
    }

    // Initialize designVolumes if it doesn't exist
    if (!currentState.userInput.designVolumes) {
      currentState.userInput.designVolumes = {};
    }

    // Map the design volume fields
    const designVolumeFields = [
      'containersPerMission',
      'linesPerStop',
      'inductLoadTime',
      'dropOffUnloadTime',
      'ordersPerDay',
      'linesPerDay',
      'unitsPerDay',
      'taskTimePerLine'
    ];

    designVolumeFields.forEach(field => {
      if (currentState.userInput[field] !== undefined) {
        currentState.userInput.designVolumes[field] = currentState.userInput[field];
        delete currentState.userInput[field];
      }
    });
  }

  // Static input fields
  if (currentState.staticInput) {
    const staticInputMappings = {
      autonomousLoadTimeTotal: 'autonomousLoadTime',
      autonomousUnloadTimeTotal: 'autonomousUnloadTime',
      pickerPFD: 'pickerPfd',
      inductDropoffFTEUtilization: 'inductDropoffFteUtilization',
      waitTaskTimeBasedOnPickDensity: 'waitTaskTime',
      robotClusteringFactor: 'robotClusteringFactor',
      pickingAvgJobPercentOfZone: 'pickingAvgJobPercentOfZone',
      putawayAvgJobPercentOfZone: 'putawayAvgJobPercentOfZone',
      putawayType: 'putawayType',
      batchBot: 'batchBot',
      inductToPickArea: 'inductToPickArea',
      pickAreaToDropoffAdjustment: 'pickAreaToDropoffAdjustment',
      dropoffQueueTotalTime: 'dropoffQueueTotalTime',
      travelToInductDistance: 'travelToInductDistance',
      inductQueueTotalTime: 'inductQueueTotalTime',
      averageWalkingSpeed: 'averageWalkingSpeed',
      designFactor: 'designFactor'
    };

    currentState.staticInputs = {};
    for (const [apiKey, stateKey] of Object.entries(staticInputMappings)) {
      if (currentState.staticInput[apiKey] !== undefined) {
        currentState.staticInputs[stateKey] = currentState.staticInput[apiKey];
      }
    }
    delete currentState.staticInput;
  }

  return currentState;
}

// Convert current state format to API format
function convertToApiFormat(currentState) {
  // Create a new object with only the necessary fields
  const apiObject = {
    siteName: currentState.siteName,
    userInput: {
      aisleWidth: currentState.userInput.aisleWidth,
      determinedPassingCapability: currentState.userInput.determinedPassingCapability,
      inductDropoffLocationEntry: currentState.userInput.inductDropoffLocationEntry,
      metricOrImperial: currentState.userInput.metricOrImperial,
      numberOfAislesInRobotArea: currentState.userInput.numberOfAislesInRobotArea,
      numberOfCutThroughsInRobotArea: currentState.userInput.numberOfCutThroughsInRobotArea,
      productiveHoursPerDayPicking: currentState.userInput.productiveHoursPerDayPicking,
      productiveHoursPerDayPutaway: currentState.userInput.productiveHoursPerDayPutaway,
      robotOrigin: currentState.userInput.robotOrigin,
      totalLengthOfRobotArea: currentState.userInput.totalLengthOfRobotArea,
      totalRobotArea: currentState.userInput.totalRobotArea,
      totalWidthOfRobotArea: currentState.userInput.totalWidthOfRobotArea
    },
    staticInput: {}
  };

  // Map robotOrigin to robotType
  if (currentState.userInput.robotOrigin) {
    apiObject.userInput.robotType = currentState.userInput.robotOrigin;
  }

  // Map design volumes
  if (currentState.userInput.designVolumes) {
    const designVolumes = currentState.userInput.designVolumes;
    const fieldsToMap = [
      'containersPerMission',
      'linesPerStop',
      'inductLoadTime',
      'dropOffUnloadTime',
      'ordersPerDay',
      'linesPerDay',
      'unitsPerDay',
      'taskTimePerLine'
    ];

    fieldsToMap.forEach(field => {
      if (designVolumes[field]) {
        apiObject.userInput[field] = designVolumes[field];
      }
    });
  }

  // Map all static inputs
  if (currentState.staticInputs) {
    const staticInputMappings = {
      autonomousLoadTime: 'autonomousLoadTimeTotal',
      autonomousUnloadTime: 'autonomousUnloadTimeTotal',
      pickerPfd: 'pickerPFD',
      inductDropoffFteUtilization: 'inductDropoffFTEUtilization',
      waitTaskTime: 'waitTaskTimeBasedOnPickDensity',
      robotClusteringFactor: 'robotClusteringFactor',
      pickingAvgJobPercentOfZone: 'pickingAvgJobPercentOfZone',
      putawayAvgJobPercentOfZone: 'putawayAvgJobPercentOfZone',
      putawayType: 'putawayType',
      batchBot: 'batchBot',
      inductToPickArea: 'inductToPickArea',
      pickAreaToDropoffAdjustment: 'pickAreaToDropoffAdjustment',
      dropoffQueueTotalTime: 'dropoffQueueTotalTime',
      travelToInductDistance: 'travelToInductDistance',
      inductQueueTotalTime: 'inductQueueTotalTime',
      averageWalkingSpeed: 'averageWalkingSpeed',
      designFactor: 'designFactor'
    };

    for (const [stateKey, apiKey] of Object.entries(staticInputMappings)) {
      if (currentState.staticInputs[stateKey] !== undefined) {
        // Special handling for batchBot to ensure boolean
        if (stateKey === 'batchBot') {
          apiObject.staticInput[apiKey] = Boolean(currentState.staticInputs[stateKey]);
          if (typeof currentState.staticInputs[stateKey] === 'string') {
            apiObject.staticInput[apiKey] = currentState.staticInputs[stateKey].toLowerCase() === 'true';
          }
        } else {
          apiObject.staticInput[apiKey] = currentState.staticInputs[stateKey];
        }
      }
    }
  }

  return apiObject;
}


// Add this near the top of the file, with the other helper functions
const findMatchingIndex = (searchValue, lookupArray) => {
  try {
    if (!Array.isArray(lookupArray) || !lookupArray.length) return -1;

    // Convert all values to numbers for comparison
    const numericSearchValue = Number(searchValue);
    if (isNaN(numericSearchValue)) return -1;

    // Check if search value is less than all values
    const isLessThanAll = lookupArray.every((value) => {
      const numericValue = Number(value);
      return !isNaN(numericValue) && numericSearchValue < numericValue;
    });

    if (isLessThanAll) {
      return -1;
    }

    // Find which range the search value falls into
    for (let i = 0; i < lookupArray.length; i++) {
      const currentValue = Number(lookupArray[i]);
      const nextValue =
        i < lookupArray.length - 1 ? Number(lookupArray[i + 1]) : Infinity;

      if (isNaN(currentValue)) continue;

      if (numericSearchValue >= currentValue) continue;
      else {
        return i;
      }

      // // If it's equal to or less than the first value
      // if (i === 0 && numericSearchValue <= currentValue) {
      //   return 1;
      // }

      // // If it's equal to current value (for non-first values)
      // if (i > 0 && numericSearchValue === currentValue) {
      //   return i + 1;
      // }

      // // If it's in between current and next value (exclusive of current, inclusive of next)
      // if (numericSearchValue > currentValue && numericSearchValue <= nextValue) {
      //   return i + 1;
      // }
    }

    // If it's greater than the last value
    return lookupArray.length;
  } catch (error) {
    console.log("Error in findMatchingIndex:", error);
    return -1;
  }
};

const flattenSiteActionLogs = (data) => {
  const flattenData = (item) => ({
    id: item.id,
    taskName: item.values?.taskType || "N/A",
    status: item?.values?.status || "N/A",
    submittedAt: item.createdAt || "N/A",
    completedAt: item.values?.completedAt || "N/A",
    updatedAt: item.updatedAt || "N/A",
    username: item.values?.user?.username || "N/A",
    taskId: item.values?.task || "N/A",
    scheduledAt: item?.values?.scheduledAt || "N/A",
    createdAt: item?.values?.createdAt,
    error: item?.values?.error || "N/A",
    arguments: item?.values?.arguments || "N/A",
  });
  return {
    data: data.logs?.rows.map(flattenData) || [],
    latestUpdate: data.latestUpdate,
  };
};

const flattenSiteActionOverview = (data) => {
  const flattenData = (item) => ({
    id: item.id,
    taskName: item.task?.name || "N/A",
    status: item.status,
    submittedAt: item.createdAt || "N/A",
    scheduledAt: item.scheduledAt || "N/A",
    completedAt: item.completedAt || "N/A",
    updatedAt: item.task?.updatedAt || "N/A",
    username: item.user?.username || "N/A",
    taskId: item.task?.id || "N/A",
    error: item?.error ? item.error.split("\r\n")[0] : "N/A",
    arguments: item?.arguments || "N/A",
  });
  return {
    currentQueue: data.currentQueue?.rows.map(flattenData) || [],
    recentlyCompleted: data.recentlyCompleted?.rows.map(flattenData) || [],
    latestUpdate: data.latestUpdate,
  };
};

// Add this function near the top of the file with other helper functions
const calculatePassingCapability = (userInput, lookupReference) => {
  try {
    const isImperial = userInput?.metricOrImperial === "Imperial";
    const robotOrigin = userInput?.robotOrigin || "";
    const aisleWidth = userInput?.aisleWidth || 0;
    const robotType = robotOrigin
      .split(" ")[0]
      .toLowerCase()
      .replace(/\s+/g, "");

    // Get the appropriate specs based on metric/imperial
    const specs = isImperial
      ? lookupReference?.robotSpecs?.imperial
      : lookupReference?.robotSpecs?.metric;

    let passingCapability = "Unable to Enter Aisle";

    if (specs && robotType) {
      const robotSpecs = specs[robotType];

      if (robotSpecs) {
        // Extract the passing capabilities (no pass, two wide, three wide)
        const passingWidths = [
          robotSpecs.noPass,
          robotSpecs.twoWide,
          robotSpecs.threeWide,
        ];

        // Find the appropriate passing capability based on aisle width
        const passingIndex = findMatchingIndex(aisleWidth, passingWidths);

        switch (passingIndex) {
          case 0:
            passingCapability = "unableToEnterAisle";
            break;
          case 1:
            passingCapability = "noPass";
            break;
          case 2:
            passingCapability = "twoWidePassing";
            break;
          case 3:
            passingCapability = "threeWidePassing";
            break;
          default:
            passingCapability = "unableToEnterAisle";
        }
      }
    }

    return passingCapability;
  } catch (error) {
    console.log("Error calculating passing capability:", error);
    return "Unable to Enter Aisle";
  }
};

// ----------------------------------------------------------------------

export const initialState = {
  error: null,
  tools: [],
  devices: {
    count: 0,
    rows: [],
  },
  selectedTool: {},
  robotsWithCarts: {},
  siteActionOverview: {
    currentQueue: [],
    recentlyCompleted: [],
    latestUpdate: {
      status: "New",
      updatedAt: "",
    },
  },
  siteActionLogs: {
    logs: {
      count: 0,
      rows: [],
      usingTest: false,
    },
    latestUpdate: {
      updatedAt: "",
      status: "New",
    },
  },
  integrationMonitoringLogs: { count: 0, rows: [] },
  unassignedJobs: {
    count: 0,
    rows: [],
  },
  botConfigurations: [], // List of bot configurations
  selectedBotCalculator: {
    botCalculations: {
      activePickTime: {
        picking: 0, // C132 =IFERROR(C112-C110+C115+C122,"")
        putaway: 0, // D132 =IFERROR(D112-D110+D115+D122,"")
      },
      activeRobotLphDesignVol: {
        picking: 0, // C145 =IFERROR(C102/(C133/3600),"")
        putaway: 0, // D145 =IFERROR(D102/(D133/3600),"")
      },
      activeRobotUphDesignVol: {
        picking: 0, // C146 =IFERROR(C145*C157,"")
        putaway: 0, // D146 =IFERROR(D145*D157,"")
      },
      activeRobots: {
        picking: 0, // C140 =IFERROR(C135/C138,"")
        putaway: 0, // D140 =IFERROR(D135/D138,"")
      },
      allRobotLphDesignVol: {
        picking: 0, // C148 =IFERROR(C147/C143,"")
        putaway: 0, // D148 =IFERROR(D147/D143,"")
      },
      allRobotUphDesignVol: {
        picking: 0, // C149 =IFERROR(C148*C157,"")
        putaway: 0, // D149 =IFERROR(D148*D157,"")
      },
      autonomousLoadTime: {
        picking: 0, // C105 =I16
        putaway: 0, // D105 =I16
      },
      autonomousUnloadTime: {
        picking: 0, // C128 =I20
        putaway: 0, // D128 =I20
      },
      availableRobots: {
        picking: 0, // C144 =C140*C139
        putaway: 0, // D144 =D140*D139
      },
      averageActivePickingPercentage: {
        picking: 0, // C134 =IFERROR(C132/C133,"")
        putaway: 0, // D134 =IFERROR(D132/D133,"")
      },
      averageAvailableTime: {
        picking: 0, // C139 =IFERROR((C109+IF(C100=FALSE,C112+C122,C110))/C133,"")
        putaway: 0, // D139 =IFERROR((D109+IF(D100=FALSE,D112+D122,D110))/D133,"")
      },
      averageMissionTimeMin: {
        picking: 0, // C137 =IFERROR(C133/60,"")
        putaway: 0, // D137 =IFERROR(D133/60,"")
      },
      averageMissionTimeSec: {
        picking: 0, // C133 =IFERROR(IF(C105>0,C105,C104)+C109+C112+C115+C122+C125+C126+IF(C128>0,C128,C127)+C130+C131,"")
        putaway: 0, // D133 =IFERROR(IF(D105>0,D105,D104)+D109+D112+D115+D122+D125+D126+IF(D128>0,D128,D127)+D130+D131,"")
      },
      averageRobotMissionDistance: {
        picking: 0, // C136 =IFERROR(C108+(C119*C121)+C124+C129,"")
        putaway: 0, // D136 =IFERROR(D108+(D119*D121)+D124+D129,"")
      },
      avgJobPercentageOfZone: {
        picking: 0, // C117 =I10
        putaway: 0, // D117 =I11
      },
      avgTasksPerAisle: {
        picking: 0, // C116 =IFERROR((C102/C103)/(I10*C15),"")
        putaway: 0, // D116 =IFERROR((D102/D103)/(I11*C15),"")
        },
      batchBot: {
        picking: "FALSE", // C100 =I15
        putaway: "FALSE", // D100 =I15
      },
      chargingBuffer: {
        picking: 0, // C141 =IFERROR(XLOOKUP(C23,B41:B48,D41:D48,"",0),"")
        putaway: 0, // D141 =IFERROR(XLOOKUP(C23,B41:B48,D41:D48,"",0),"")
      },
      chargingRobots: {
        picking: 0, // C142 =C141*C140
        putaway: 0, // D142 =D141*D140
      },
      designDirectFtes: {
        picking: 0, // C162 =IFERROR(C161/C158,"")
        putaway: 0, // D162 =IFERROR(D161/D158,"")
      },
      designDropOffOperators: {
        picking: 0, // C165 =IFERROR((((C135*C127/60)/I25)/60),"")
        putaway: 0, // D165 =IFERROR((((D135*D127/60)/I25)/60),"")
      },
      designIndirectFtes: {
        picking: 0, // C163 =IFERROR(C164+C165,"")
        putaway: 0, // D163 =IFERROR(D164+D165,"")
      },
      designInductOperators: {
        picking: 0, // C164 =IFERROR((((C135*C104/60)/I25)/60),"")
        putaway: 0, // D164 =IFERROR((D135*D104/60)/L25/60*I25,"")
      },
      designLinesPerHour: {
        picking: 0, // C147 =IFERROR(C33/C21,"")
        putaway: 0, // D147 =IFERROR(D33/C22,"")
      },
      designVolumeUnitsPerHour: {
        picking: 0, // C159 =IFERROR(C34/C21,"")
        putaway: 0, // D159 =IFERROR(D34/C22,"")
      },
      designVolumeWithFactorUnitsPerHour: {
        picking: 0, // C161 =IFERROR(C159*I24,"")
        putaway: 0, // D161 =IFERROR(D159*I24,"")
      },
      dropoffQueueTotalTime: {
        picking: 0, // C126 =I19
        putaway: 0, // D126 =I19
      },
      dropoffTotalTime: {
        picking: 0, // C127 =C31
        putaway: 0, // D127 =D31
      },
      inductDropoffLocationEntry: {
        picking: "", // C106 =C18
        putaway: "", // D106 =C18
      },
      inductQueueTotalTime: {
        picking: 0, // C131 =IFERROR(IF(C130>0,I22,0),"")
        putaway: 0, // D131 =IFERROR(IF(D130>0,I22,0),"")
      },
      inductToPickArea: {
        picking: 0, // C107 =IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),"")
        putaway: 0, // D107 =IFERROR(IF(C11="Imperial",I17,CONVERT(I17,"ft","m")),"")
      },
      inductTotalTime: {
        picking: 0, // C104 =C30
        putaway: 0, // D104 =D30
      },
      linesPerCycle: {
        picking: 0, // C154 =IFERROR(IF(C152="Location",C103,C102),"")
        putaway: 0, // D154 =IFERROR(IF(D152="Location",D103,D102),"")
      },
      linesPerMission: {
        picking: 0, // C102 =IFERROR(IF(C33/C32)*C28,"")
        putaway: 0, // D102 =IFERROR(IF(D33/D32)*D28,"")
      },
      linesPerStop: {
        picking: 0, // C103 =C29
        putaway: 0, // D103 =D29
      },
      lph: {
        picking: 0, // C156 =IFERROR((3600/(C155/C154))*I14,"")
        putaway: 0, // D156 =IFERROR((3600/(D155/D154))*I14,"")
      },
      navigationFactor: {
        picking: 0, // C118 =XLOOKUP(C24,B61:B64,C61:C64,"",0)
        putaway: 0, // D118 =XLOOKUP(C24,B61:B64,C61:C64,"",0)
      },
      pickAreaToDropoffAdjustment: {
        picking: 0, // C123 =IFERROR(IF(C11="Imperial",I18,CONVERT(I18,"ft","m")),"")
        putaway: 0, // D123 =IFERROR(IF(C11="Imperial",I18,CONVERT(I18,"ft","m")),"")
      },
      pickLineQty: {
        picking: 0, // C114 =C102
        putaway: 0, // D114 =D102
      },
      pickLineTime: {
        picking: 0, // C113 =C35
        putaway: 0, // D113 =D35
      },
      pickLineTotalTime: {
        picking: 0, // C115 =IFERROR(C113*C114,"")
        putaway: 0, // D115 =IFERROR(D113*D114,"")
      },
      repeatedAction: {
        picking: "Location", // C152 =IFERROR(IF(C100=TRUE,"Task List",IF(C100=FALSE,"Location")),"")
        putaway: "Location", // D152 =IFERROR(IF(D100=TRUE,"Task List",IF(D100=FALSE,"Location")),"")
      },
      repeatedActionSeconds: {
        picking: 0, // C153 =IFERROR(IF(C152="Location",C113*C103,C132),"")
        putaway: 0, // D153 =IFERROR(IF(D152="Location",D113*D103,D132),"")
      },
      robot: {
        picking: "Origin", // C99 =TEXTBEFORE(C23," ",1)
        putaway: "Origin", // D99 =TEXTBEFORE(C23," ",1)
      },
      robotJobsPerHour: {
        picking: 0, // C138 =IFERROR(60/C137,"")
        putaway: 0, // D138 =IFERROR(60/D137,"")
      },
      robotSpeed: {
        picking: 0, // C101 =IF(C11="Imperial",XLOOKUP(C23,B41:B48,C41:C48,"",0),XLOOKUP(C23,B51:B58,C51:C58,"",0))
        putaway: 0, // D101 =IF(C11="Imperial",XLOOKUP(C23,B41:B48,C41:C48,"",0),XLOOKUP(C23,B51:B58,C51:C58,"",0))
      },
      timePerCycleSeconds: {
        picking: 0, // C155 =IFERROR(C153+C151,"")
        putaway: 0, // D155 =IFERROR(D153+D151,"")
      },
      totalDesignFtes: {
        picking: 0, // C160 =IFERROR(C162+C163,"")
        putaway: 0, // D160 =IFERROR(D162+D163,"")
      },
      totalJobsPerHourRequired: {
        picking: 0, // C135 =IFERROR((C33/C102)/C21,"")
        putaway: 0, // D135 =IFERROR((D33/D102)/C22,"")
      },
      totalRobots: {
        picking: 0, // C143 =C140+C142
        putaway: 0, // D143 =D140+D142
      },
      travelToDropoffDistance: {
        picking: 0, // C124 =IFERROR(XLOOKUP(C106,B77:B80,C77:C80,"",0)+C123,"")
        putaway: 0, // D124 =IFERROR(XLOOKUP(D106,B77:B80,C77:C80,"",0)+D123,"")
      },
      travelToDropoffTotalTime: {
        picking: 0, // C125 =C124/C101
        putaway: 0, // D125 =D124/D101
      },
      travelToFirstPickDistance: {
        picking: 0, // C108 =IFERROR(XLOOKUP(C106,B77:B80,C77:C80,"",0)+C107,"")
        putaway: 0, // D108 =IFERROR(XLOOKUP(D106,B77:B80,C77:C80,"",0)+D107,"")
      },
      travelToFirstPickTime: {
        picking: 0, // C109 =IFERROR(C108/C101,"")
        putaway: 0, // D109 =IFERROR(D108/D101,"")
      },
      travelToInductDistance: {
        picking: 0, // C129 =IFERROR(IF(C11="Imperial",I21,CONVERT(I21,"ft","m")),"")
        putaway: 0, // D129 =IFERROR(IF(C11="Imperial",I21,CONVERT(I21,"ft","m")),"")
      },
      travelToInductTotalTime: {
        picking: 0, // C130 =IFERROR(C129/C101,"")
        putaway: 0, // D130 =IFERROR(D129/D101,"")
      },
      travelToNextPickDistance: {
        picking: 0, // C119 =IFERROR((MIN(XLOOKUP(C17,B67:B73,C67:C73)*C13,(C13/C116))+(C12*C117*(1/C114)))*C118,"")
        putaway: 0, // D119 =IFERROR((MIN(XLOOKUP(C17,B67:B73,C67:C73)*C13,(C13/D116))+(C12*D117*(1/D114)))*D118,"")
      },
      travelToNextPickQty: {
        picking: 0, // C121 =IFERROR((C102/C103)-1,"")
        putaway: 0, // D121 =IFERROR((D102/D103)-1,"")
      },
      travelToNextPickTime: {
        picking: 0, // C120 =IFERROR(C119/C101,"")
        putaway: 0, // D120 =IFERROR(D119/D101,"")
      },
      travelToNextPickTotalTime: {
        picking: 0, // C122 =IFERROR(C121*C120,"")
        putaway: 0, // D122 =IFERROR(D121*D120,"")
      },
      unitsPerLine: {
        picking: 0, // C157 =IFERROR(C34/C33,"")
        putaway: 0, // D157 =IFERROR(D34/D33,"")
      },
      uph: {
        picking: 0, // C158 =IFERROR(C156*C157,"")
        putaway: 0, // D158 =IFERROR(D156*D157,"")
      },
      waitForFteQty: {
        picking: 0, // C111 =C102
        putaway: 0, // D111 =D102
      },
      waitForFteTime: {
        picking: 0, // C110 =I12
        putaway: 0, // D110 =I12
      },
      waitForFteTotalTime: {
        picking: 0, // C112 =IFERROR(C110*C111,"")
        putaway: 0, // D112 =IFERROR(D110*D111,"")
      },
      walkToNewBotSeconds: {
        picking: 0, // C151 =IFERROR(C150/I23,"")
        putaway: 0, // D151 =IFERROR(D150/I23,"")
      },
      walkToNewRobotDistance: {
        picking: 0, // C150 =IF(C143="",C95,E95)
        putaway: 0, // D150 =IF(D143="",D95,E95)
      },
      // ... continue with remaining calculations ...
    },
    lookupReference: {
      navFactor: {
        unableToEnterAisle: 1.5, // C61: Unable to Enter Aisle
        noPass: 1.3, // D61: No Pass
        twoWidePassing: 1.05, // E61: 2-Wide Passing
        threeWidePassing: 1.025, // F61: 3-Wide Passing
      },
      cutThroughEffectiveness: [
        {
          cutThroughs: 0, // B67
          percentOfAisle: 0.5, // C67
          endCap: 0, // D67: =((C13/(B67+1))/2)+((C13/(B67+1))/2)+(C12*0.5)
        },
        {
          cutThroughs: 1, // B68
          percentOfAisle: 0.45, // C68
          endCap: 0, // D68: =((C13/(B68+1))/2)+(C12*0.5)
        },
        {
          cutThroughs: 2, // B69
          percentOfAisle: 0.4, // C69
          endCap: 0, // D69: =((C13/(B69+1))/2)+((C13/(B69+1))/2)+(C12*0.5)
        },
        {
          cutThroughs: 3, // B70
          percentOfAisle: 0.38, // C70
          endCap: 0, // D70: =(C13*0.25)+(C12*0.5)
        },
        {
          cutThroughs: 4, // B71
          percentOfAisle: 0.37, // C71
          endCap: 0, // D71: =(C13*0.1)+(C13*0.18)+(C12*0.5)
        },
        {
          cutThroughs: 5, // B72
          percentOfAisle: 0.36, // C72
          endCap: 0, // D72: =(C13*0.25)+(C12*0.5)
        },
        {
          cutThroughs: 6, // B73
          percentOfAisle: 0.35, // C73
          endCap: 0, // D73: =(C13*0.071)+(C13*0.1939)+(C12*0.5)
        },
        {
          cutThroughs: 2, // B74
          percentOfAisle: null, // C74
          endCap: 0, // D74: =AVERAGE(XLOOKUP(ROUNDDOWN(C17,0),B67:B73,D67:D73),XLOOKUP(ROUNDUP(C17,0),B67:B73,D67:D73))
        }
      ],
      distRef: {
        cornerOfPickSpace: 0, // C77: =IFERROR((0.5*C12)+(0.5*C13),"")
        centerOfSpaceAlongEndCaps: 0, // C78: =IFERROR((0.5*C13)+(0.25*C12),"")
        centerOfSpaceAlongAislePickFaces: 0, // C79: =IFERROR(XLOOKUP(IF(C17>6,6,C17),B67:B74,D67:D74),"")
        centerOfPickSpace: 0, // C80: =IFERROR((0.25*C13)+(0.25*C12),"")
      },
      pickDensity: [
        {
          lowerBounds: null,  // B83
          upperBounds: 1,     // C83
          dwellTime: 120,     // D83
          isActive: false, // E83 =IF(((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))<C83,"Active","")
        },
        {
          lowerBounds: 1,     // B84
          upperBounds: 3,     // C84
          dwellTime: 90,      // D84
          isActive: false, // =IF(AND(((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))>=B84,((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))<C84),"Active","")
        },
        {
          lowerBounds: 3,     // B85
          upperBounds: 10,    // C85
          dwellTime: 75,      // D85
          isActive: false, // E85 =IF(AND(((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))>=B85,((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))<C85),"Active","")
        },
        {
          lowerBounds: 10,    // B86
          upperBounds: 20,    // C86
          dwellTime: 60,      // D86
          isActive: false, // E86 =IF(AND(((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))>=B86,((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))<C86),"Active","")
        },
        {
          lowerBounds: 20,    // B87
          upperBounds: null,  // C87
          dwellTime: 45,      // D87
          isActive: false, // E87 =IF(((SUM(C33:D33)/(MAX(C21:C22)))/(C14/1000))>=B87,"Active","")
        }
      ],
      robotSpecs: {
        imperial: {
          origin20_5: {
            name: 'Origin 20.5"', // B41
            avgSpeed: 3, // C41
            buffer: 0.1, // D41
            noPass: 3.5, // E41
            twoWide: 5.3333333333333, // F41
            threeWide: 8, // G41
          },
          origin22_5: {
            name: 'Origin 22.5"', // B42
            avgSpeed: 3, // C42
            buffer: 0.1, // D42
            noPass: 3.5, // E42
            twoWide: 5.5833333333333, // F42
            threeWide: 8.4166666666667, // G42
          },
          origin24_5: {
            name: 'Origin 24.5"', // B43
            avgSpeed: 3, // C43
            buffer: 0.1, // D43
            noPass: 3.5, // E43
            twoWide: 5.9166666666667, // F43
            threeWide: 8.8333333333333, // G43
          },
          origin26_5: {
            name: 'Origin 26.5"', // B44
            avgSpeed: 3, // C44
            buffer: 0.1, // D44
            noPass: 3.5, // E44
            twoWide: 6.1666666666667, // F44
            threeWide: 9.25, // G44
          },
          vectorFixedShelf: {
            name: "Vector Fixed Shelf", // B45
            avgSpeed: 2.5, // C45
            buffer: 0.15, // D45
            noPass: 4.0833333333333, // E45
            twoWide: 8.1666666666667, // F45
            threeWide: 12.3333333333333, // G45
          },
          vectorLift_2x4: {
            name: "Vector Lift (2'x4')", // B46
            avgSpeed: 2.5, // C46
            buffer: 0.15, // D46
            noPass: 4.3333333333333, // E46
            twoWide: 8.5, // F46
            threeWide: 12.6666666666667, // G46
          },
          vectorLift_2x5: {
            name: "Vector Lift (2'x5')", // B47
            avgSpeed: 2.5, // C47
            buffer: 0.15, // D47
            noPass: 4.3333333333333, // E47
            twoWide: 8.6666666666667, // F47
            threeWide: 13.0833333333333, // G47
          },
          vectorLift_2x6: {
            name: "Vector Lift (2'x6')", // B48
            avgSpeed: 2.5, // C48
            buffer: 0.15, // D48
            noPass: 4.4166666666667, // E48
            twoWide: 8.6666666666667, // F48
            threeWide: 13, // G48
          },
        },
        metric: {
          origin20_5: {
            name: 'Origin 20.5"', // B51
            avgSpeed: 0.9144, // C51
            buffer: 0.1, // D51
            noPass: 1.0668, // E51
            twoWide: 1.6256, // F51
            threeWide: 2.4384, // G51
          },
          origin22_5: {
            name: 'Origin 22.5"', // B52
            avgSpeed: 0.9144, // C52
            buffer: 0.1, // D52
            noPass: 1.0668, // E52
            twoWide: 1.7018, // F52
            threeWide: 2.5654, // G52
          },
          origin24_5: {
            name: 'Origin 24.5"', // B53
            avgSpeed: 0.9144, // C53
            buffer: 0.1, // D53
            noPass: 1.0668, // E53
            twoWide: 1.8034, // F53
            threeWide: 2.6924, // G53
          },
          origin26_5: {
            name: 'Origin 26.5"', // B54
            avgSpeed: 0.9144, // C54
            buffer: 0.1, // D54
            noPass: 1.0668, // E54
            twoWide: 1.8796, // F54
            threeWide: 2.8194, // G54
          },
          vectorFixedShelf: {
            name: "Vector Fixed Shelf", // B55
            avgSpeed: 0.762, // C55
            buffer: 0.15, // D55
            noPass: 1.2446, // E55
            twoWide: 2.4892, // F55
            threeWide: 3.7592, // G55
          },
          vectorLift_2x4: {
            name: "Vector Lift (2'x4')", // B56
            avgSpeed: 0.762, // C56
            buffer: 0.15, // D56
            noPass: 1.3208, // E56
            twoWide: 2.5908, // F56
            threeWide: 3.8608, // G56
          },
          vectorLift_2x5: {
            name: "Vector Lift (2'x5')", // B57
            avgSpeed: 0.762, // C57
            buffer: 0.15, // D57
            noPass: 1.3208, // E57
            twoWide: 2.6416, // F57
            threeWide: 3.9878, // G57
          },
          vectorLift_2x6: {
            name: "Vector Lift (2'x6')", // B58
            avgSpeed: 0.762, // C58
            buffer: 0.15, // D58
            noPass: 1.3462, // E58
            twoWide: 2.6416, // F58
            threeWide: 3.9624, // G58
          },
        },
      },
      walkDistance: {
        availableRobots: {
          picking: 0, // C90: =C144
          putaway: 0, // D90: =D144
          concurrent: 0 // E90: =C90+D90
        },
        areaPerAvailableBot: {
          picking: 0, // C91: =IFERROR(C14/C90,"")
          putaway: 0, // D91: =IFERROR(C14/D90,"")
          concurrent: 0 // E91: =IFERROR(C14/E90,"")
        },
        xPerRobot: {
          picking: 0, // C92: =IFERROR(SQRT(C91)/(SQRT(C13/C12)),"")
          putaway: 0, // D92: =IFERROR(SQRT(D91)/(SQRT(C13/C12)),"")
          concurrent: 0 // E92: =IFERROR(SQRT(E91)/(SQRT(C13/C12)),"")
        },
        yPerRobot: {
          picking: 0, // C93: =IFERROR(SQRT(C91)/(SQRT(C12/C13)),"")
          putaway: 0, // D93: =IFERROR(SQRT(D91)/(SQRT(C12/C13)),"")
          concurrent: 0 // E93: =IFERROR(SQRT(E91)/(SQRT(C12/C13)),"")
        },
        walkDistanceWithoutCluster: {
          picking: 0, // C94: =IFERROR(AVERAGE(C92+C93,SQRT(C92^2+C93^2)),"")
          putaway: 0, // D94: =IFERROR(AVERAGE(D92+D93,SQRT(D92^2+D93^2)),"")
          concurrent: 0 // E94: =IFERROR(AVERAGE(E92+E93,SQRT(E92^2+E93^2)),"")
        },
        walkDistanceWithCluster: {
          picking: 0, // C95: =IFERROR(C94*I9,"")
          putaway: 0, // D95: =IFERROR(D94*I9,"")
          concurrent: 0 // E95: =IFERROR(E94*I9,"")
        }
      },
    },
    outputs: {
      concurrentSummary: {
        designDemandVolume: {
          pickingOrdersPerHour: "0", // Y12 =C32/C21
          pickingLinesPerHour: "0", // Y13 =C33/C21
          pickingUnitsPerHour: "0", // Y14 =C34/C21
          putawayContainersPerHour: "0", // Y15 =D32/C22
          putawayLinesPerHour: "0", // Y16 =D33/C22
          putawayUnitsPerHour: "0", // Y17 =D33/C22
        },
        ops: {
          productivePickingHoursPerDay: "0.0 hrs", // Y9 =C21
          productivePutawayHoursPerDay: "0.0 hrs", // Y10 =C22
        },
        pickingBotMetrics: {
          numberOfPickingBots: "0.0", // Y19 =C143
          robotPickingLph: "0", // Y20 =C148
          robotPickingUph: "0", // Y21 =C149
          avgRobotMissionMin: "00:00", // Y22 =C137/1440
        },
        pickingMetrics: {
          purePickLph: "0", // Y36 =C156
          purePickUph: "0", // Y37 =C158
          totalFteLph: "0", // Y38 =(C161/C160)/C157
          totalFteUph: "0", // Y39 =C161/C160
        },
        putawayBotMetrics: {
          numberOfPutawayBots: "0.0", // Y24 =D143
          robotPutawayLph: "0", // Y25 =D148
          robotPutawayUph: "0", // Y26 =D149
          avgRobotMissionMin: "00:00", // Y27 =D137/1440
        },
        putawayMetrics: {
          purePutLph: "0", // Y41 =D156
          purePutUph: "0", // Y42 =D158
          totalFteLph: "0", // Y43 =(D161/D160)/D157
          totalFteUph: "0", // Y44 =D161/D160
        },
        staffing: {
          numberOfDirectFtes: "0.0", // Y31 =SUM(C162:D162)
          numberOfIndirectPickingFtes: "0.0", // Y32 =C163
          numberOfIndirectPutawayFtes: "0.0", // Y33 =D163
          totalFtes: "0.0", // Y34 =SUM(Y31:Y33)
        },
        systemMetrics: {
          robotToPickerRatio: "0:1", // Y46 =ROUND((C143+D143)/(C162+D162),1)&":"&1
          robotToFteRatio: "0:1", // Y47 =ROUND((C143+D143)/(C160+D160),1)&":"&1
          pickArea: "0", // Y48 =C14
          areaPerBot: "0", // Y49 =C14/ROUNDUP(C143+D143,0)
          areaPerPicker: "0", // Y50 =C14/(C162+D162)
        },
        total: {
          totalNumberOfRobots: "0", // Y29 =ROUNDUP(C143+D143,0)
        },
      },
      pickingBotMissionWorkflow: {
        summaryMetrics: {
          avgActivePickingTime: 0, // L51 =SUM(Q43:Q46)/SUM(Q40:Q49)
          totalJobsPerHourRequired: 0, // L52 =C135
          avgRobotMissionDistance: 0, // L53 =C136
          avgJobTimeMin: 0, // Q51 =SUM(Q40:Q49)/60
          robotJobsPerHour: 0, // Q52 =60/Q51
          avgAvailableTime: 0, // Q53 =C139
        },
        workflow: {
          inductTote: {
            distance: null, // J40
            time: 0, // M40 =C104
            multiplier: 1, // O40
            totalTime: 0, // Q40 =M40*O40
          },
          travelToFirstPick: {
            distance: 0, // L41 =C108
            time: 0, // M41 =C109
            multiplier: 1, // O41
            totalTime: 0, // Q41 =M41*O41
          },
          waitForPicker: {
            distance: null, // J42
            time: 0, // M42 =C110
            multiplier: 1, // O42
            totalTime: 0, // Q42 =M42*O42
          },
          pickLineItems: {
            distance: null, // J43
            time: 0, // M43 =C113
            multiplier: 1, // O43
            totalTime: 0, // Q43 =M43*O43
          },
          travelToNextPick: {
            distance: 0, // L44 =C119
            time: 0, // M44 =C120
            multiplier: 0, // O44 =C121
            totalTime: 0, // Q44 =M44*O44
          },
          secondWaitForPicker: {
            distance: null, // J45
            time: 0, // M45 =C110
            multiplier: 0, // O45
            totalTime: 0, // Q45 =M45*O45
          },
          secondPickLineItems: {
            distance: null, // J46
            time: 0, // M46 =C113
            multiplier: 0, // O46
            totalTime: 0, // Q46 =M46*O46
          },
          travelToDropoff: {
            distance: 0, // L47 =C124
            time: 0, // M47 =C125
            multiplier: 1, // O47
            totalTime: 0, // Q47 =M47*O47
          },
          dropoffQueue: {
            distance: null, // J48
            time: 0, // M48 =C126
            multiplier: 1, // O48
            totalTime: 0, // Q48 =M48*O48
          },
          removeTote: {
            distance: null, // J49
            time: 0, // M49 =C127
            multiplier: 1, // O49
            totalTime: 0, // Q49 =M49*O49
          },
        },
      },
      pickingOnlySummary: {
        botMetrics: {
          //
          numberOfRobots: "0", // O19 =ROUNDUP(C143,-0.1)
          robotLph: "0.00", // O20 =C148
          robotUph: "0.00", // O21 =C149
          avgRobotMissionMin: "00:00", // O22 =C137/1440
        },
        designDemandVolume: {
          ordersPerDay: "0", // O12 =C32
          linesPerDay: "0", // O13 =C34
          unitsPerDay: "0", // O14 =C33
          ordersPerHour: "0", // O15 =C32/C21
          linesPerHour: "0", // O16 =C33/C21
          unitsPerHour: "0", // O17 =C34/C21
        },
        ops: {
          productiveHoursPerDay: "0.0 hrs", // O9 =C32
        },
        pickingMetrics: {
          numberOfPickers: "0.0", // O24 =C162
          inductDropoffFtes: "0.0", // O25 =C163
          totalFtes: "0.0", // O26 =C160
          purePickLph: "0", // O27 =C156
          purePickUph: "0", // O28 =C158
          totalFteLph: "0", // O29 =(C161/C160)/C157
          totalFteUph: "0", // O30 =C161/C160
        },
        systemMetrics: {
          robotToPickerRatio: "0:1", // O32 =ROUND(C143/C162,1)&":"&1
          robotToFteRatio: "0:1", // O33 =ROUND(C143/C160,1)&":"&1
          pickArea: "0", // O34 =C14
          areaPerBot: "0", // O35 =C14/ROUNDUP(C143,0)
          areaPerPicker: "0", // O36 =C14/C162
        },
      },
      putawayBotMissionWorkflow: {
        summaryMetrics: {
          avgActivePickingTime: "0.0%", // M68 =SUM(Q60:Q63)/SUM(Q57:Q66)
          totalJobsPerHourRequired: "0", // M69 =D135
          avgRobotMissionDistance: "0", // M70 =D136
          avgJobTimeMin: "0", // Q68 =SUM(Q57:Q66)/60
          robotJobsPerHour: "0", // Q69 =60/Q68
          avgAvailableTime: "0.0%", // Q70 =D139
        },
        workflow: {
          inductTote: {
            distance: null, // J57
            time: 0, // M57 =D104
            multiplier: 1, // O57
            totalTime: 0, // Q57 =M57*O57
          },
          travelToFirstPick: {
            distance: 0, // L58 =C125
            time: 0, // M58 =D109
            multiplier: 1, // O58
            totalTime: 0, // Q58 =M58*O58
          },
          waitForPicker: {
            distance: null, // J59
            time: 0, // M59 =D110
            multiplier: 1, // O59
            totalTime: 0, // Q59 =M59*O59
          },
          pickLineItems: {
            distance: null, // J60
            time: 0, // M60 =D113
            multiplier: 1, // O60
            totalTime: 0, // Q60 =M60*O60
          },
          travelToNextPick: {
            distance: 0, // L61 =D119
            time: 0, // M61 =D120
            multiplier: 0, // O61 =D121
            totalTime: 0, // Q61 =M61*O61
          },
          secondWaitForPicker: {
            distance: null, // J62
            time: 0, // M62 =D110
            multiplier: 0, // O62 =D121
            totalTime: 0, // Q62 =M62*O62
          },
          secondPickLineItems: {
            distance: null, // J63
            time: 0, // M63 =D113
            multiplier: 0, // O63 =D121
            totalTime: 0, // Q63 =M63*O63
          },
          travelToDropoff: {
            distance: 0, // L64 =D124
            time: 0, // M64 =D125
            multiplier: 1, // O64
            totalTime: 0, // Q64 =M64*O64
          },
          dropoffQueue: {
            distance: null, // J65
            time: 0, // M65 =D126
            multiplier: 1, // O65
            totalTime: 0, // Q65 =M65*O65
          },
          removeTote: {
            distance: null, // J66
            time: 0, // M66 =D127
            multiplier: 1, // O66
            totalTime: 0, // Q66 =M66*O66
          },
        },
      },
      putawayOnlySummary: {
        botMetrics: {
          numberOfRobots: "0", // T19 =ROUNDUP(D143,-0.1)
          robotLph: "0.00", // T20 =D148
          robotUph: "0.00", // T21 =D149
          avgRobotMissionMin: "00:00", // T22 =D137/1440
        },
        designDemandVolume: {
          containersPerDay: "0", // T12 =D32
          linesPerDay: "0", // T13 =D33
          unitsPerDay: "0", // T14 =D34
          containersPerHour: "0", // T15 =D32/C22
          linesPerHour: "0", // T16 =D33/C22
          unitsPerHour: "0", // T17 =D34/C22
        },
        ops: {
          productiveHoursPerDay: "0.0 hrs", // T9 =C22
        },
        putawayMetrics: {
          numberOfPickers: "0.0", // T24 =D162
          inductDropoffFtes: "0.0", // T25 =D163
          totalFtes: "0.0", // T26 =D160
          purePutLph: "0", // T27 =D156
          purePutUph: "0", // T28 =D158
          totalFteLph: "0", // T29 =(D161/D160)/D157
          totalFteUph: "0", // T30 =D161/D160
        },
        systemMetrics: {
          robotToPickerRatio: "0:1", // T32 =ROUND(D143/D162,1)&":"&1
          robotToFteRatio: "0:1", // T33 =ROUND(D143/D160,1)&":"&1
          pickArea: "0", // T34 =C14
          areaPerBot: "0", // T35 =C14/ROUNDUP(D143,0)
          areaPerPicker: "0", // T36 =C14/D162
        },
      },
    },
    siteName: "",
    staticInputs: {
      autonomousLoadTime: 0, // I9
      autonomousUnloadTime: 0, // I10
      averageWalkingSpeed: 3.8, // I11
      batchBot: "FALSE", // I12
      designFactor: 1, // I13
      dropoffQueueTotalTime: 60, // I14
      inductDropoffFteUtilization: 0.85, // I15
      inductQueueTotalTime: 0, // I16
      inductToPickArea: 20, // I17
      pickAreaToDropoffAdjustment: 20, // I18
      pickerPfd: 1, // I19
      pickingAvgJobPercentOfZone: 0.65, // I20
      putawayAvgJobPercentOfZone: 0.4, // I21
      putawayType: "Interleaved", // I22
      robotClusteringFactor: 0.75, // I23 =IFERROR(IF(C11="Imperial",3.8,1.16),"")
      travelToInductDistance: 0, // I24
      waitTaskTime: 45, // I25 =XLOOKUP("Active",E83:E87,D83:D87,"",0)
    },
    userInput: {
      aisleWidth: 0, // C11
      designVolumes: {
        containersPerMission: {
          picking: 0, // C28
          putaway: 0, // D28
        },
        linesPerStop: {
          picking: 0, // C29
          putaway: 0, // D29
        },
        inductLoadTime: {
          picking: 0, // C30
          putaway: 0, // D30
        },
        dropOffUnloadTime: {
          picking: 0, // C31
          putaway: 0, // D31
        },
        ordersPerDay: {
          picking: 0, // C32
          putaway: 0, // D32
        },
        linesPerDay: {
          picking: 0, // C33
          putaway: 0, // D33
        },
        unitsPerDay: {
          picking: 0, // C34
          putaway: 0, // D34
        },
        taskTimePerLine: {
          picking: 0, // C35
          putaway: 0, // D35
        },
      }, // C12
      determinedPassingCapability: "Unable to Enter Aisle", // C13
      inductDropoffLocationEntry: "centerOfSpaceAlongEndCaps", // C14
      metricOrImperial: "Imperial", // C15
      numberOfAislesInRobotArea: 0, // C16
      numberOfCutThroughsInRobotArea: 0, // C17
      productiveHoursPerDayPicking: 0, // C18
      productiveHoursPerDayPutaway: 0, // C21
      robotOrigin: "", // C22
      totalLengthOfRobotArea: 0, // C23
      totalRobotArea: 0, // C24 =IFERROR(IF(C11="Imperial",INDEX(E40:G40,0,MATCH(C16,XLOOKUP(C23,B40:B48,E40:G48,"",0),1)),INDEX(E50:G50,0,MATCH(C16,XLOOKUP(C23,B50:B58,E50:G58,"",0),1))),"Unable to Enter Aisle")
      totalWidthOfRobotArea: 0,
    },
    version: "",
  },
};

const slice = createSlice({
  name: "tool",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getToolsSuccess(state, action) {
      state.tools = action.payload;
    },
    getSelectedToolsSuccess(state, action) {
      state.selectedTool = action.payload;
    },
    getRobotsWithCartsSuccess(state, action) {
      state.robotsWithCarts = action.payload;
    },
    getSiteActionOverviewSuccess(state, action) {
      state.siteActionOverview = action.payload;
    },
    updateStatusSuccess(state, action) {
      state.siteActionOverview.latestUpdate.status = action.payload;
    },
    getSiteActionLogsSuccess(state, action) {
      state.siteActionLogs = action.payload;
    },
    getMerakiDevicesSuccess(state, action) {
      state.devices = action.payload;
    },
    getIntegrationMonitoringLogsSuccess(state, action) {
      state.integrationMonitoringLogs = action.payload;
    },
    getUnassignedJobsSuccess(state, action) {
      state.unassignedJobs = action.payload;
    },
    getBotConfigurationsSuccess(state, action) {
      state.botConfigurations = action.payload;
    },
    getBotCalculatorSuccess(state, action) {
      state.selectedBotCalculator.userInput = action.payload;
    },
    updateBotCalculator: (state, action) => {
      // Create a deep copy of the current state for modification
      const currentUserInput = JSON.parse(JSON.stringify(state.selectedBotCalculator.userInput));

      // Calculate passing capability first
      if (action.payload.userInput) {
        const updatedUserInput = {
          ...currentUserInput,
          ...action.payload.userInput
        };
        
        updatedUserInput.determinedPassingCapability = calculatePassingCapability(
          updatedUserInput,
          state.selectedBotCalculator.lookupReference
        );

        // Safely calculate totalRobotArea
        const width = Number(updatedUserInput.totalWidthOfRobotArea) || 0;
        const length = Number(updatedUserInput.totalLengthOfRobotArea) || 0;
        updatedUserInput.totalRobotArea = safeMultiply(width, length);

        // Update the state with the modified user input
        state.selectedBotCalculator.userInput = updatedUserInput;
      }

      // Update siteName if provided
      if (action.payload.siteName !== undefined) {
        state.selectedBotCalculator.siteName = action.payload.siteName;
      }

      // Update static inputs if provided
      if (action.payload.staticInputs) {
        state.selectedBotCalculator.staticInputs = {
          ...state.selectedBotCalculator.staticInputs,
          ...action.payload.staticInputs
        };
      }

      // Now that lookups are updated, calculate bot metrics
      const calculationResults = calculateBotMetrics(
        state.selectedBotCalculator.userInput,
        JSON.parse(JSON.stringify(state.selectedBotCalculator.staticInputs)),
        JSON.parse(JSON.stringify(state.selectedBotCalculator.lookupReference))
      );

      // Update calculated values while preserving structure
      state.selectedBotCalculator.botCalculations = {
        ...state.selectedBotCalculator.botCalculations,
        ...calculationResults.values
      };
      // First, calculate lookup references since bot calculations depend on them
      const lookupParams = {
        userInput: state.selectedBotCalculator.userInput,
        staticInputs: JSON.parse(
          JSON.stringify(state.selectedBotCalculator.staticInputs),
        ),
      };

      // Calculate all lookup references using the new function
      const lookupResults = calculateLookupReferences(
        lookupParams.userInput,
        lookupParams.staticInputs,
        JSON.parse(JSON.stringify(state.selectedBotCalculator.botCalculations)),
        JSON.parse(JSON.stringify(state.selectedBotCalculator.lookupReference)),
      );

      // Store lookup results
      state.selectedBotCalculator.lookupReference = {
        ...state.selectedBotCalculator.lookupReference,
        ...JSON.parse(JSON.stringify(lookupResults.values)),
      };

      // Store lookup metadata if needed
      state.selectedBotCalculator.lookupMetadata = lookupResults.metadata;

      // Had to run twice to get the correct values
      // // Now that lookups are updated, calculate bot metrics
      const calculationResults2 = calculateBotMetrics(
        state.selectedBotCalculator.userInput,
        JSON.parse(JSON.stringify(state.selectedBotCalculator.staticInputs)),
        JSON.parse(JSON.stringify(state.selectedBotCalculator.lookupReference)),
      );

      // // Update calculated values while preserving structure
      state.selectedBotCalculator.botCalculations = {
        ...state.selectedBotCalculator.botCalculations,
        ...calculationResults2.values,
      };


      ///
      const lookupParams2 = {
        userInput: state.selectedBotCalculator.userInput,
        staticInputs: JSON.parse(
          JSON.stringify(state.selectedBotCalculator.staticInputs),
        ),
      };

      // Calculate all lookup references using the new function
      const lookupResults2 = calculateLookupReferences(
        lookupParams2.userInput,
        lookupParams2.staticInputs,
        JSON.parse(JSON.stringify(state.selectedBotCalculator.botCalculations)),
        JSON.parse(JSON.stringify(state.selectedBotCalculator.lookupReference)),
      );

      // Store lookup results
      state.selectedBotCalculator.lookupReference = {
        ...state.selectedBotCalculator.lookupReference,
        ...JSON.parse(JSON.stringify({...lookupResults2.values})),
      };

      // Store calculation metadata
      state.selectedBotCalculator.calculationMetadata =
        calculationResults.metadata;

      // Finally, calculate outputs using updated lookups and bot calculations
      const outputResults = calculateOutputs(
        state.selectedBotCalculator.userInput,
        state.selectedBotCalculator.staticInputs,
        state.selectedBotCalculator.lookupReference,
        state.selectedBotCalculator.botCalculations,
      );

      // Update outputs while preserving structure
      state.selectedBotCalculator.outputs = {
        ...state.selectedBotCalculator.outputs,
        ...outputResults.values,
      };

      // Store output metadata
      state.selectedBotCalculator.outputMetadata = outputResults.metadata;
    },
    updateSelectedDesignVolume: (state, action) => {
      const { key, picking, putaway } = action.payload;

      // Ensure the path exists
      if (!state.selectedBotCalculator?.userInput?.designVolumes) {
        state.selectedBotCalculator = {
          ...state.selectedBotCalculator,
          userInput: {
            ...state.selectedBotCalculator?.userInput,
            designVolumes: {},
          },
        };
      }

      const currentDesignVolumes =
        state.selectedBotCalculator.userInput.designVolumes;
      // Ensure key is a string
      const stringKey = String(key?.key);

      // Create new designVolumes object with all existing values
      const updatedDesignVolumes = {
        ...currentDesignVolumes,
        [stringKey]: {
          picking:
            key.picking !== undefined
              ? key.picking
              : (currentDesignVolumes[stringKey]?.picking ?? 0),
          putaway:
            key.putaway !== undefined
              ? key.putaway
              : (currentDesignVolumes[stringKey]?.putaway ?? 0),
        },
      };

      // Update the entire designVolumes object
      state.selectedBotCalculator.userInput.designVolumes =
        updatedDesignVolumes;



      // Now that lookups are updated, calculate bot metrics
      const calculationResults = calculateBotMetrics(
        state.selectedBotCalculator.userInput,
        JSON.parse(JSON.stringify(state.selectedBotCalculator.staticInputs)),
        JSON.parse(JSON.stringify(state.selectedBotCalculator.lookupReference))
      );

      // Update calculated values while preserving structure
      state.selectedBotCalculator.botCalculations = {
        ...state.selectedBotCalculator.botCalculations,
        ...calculationResults.values
      };
      // First, calculate lookup references since bot calculations depend on them
      const lookupParams = {
        userInput: state.selectedBotCalculator.userInput,
        staticInputs: JSON.parse(
          JSON.stringify(state.selectedBotCalculator.staticInputs),
        ),
      };

      // Calculate all lookup references using the new function
      const lookupResults = calculateLookupReferences(
        lookupParams.userInput,
        lookupParams.staticInputs,
        JSON.parse(JSON.stringify(state.selectedBotCalculator.botCalculations)),
        JSON.parse(JSON.stringify(state.selectedBotCalculator.lookupReference)),
      );

      // Store lookup results
      state.selectedBotCalculator.lookupReference = {
        ...state.selectedBotCalculator.lookupReference,
        ...JSON.parse(JSON.stringify(lookupResults.values)),
      };

      // Store lookup metadata if needed
      state.selectedBotCalculator.lookupMetadata = lookupResults.metadata;

      // Had to run twice to get the correct values
      // // Now that lookups are updated, calculate bot metrics
      const calculationResults2 = calculateBotMetrics(
        state.selectedBotCalculator.userInput,
        JSON.parse(JSON.stringify(state.selectedBotCalculator.staticInputs)),
        JSON.parse(JSON.stringify(state.selectedBotCalculator.lookupReference)),
      );

      // // Update calculated values while preserving structure
      state.selectedBotCalculator.botCalculations = {
        ...state.selectedBotCalculator.botCalculations,
        ...calculationResults2.values,
      };


      ///
      const lookupParams2 = {
        userInput: state.selectedBotCalculator.userInput,
        staticInputs: JSON.parse(
          JSON.stringify(state.selectedBotCalculator.staticInputs),
        ),
      };

      // Calculate all lookup references using the new function
      const lookupResults2 = calculateLookupReferences(
        lookupParams2.userInput,
        lookupParams2.staticInputs,
        JSON.parse(JSON.stringify(state.selectedBotCalculator.botCalculations)),
        JSON.parse(JSON.stringify(state.selectedBotCalculator.lookupReference)),
      );

      // Store lookup results
      state.selectedBotCalculator.lookupReference = {
        ...state.selectedBotCalculator.lookupReference,
        ...JSON.parse(JSON.stringify({...lookupResults2.values})),
      };

      // Store calculation metadata
      state.selectedBotCalculator.calculationMetadata =
        calculationResults.metadata;

      // Finally, calculate outputs using updated lookups and bot calculations
      const outputResults = calculateOutputs(
        state.selectedBotCalculator.userInput,
        state.selectedBotCalculator.staticInputs,
        state.selectedBotCalculator.lookupReference,
        state.selectedBotCalculator.botCalculations,
      );

      // Update outputs while preserving structure
      state.selectedBotCalculator.outputs = {
        ...state.selectedBotCalculator.outputs,
        ...outputResults.values,
      };

      // Store output metadata
      state.selectedBotCalculator.outputMetadata = outputResults.metadata;


    },
    updateSelectedBotCalculator: (state, action) => {

      // Update userInput
      if (action.payload.userInput) {
        state.selectedBotCalculator.userInput = {
          ...state.selectedBotCalculator.userInput,
          ...action.payload.userInput,
        };
      }

      // Get current state values
      const userInput = state.selectedBotCalculator.userInput;
      const staticInputs = state.selectedBotCalculator.staticInputs;
      const lookupReference = state.selectedBotCalculator.lookupReference;


      // Calculate new values
      const calculationResults = calculateBotMetrics(
        userInput,
        staticInputs,
        lookupReference,
      );

      // Update bot calculations
      state.selectedBotCalculator.botCalculations = calculationResults.values;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
// Actions

export function getTools() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/tools`,
        {},
        {},
      );
      dispatch(slice.actions.getToolsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBotConfigurations(warehouse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/robot/calculation`,
        {
          headers: {
            "locus-warehouse": warehouse,
          },
        },
      );
      dispatch(slice.actions.getBotConfigurationsSuccess(response.data));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBotCalculationById(id, warehouse) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/robot/calculation/${id}`,
        {
          headers: {
            "locus-warehouse": warehouse,
          },
        },
      );

      // Convert API response to current state format
      const convertedData = convertToCurrentState(response.data);

      // Create the payload in the format expected by updateBotCalculator
      const payload = {
        siteName: convertedData.siteName,
        version: convertedData.version,
        userInput: convertedData.userInput,
        staticInputs: convertedData.staticInputs,
      };

      // First dispatch the initial state update
      dispatch(slice.actions.updateBotCalculator(payload));
      dispatch(slice.actions.updateBotCalculator(payload));

      // Then trigger the calculations by dispatching updateSelectedBotCalculator

      return convertedData;
    } catch (error) {
      throw error;
    }
  };
}

export function createBotCalculation(calculationData, warehouse) {
  return async () => {
    try {
      // First convert to API format
      const apiFormatData = convertToApiFormat(calculationData);
      
      // Then handle the inductDropoffLocationEntry mapping
      const mappedData = {
        ...apiFormatData,
        userInput: {
          ...apiFormatData.userInput,
          inductDropoffLocationEntry: inductDropoffLocationEntryMapping[calculationData.userInput.inductDropoffLocationEntry],
          robotType: robotOriginMapping[calculationData.userInput.robotOrigin]
        }
      };
      
      const response = await axios.post(
        `${process.env.REACT_APP_SERVICES_API}/services/robot/calculation`,
        mappedData,
        {
          headers: {
            "locus-warehouse": warehouse,
          },
        },
      );
      
      // Convert response back to current state format
      return convertToCurrentState(response.data);
    } catch (error) {
      throw error;
    }
  };
}

export function updateBotCalculation(id, calculationData, warehouse) {
  return async () => {
    try {
      // First convert to API format
      const apiFormatData = convertToApiFormat(calculationData);
      
      // Then handle the inductDropoffLocationEntry mapping
      const mappedData = {
        ...apiFormatData,
        userInput: {
          ...apiFormatData.userInput,
          inductDropoffLocationEntry: inductDropoffLocationEntryMapping[calculationData.userInput.inductDropoffLocationEntry],
          robotType: robotOriginMapping[calculationData.userInput.robotOrigin]
        }
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_SERVICES_API}/services/robot/calculation/${id}`,
        mappedData,
        {
          headers: {
            "locus-warehouse": warehouse,
          },
        },
      );
      
      // Convert response back to current state format
      return convertToCurrentState(response.data);
    } catch (error) {
      throw error;
    }
  };
}

export function getRobotsWithCarts({ site, searchFilter }) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/cart/robot`,
        {
          headers: {
            "locus-warehouse": site.condensedName,
          },
          params: {
            ...searchFilter,
          },
        },
      );

      const processedRows = [];
      response.data?.rows?.forEach((robotItem, rIdx) => {
        robotItem?.carts?.forEach((cartItem, cIdx) => {
          processedRows.push({
            id: `${rIdx}-${cIdx}`,
            cart: cartItem,
            robot: robotItem.robot,
          });
        });
      });

      dispatch(
        slice.actions.getRobotsWithCartsSuccess({
          count: response?.data?.count,
          rows: processedRows,
        }),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSiteActionLogs(
  site,
  environment,
  timestampRange,
  task = undefined,
  page,
  pageSize,
) {
  return async () => {
    const timestampRangeString =
      timestampRange[0] && timestampRange[1]
        ? `${new Date(timestampRange[0]).toISOString()},${new Date(timestampRange[1]).toISOString()}`
        : undefined;

    const response = await axios.get(
      `${process.env.REACT_APP_TOOLING_API}/tooling/siteaction/logs`,
      {
        headers: {
          "locus-agent-env": environment,
        },
        params: {
          site,
          timestampRange: timestampRangeString,
          group: false,
          page,
          pageSize,
          task,
        },
      },
    );

    const flattenedData = flattenSiteActionLogs(response.data.data);
    dispatch(slice.actions.getSiteActionLogsSuccess(flattenedData));
    return response;
  };
}
function buildStatusBody(
  site,
  user,
  taskType,
  taskId,
  args = [],
  scheduledAt = new Date(),
  status = "New",
) {
  let postBody = {
    scheduledAt: new Date(scheduledAt).toISOString(), // Ensure the date is in UTC format
    status,
    taskType,
    task: taskId,
    user: {
      username: user.username,
      id: user.id,
    },
    arguments: args,
    site: site.id,
  };
  return postBody;
}

async function getTaskId(taskName) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TOOLING_API}/tooling/agenttask/find`,
      {
        params: {
          name: taskName,
        },
      },
    );
    return response.data?.id || null;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export function postStatusAgentCheck(
  site,
  user,
  environment,
  taskType,
  args = [],
  scheduledAt = new Date(),
  status = "New",
) {
  return async () => {
    try {
      const taskId = await getTaskId(taskType);

      const postBody = buildStatusBody(
        site,
        user,
        taskType,
        taskId,
        args,
        scheduledAt,
        status,
      );

      const responseAgenttask = await axios.post(
        `${process.env.REACT_APP_TOOLING_API}/tooling/status/agenttask`,
        postBody, // Post body goes here
        {
          headers: {
            "locus-agent-env": environment,
          },
        },
      );
      dispatch(slice.actions.updateStatusSuccess("New"));
      return responseAgenttask;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

export function getSiteActionOverview(site, environment, group = false) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TOOLING_API}/tooling/siteaction/overview`,
        {
          headers: {
            "locus-agent-env": environment,
          },
          params: {
            site: site,
            group: group,
          },
        },
      );
      const flattenedData = flattenSiteActionOverview(response.data.data);
      dispatch(slice.actions.getSiteActionOverviewSuccess(flattenedData));
      return flattenedData;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.error(error);
    }
  };
}

export function getMerakiDevices({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
  selectedSiteId,
}) {
  return async () => {
    try {
      let params = {
        page: pageNumber,
        pageSize: pageSize,
        ...searchFilter,
      };
      if (sortField) {
        params.sort = sortField;
      }
      if (sortDirection) {
        params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
      }

      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/mobiledevice/${selectedSiteId}`,
        { params },
        {},
      );
      dispatch(
        slice.actions.getMerakiDevicesSuccess({
          count: response.data?.count,
          rows: response.data?.data,
        }),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateMerakiDevice(context) {
  return async () => {
    try {
      return await axios.post(
        `${process.env.REACT_APP_SERVICES_API}/services/mobiledevicelockstate`,
        context,
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getIntegrationMonitoringLogs({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
  selectedClient,
  selectedSite,
  viewState,
}) {
  return async () => {
    try {
      let params = {
        page: pageNumber,
        pageSize: pageSize,
      };
      if (sortField) {
        params.sort = sortField;
      }
      if (sortDirection) {
        params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
      }
      if (viewState === "client" && selectedClient && selectedClient?.id) {
        params.client = selectedClient.id;
      }
      if (viewState === "site" && selectedSite && selectedSite?.id) {
        params.site = selectedSite.id;
      }

      params = { ...params, ...searchFilter };

      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/warehouse/connectorlog`,
        {
          headers: {
            "locus-warehouse": selectedSite.condensedName,
          },
          params,
        },
        {},
      );
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess(response.data),
      );
    } catch (error) {
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess(
          initialState.integrationMonitoringLogs,
        ),
      );
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearIntegrationMonitoringLogs() {
  return async () => {
    try {
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess(
          initialState.integrationMonitoringLogs,
        ),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIntegrationMonitoringLogById({ selectedSite, logId }) {
  return async () => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/warehouse/connectorlog/${logId}`,
        {
          headers: {
            "locus-warehouse": selectedSite.condensedName,
          },
        },
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getUnassignedJobs({ site }) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TOOLING_API}/tooling/warehouse/job/unassigned`,
        {
          headers: {
            "locus-warehouse": site.condensedName,
          },
        },
      );
      dispatch(
        slice.actions.getUnassignedJobsSuccess(response.data),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(
        slice.actions.getUnassignedJobsSuccess({
          count: 0,
          rows: [],
        }),
      );
    }
  };
}

export function updateSelectedBotCalculator(payload) {
  return async () => {
    try {
      dispatch(slice.actions.updateBotCalculator(payload));
    } catch (error) {
      console.error("Error updating bot calculator:", error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSelectedDesignVolume(key, picking, putaway) {
  return async () => {
    try {
      dispatch(
        slice.actions.updateSelectedDesignVolume({ key, picking, putaway }),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
